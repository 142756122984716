import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useLayoutEffect,
} from "react";
import {
  Row,
  Card,
  Form,
  useAPI,
  Animate,
  Button,
  ViewContext,
  Image,
  Article,
} from "components/lib";
import qs from "querystring";
import axios from "axios";
import GENLogo from "./images/gen_banner.png";

export function MonashForm3(props) {
  const locations = props.location.search.substring(1);
  const queryParams = qs.parse(locations);
  const savedFormData = useAPI(`/api/submission/${queryParams.submission_id}`);
  const [formValues, setFormValues] = useState({});
  const [showOtherHeard, setShowOtherHeard] = useState(false);

  let formData = {
    aboutVenture: {
      title: "About Your Venture",
      type: "header",
    },
    teamName: {
      label: "Startup or Team Name",
      type: "text",
      required: true,
      value: formValues?.teamName,
      errorMessage: "Please enter your team name",
    },
    companyWebsite: {
      label: "Company Website (if applicable)",
      type: "url",
      required: false,
      value: formValues?.companyWebsite,
    },
    idea: {
      label: "Describe your venture in 2-3 sentences (e.g. elevator pitch)",
      type: "textarea",
      required: true,
      value: formValues?.idea,
      errorMessage: "Please describe your elevator pitch",
      maxlength: 300,
    },
    video: {
      label:
        "Please enter the url of a ONE MINUTE publicly accessible video introducing yourself and your cofounders",
      type: "url",
      required: true,
      value: formValues?.video,
      errorMessage: "Please enter you intro video",
      hint: "Please ensure that the audio is clear and there is adequate lighting (we want to see and hear you!). The time limit is strict - your video will not be viewed past the 60-second mark.",
    },
    problem: {
      label: "What problem are you solving? (In 50 words or less)",
      type: "textarea",
      required: true,
      value: formValues?.problem,
      errorMessage: "Please describe the problem you are solving",
      maxlength: 300,
    },
    solution: {
      label: "Describe your solution (in 50 words or less)",
      type: "textarea",
      required: true,
      value: formValues?.solution,
      errorMessage: "Please describe the solution",
      maxlength: 300,
    },
    origin: {
      label: "How did you come about this idea?",
      type: "textarea",
      required: true,
      value: formValues?.origin,
      maxlength: 2000,
    },
    interviews: {
      label:
        "How many one-on-one customer interviews have you conducted so far?",
      type: "number",
      required: true,
      value: savedFormData?.data?.submission_data?.interviews,
      errorMessage: "Please provide a number",
    },
    currentSolution: {
      label:
        "What substitutes do your customers resort to, i.e. without your solution?",
      type: "textarea",
      required: true,
      value: formValues?.currentSolution,
      errorMessage:
        "Please describe the common way this problem is solved currently",
      maxlength: 2000,
    },

    unique: {
      label:
        "How is your solution unique/innovative/better than other solutions?",
      type: "textarea",
      required: true,
      value: formValues?.unique,
      errorMessage: "Please describe how your solution is better",
      maxlength: 2000,
    },

    businessModel: {
      label: "What is your business model?",
      type: "textarea",
      required: true,
      value: formValues?.businessModel,
      errorMessage: "Please enter your business model",
      maxlength: 2000,
    },
    traction: {
      hint: "For example: Yes - we launched a landing page 2 weeks ago explaining what we are going to do and are collecting email addresses to form a mailing list",
      label: "Describe the progress/traction you have achieved to date",
      type: "textarea",
      required: true,
      value: formValues?.traction,
      errorMessage: "Please describe your traction",
      maxlength: 2000,
    },
    rightTeam: {
      label: "Why are you the right team to solve this problem?",
      type: "textarea",
      required: true,
      value: formValues?.rightTeam,
      errorMessage:
        "Please describe why you are the right team to solve this problem",
      maxlength: 2000,
    },
    whyApply: {
      label:
        "What motivates you? What do you care about? Why do you care about this problem?",
      type: "textarea",
      required: true,
      value: formValues?.whyApply,
      errorMessage: "Please describe your motivation to apply",
      maxlength: 2000,
    },
    whatGain: {
      label: "What do you hope to gain by participating in the Accelerator?",
      type: "textarea",
      required: true,
      value: formValues?.whatGain,
      errorMessage: "Please describe your you want to participate",
      maxlength: 2000,
    },
    hear: {
      label: "How did you hear about the Monash Generator Accelerator? ",
      type: "select",
      options: [
        { value: "Generator website", label: "Generator website" },
        { value: "Generator mailing list", label: "Generator mailing list" },
        { value: "Generator Facebook page", label: "Generator Facebook page" },
        {
          value: "Generator Instagram Page",
          label: "Generator Instagram Page",
        },
        { value: "Generator Twitter Page", label: "Generator Twitter Page" },
        {
          value: "University Communications",
          label: "University Communications",
        },
        { value: "A Monash student", label: "A Monash Student" },
        { value: "A Monash staff member", label: "A Monash staff member" },
        { value: "A Monash alumni", label: "A Monash alumni" },
        {
          value: "A friend or colleague encouraged me to apply",
          label: "A friend or colleague encouraged me to apply",
        },
        {
          value: "I've previously engaged with the Generator",
          label: "I've previously engaged with the Generator",
        },
        { value: "Other", label: "Other" },
      ],
      required: true,
      default: formValues?.hear,
    },
    otherHear: {
      label: "Please share where did you hear of us?",
      type: "text",
      required: false,
      show: showOtherHeard,
      value: formValues?.otherHear,
    },
  };

  const context = useContext(ViewContext);

  const save = (submission_id) => {
    console.log("axios.post()", submission_id);
    context.notification.show(
      "Your changes have now been saved!",
      "success",
      true
    );
    setShowOtherHeard(!showOtherHeard);
  };

  const toggleOther = () => {
    const newFormData = formData;
    console.log("in toggle other effect");

    // if (newFormData && formValues) {
    //   console.log("in if", newFormData);
    //   console.log("form val", formValues);
    //   newFormData.teamName.value = formValues.teamName;
    //   newFormData.companyWebsite.value = formValues.companyWebsite;
    //   newFormData.hear.default = formValues.hear;
    //   //   newFormData.otherHear.value = formValues.otherHear;
    //   console.log("hear", newFormData.hear);
    //   if (newFormData.hear.value === "Other") {
    //     newFormData.otherHear = {
    //       label: "Please share where did you hear of us?",
    //       type: "text",
    //       required: false,
    //       value: formValues?.otherHear,
    //     };
    //   }
    // }
    // formData = newFormData;
  };

  useEffect(() => {
    console.log("in init load", savedFormData);
    setFormValues(savedFormData?.data?.submission_data);
    setShowOtherHeard(savedFormData?.data?.submission_data?.hear === "Other");
  }, [savedFormData]);

  const scrollX = window.scrollX;
  const scrollY = window.scrollY;

  useLayoutEffect(() => {
    window.scrollTo(scrollX, scrollY);
  });

  useEffect(() => {
    const newFormData = formData;
    console.log("in setting effect");

    if (newFormData && formValues) {
      //   console.log("in if", newFormData);
      //   console.log("form val", formValues);
      newFormData.teamName.value = formValues.teamName;
      newFormData.companyWebsite.value = formValues.companyWebsite;
      newFormData.hear.default = formValues.hear;
      //   newFormData.otherHear.value = formValues.otherHear;
      console.log("hear", newFormData.hear);
      //   if (newFormData.hear.value === "Other") {
      //     newFormData.otherHear = {
      //       label: "Please share where did you hear of us?",
      //       type: "text",
      //       required: false,
      //       value: formValues?.otherHear,
      //     };
      //   }
    }
    formData = newFormData;
    toggleOther();

    //   if (formValues.hear === "Other") {
    //     console.log("is other");
    //     setShowOtherHeard(true);
    //   }

    //   if (formValues.hear !== "Other") {
    //     console.log("not other");
    //     setShowOtherHeard(false);
    //   }
    // }
  }, [formData, formValues, toggleOther]);

  useEffect(() => {
    console.log("trigger");
    const newFormData = formData;
    if (showOtherHeard) {
      //   newFormData.otherHear = {
      //     label: "Please share where did you hear of us?",
      //     type: "text",
      //     required: false,
      //     value: formValues?.otherHear,
      //   };
    } else {
      delete newFormData.otherHear;
    }

    formData = newFormData;
  }, [formData]);

  return (
    <Row title="Monash Generator Accelerator 2022 application">
      <Card>
        <Image source={GENLogo} width={1000} height={1200} />
        <Article>
          <p>
            {" "}
            Completing the application form will take approximately 30 - 40
            minutes and require some background on you, your cofounders/team
            members and your project. As part of the application form you will
            also need to include a link to 60-second video introducing yourself
            and any cofounders.
            <br />
            <br />
            The application will autosave, and until you press submit you can
            leave and return to edit your application as many times as you like.{" "}
            <strong>
              Make sure to submit your completed application prior to the
              application deadline, 11.59pm AEST Monday 1 August 2022.
            </strong>
          </p>
        </Article>
      </Card>
      <Animate>
        {formValues && (
          <Fragment>
            <Card>
              <Button
                text="Save"
                action={save}
                params={queryParams.submission_id}
              />
              <p>Note: Responses are auto saved</p>
              <br />
              <br />
              <Form
                data={formData}
                method="POST"
                url={`/api/submission/${queryParams.submission_id}`}
                updateOnChange
                onChange={async (e) => {
                  //   console.log("on change", e);
                  if (e.valid === true || e.valid === 1) {
                    await axios.patch(
                      `/api/submission_data/${queryParams.submission_id}`,
                      e
                    );

                    const newFormValues = formValues;
                    newFormValues[`${e.input}`] = e.value;
                    console.log("setting form value to", newFormValues);
                    setFormValues(newFormValues);

                    const newFormData = formData;
                    console.log(" setting form data");

                    if (e.input === "hear" && e.value === "Other") {
                      console.log("set other heard true");
                      setShowOtherHeard(true);
                    }
                    if (e.input === "hear" && e.value !== "Other") {
                      console.log("set other heard false");
                      setShowOtherHeard(false);
                    }

                    if (newFormData && formValues) {
                      //   console.log("in if", newFormData);
                      console.log("form val", formValues);
                      newFormData.teamName.value = formValues.teamName;
                      newFormData.companyWebsite.value =
                        formValues.companyWebsite;
                      newFormData.hear.default = formValues.hear;
                      //   newFormData.otherHear.value = formValues.otherHear;
                      //   console.log("hear", newFormData.hear);
                      //   newFormData.otherHear.show =
                      //     newFormData.hear.value === "Other";

                      //   if (formValues.hear !== "Other") {
                      //     console.log("is not other", formValues, formData);
                      //     delete newFormData.otherHear;
                      //     console.log("is not other after", formData);
                      //   }
                      formData = newFormData;
                    }
                  }
                }}
                buttonText="Submit"
                redirect="/thanks/monash"
              />
            </Card>
          </Fragment>
        )}
      </Animate>
    </Row>
  );
}
