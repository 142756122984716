import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  Feedback,
  Footer,
  Grid,
  Card,
  Chart,
  Loader,
  useAPI,
  Stat,
  Icon,
  Row,
  Link,
  Button,
  TabView,
  Paginate,
  Search,
} from "components/lib";

export function CampaignSummary(props) {
  const campaignId = props.computedMatch.params.campaignId;
  const campaign = useAPI("/api/campaign/" + campaignId);
  const campaignStats = useAPI("/api/submission-data/stats/" + campaignId);
  // const submissions = useAPI(`/api/campaign/${campaignId}/submissions`);
  const [search, setSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [offset, setOffset] = useState(0);
  const [submissions, setSubmissions] = useState(null);
  const [chart, setChart] = useState(false);
  const [loading, setLoading] = useState(false);
  const domain =
    process.env.NODE_ENV === "production"
      ? "https://www.submithero.io"
      : "http://localhost:3000";

  // show 25 results at a time
  const limit = 30;

  if (!campaign?.data || campaign?.loading) {
    return <Loader />;
  }

  // Point is to show the user the links
  // get to wizard (view settings)
  // expire wizard
  // where to find the campaign table
  // if in draft, what does draft mode mean
  // all subs cancelled when turn to live
  // if in live, what does that mean and stats
  // show me links
  return (
    <Fragment>
      <Grid cols="4">
        <Stat
          loading={campaign?.loading}
          value={campaign?.data?.[0].status}
          label="Status"
          icon="award"
        />
        <Stat
          loading={campaignStats?.loading}
          value={campaignStats?.data?.created}
          label="Created"
          icon="file-plus"
        />
        <Stat
          loading={campaignStats?.loading}
          value={campaignStats?.data?.inProgress}
          label="In Progress"
          icon="refresh-cw"
        />
        <Stat
          loading={campaignStats.loading}
          value={campaignStats?.data?.submitted}
          label="Submitted"
          icon="check"
          // change="5%"
        />
      </Grid>

      <Grid cols="2">
        <Card title={`${campaign?.data?.[0].name} - Actions`}>
          <Grid cols="4">
            <Row>
              <p className="align-center">
                <Link url={`/wizard/campaign/${campaignId}`}>
                  <div>
                    <Icon color="dark" image="tool" size="48" />
                  </div>
                  Settings
                </Link>
              </p>

              <p className="align-center" style={{ padding: "10px" }}>
                <Link url={`/wizard/form/${campaignId}`}>
                  <div>
                    <Icon color="dark" image="server" size="48" />
                  </div>
                  Form Builder
                </Link>
              </p>
            </Row>
            <Row>
              <p className="align-center">
                <Icon color="dark" image="user" size="48" />
                <div>
                  <Link
                    url={`${domain}/campaign/view/${campaignId}`}
                    text="Public"
                    target="_blank"
                  ></Link>
                </div>
              </p>

              <p className="align-center" style={{ padding: "10px" }}>
                <Link url={`/campaign/${campaignId}`}>
                  <div>
                    <Icon color="dark" image="database" size="48" />
                  </div>
                  Results Table
                </Link>
              </p>
            </Row>
            <Row>
              {/* <p className="align-center">
                <Link url={`/campaign/send/${campaignId}`}>
                  <div>
                    <Icon color="dark" image="send" size="48" />
                  </div>
                  Send Now
                </Link>
              </p> */}
              <p className="align-center" style={{ padding: "10px" }}>
                <Link url={`/campaign/${campaignId}/notification`}>
                  <div>
                    <Icon color="dark" image="mail" size="48" />
                  </div>
                  Notification Settings
                </Link>
              </p>
            </Row>
          </Grid>
          {/* <TabView
            name="Settings"
            labels={["Settings", "Form", "Public", "Results"]}
          >
            <Card>
              <p>View campaigns details such as key dates, emails etc.</p>
              <Button
                goto={`/wizard/campaign/${campaignId}`}
                small
                text="Go"
              ></Button>
              <br />
              <br />
              <br />
            </Card>
            <Card>
              <p>Manage your form</p>
              <Button
                small
                goto={`/wizard/form/${campaignId}`}
                text="Launch"
              ></Button>
              <br />
              <br />
              <br />
            </Card>
            <Card>
              {/* Do not show if not live or draft */}
          {/* <p>
                In draft mode, only registered users of your submithero account
                may register for the campaign{" "}
              </p>
              <br />
              <Button
                small
                goto={`/campaign/view/${campaignId}`}
                text="Launch"
              ></Button>
            </Card>
            <Card>
              <p>View all submissions in a data table </p>
              <Button
                small
                goto={`/campaign/${campaignId}`}
                text="View"
              ></Button>
              <br /> <br /> <br /> <br /> */}
          {/* </Card> */}
          {/* </TabView> */}
        </Card>
        <Card title="Campaign Summary Statistics">
          <Chart
            type="bar"
            legend={true}
            data={{
              labels: ["CREATED", "IN PROGRESS", "SUBMITTED"],
              datasets: [
                {
                  label: "SUBMITTED",
                  data: [
                    campaignStats?.data?.created,
                    campaignStats?.data?.inProgress,
                    campaignStats?.data?.submitted,
                  ],
                  borderColor: "#FFFFFF",
                  backgroundColor: [
                    "#8D8CC3",
                    "#9A99CB",
                    "#A6A5D7",
                    "#B6B5E4",
                    "#C5C4F1",
                    "#CECDF8",
                  ],
                  hoverBorderColor: "transparent",
                },
              ],
            }}
            color="red"
          />
        </Card>
      </Grid>

      <Grid cols="1">
        <Search
          throttle={1000}
          callback={(x) => {
            console.log("search", x);
            setSearch(x);
          }}
          placeholder="Search by email or status"
          value={searchTerm}
        />
        <br />

        <Paginate
          offset={offset}
          limit={limit}
          total={submissions?.total}
          loading={loading}
          onChange={(x) => setOffset(x)}
        />

        <FetchSubmissions
          search={search}
          offset={offset}
          limit={limit}
          name={"Submissions"}
          campaignId={campaignId}
          setLoading={(x) => setLoading(x)}
          setData={(x) => setSubmissions(x)}
          setChart={(x) => setChart(x)}
        />
        <div>
          <span style={{ paddingRight: "15px" }}>
            <Button
              text="Created"
              small
              action={() => {
                setSearchTerm("CREATED");
              }}
            />
          </span>
          <span style={{ paddingRight: "15px" }}>
            <Button
              text="In Progress"
              small
              action={() => {
                setSearchTerm("IN_PROGRESS");
              }}
            />
          </span>
          <span style={{ paddingRight: "15px" }}>
            <Button
              text="Submitted"
              small
              action={() => {
                setSearchTerm("SUBMITTED");
              }}
            />
          </span>
          <span style={{ paddingRight: "15px" }}>
            <Button text="All" small action={() => setSearchTerm("")} />
          </span>
        </div>

        <Card title="Submissions">
          <Table
            data={submissions?.results}
            show={["name", "status", "created_at", "updated_at"]}
            loading={loading}
            badge={{ col: "status", color: "red" }}
            actions={{
              view: { url: "/submission/view", col: "id" },
            }}
            sort={true}
          ></Table>
        </Card>
      </Grid>

      <Feedback />
      <Footer />
    </Fragment>
  );
}

function FetchSubmissions(props) {
  const submissions = useAPI(
    `/api/campaign/${props.campaignId}/submissions?search=${encodeURIComponent(
      props.search
    )}&offset=${props.offset}&limit=${props.limit}`
  );

  useEffect(() => {
    props.setLoading(submissions.loading);

    if (submissions.data) props.setData(submissions.data);

    if (submissions.data?.chart) props.setChart(submissions.data.chart);
  }, [submissions, props]);

  return false;
}
