import React, { Fragment, useCallback, useContext } from "react";
import { Card, AuthContext, useAPI } from "components/lib";
import { StylesManager, Model, FunctionFactory } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import campaignSendNowJson from "./campaignSendNowJson";

StylesManager.applyTheme("defaultV2");

const defaultImageFunction = (params) => {
  if (!params[0]) {
    return "https://storage.googleapis.com/submithero-images/SVG/SubmitHero_Logo_RGB_OnRed.svg";
  }
  const welcomeBanner = params[0];
  return welcomeBanner[0].content;
};

const showButtonFunction = (params) => {
  if (params[0]) {
    return '<button class="button">View My Application</button>';
  } else return "";
};

FunctionFactory.Instance.register("defaultImageFunction", defaultImageFunction);
FunctionFactory.Instance.register("showButtonFunction", showButtonFunction);

export function CampaignSendNow(props) {
  // handle submission
  // show succcess message

  const uploadFiles = useCallback((sender, options) => {
    // console.log("sender", sender);
    console.log("options", options);
    var formData = new FormData();
    options.files.forEach(function (file) {
      formData.append("file", file);
      formData.append("campaignId", campaignId);
      formData.append("questionName", options.name);
    });
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/api/utility/gcp/upload/campaign");
    xhr.setRequestHeader("Authorization", `Bearer ${context.user?.token}`);
    xhr.onload = function () {
      var data = JSON.parse(xhr.responseText);
      // console.log("data", data.url);
      options.callback(
        "success",
        options.files.map(function (file) {
          // console.log("file", file);
          return {
            file: file,
            content: data.url,
          };
        })
      );
    };
    console.log("form data", formData);
    xhr.send(formData);
  });

  function saveSurveyResults(url, json) {
    const request = new XMLHttpRequest();
    request.open("POST", url);
    request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    request.setRequestHeader("Authorization", `Bearer ${context.user?.token}`);
    request.addEventListener("load", () => {
      // Handle "load"
    });
    request.addEventListener("error", () => {
      // Handle "error"
    });
    request.send(JSON.stringify(json));
  }

  const sendNowComplete = useCallback((sender) => {
    saveSurveyResults("/api/campaign/send/" + campaignId, sender.data);
  }, []);

  const campaignId = props.computedMatch.params.campaignId;
  const campaign = useAPI("/api/campaign/" + campaignId);
  const context = useContext(AuthContext);

  const survey = new Model(campaignSendNowJson);
  survey.onUploadFiles.add(uploadFiles);
  survey.onComplete.add(sendNowComplete);

  return (
    <Fragment>
      <Card loading={campaign?.loading}>
        {!campaign?.loading && <Survey model={survey} />}
      </Card>
    </Fragment>
  );
}
