/***
 *
 *   IMAGE
 *   Import the image before passing it to this component
 *
 *   PROPS
 *   source: imported source
 *   alt: alt description
 *   title: description
 *   className: inject a custom class object
 *   width: add img width attribute
 *   height: add img height attribute
 **********/

import React from 'react';
import Style from './image.module.scss';

export function Image(props) {
  return (
    <img
      src={props.source}
      alt={props.alt}
      title={props.title}
      className={Style.image}
      width={props.width}
      heigh={props.height}
    />
  );
}
