import React, { useState, Fragment, useEffect, useCallback } from "react";
import {
  useAPI,
  Card,
  Article,
  Grid,
  Row,
  Loader,
  FormSelect,
  Form,
} from "components/lib";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
// import "./defaultV2.css";
// import "../../node_modules/survey-creator-core/survey-creator-core.min.css";
import Axios from "axios";

export function SurveyBuilder(props) {
  const formId = props.computedMatch.params.formId;
  const formData = useAPI("/api/form/" + formId);
  const fieldData = formData?.data?.fieldData;
  console.log("form data", formData, fieldData);

  const creatorOptions = {
    showLogicTab: true,
    isAutoSave: true,
    haveCommercialLicense: true,
    showJSONEditorTab: false,
    pageEditMode: "bypage",
  };

  const creator = new SurveyCreator(creatorOptions);
  creator.JSON = fieldData;
  creator.saveSurveyFunc = async (saveNo, callback) => {
    await saveSurveyJSON(
      "/api/form-fields/" + formId,
      creator.JSON,
      (formData.data?.misc?.saveNo || 0) + saveNo,
      callback
    );
  };

  const saveSurveyJSON = async (url, json, saveNo, callback) => {
    const response = await Axios.patch(url, {
      fieldData: json,
      misc: { saveNo: saveNo },
    });
    if (response.data.success) {
      callback(saveNo, true);
    } else {
      callback(saveNo, false);
    }
  };
  return (
    // <>{!formData?.loading && <SurveyCreatorComponent creator={creator} />}</>
    <>
      <p>off</p>
    </>
  );
}
