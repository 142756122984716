import React, { Fragment, useState, useContext, useCallback } from "react";
import {
  Card,
  Form,
  Message,
  Loader,
  useAPI,
  Grid,
  AuthContext,
} from "components/lib";
import { StylesManager, Model } from "survey-core";
import { SurveyPDF } from "survey-pdf";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";

// StylesManager.applyTheme("modern");
StylesManager.applyTheme("defaultV2");

export function SubmissionViewV2(props) {
  const authContext = useContext(AuthContext);
  const id = props.computedMatch.params.id;
  const submission = useAPI(
    "/api/submission/secured/" + props.computedMatch.params.id
  );
  const formData = useAPI("/api/fields/" + props.computedMatch.params.id);
  let fieldData = formData?.data;

  const exportToPdfOptions = {
    fontSize: 12,
    commercial: true,
  };

  const savePdf = function (surveyData, fieldData, resultData) {
    const filename = `${submission?.data?.name}-${id}.pdf`;
    console.log("filename", filename);
    fieldData.pages.forEach((p) => {
      if (p.elements) {
        p.elements.forEach((e) => {
          if (e.type === "comment") {
            e.type = "html";
            e.html = `<h3>${e.title}</h3> ${resultData[e.name]}`;
          }
          if (e.type === "file") {
            e.type = "html";
            e.html = `<h3>${e.title}</h3>
            <p><a href=${resultData[e.name]?.[0]?.content}>${
              resultData[e?.name]?.[0]?.name
            }</a></p>
            <p>${encodeURI(resultData[e.name]?.[0]?.content)}</p>`;
          }
        });
      }
    });
    const surveyPdf = new SurveyPDF(fieldData, exportToPdfOptions);
    surveyPdf.data = surveyData;
    surveyPdf.save(filename);
  };

  if (fieldData && authContext?.user?.account_id) {
    fieldData["checkErrorsMode"] = "onComplete";
  }

  const survey = new Model(fieldData);
  survey.focusFirstQuestionAutomatic = true;
  survey.data = submission?.data?.submission_data;

  if (submission?.data?.submission_data?.pageNo) {
    survey.currentPageNo = submission.data.submission_data.pageNo;
  }

  if (authContext?.user?.account_id) {
    survey.addNavigationItem({
      id: "pdf-export",
      title: "Save as PDF",
      action: () => savePdf(survey.data, fieldData, survey.data),
    });
  }

  function saveSurveyResults(url, json) {
    const request = new XMLHttpRequest();
    request.open("POST", url);
    request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    request.addEventListener("load", () => {
      // Handle "load"
    });
    request.addEventListener("error", () => {
      // Handle "error"
    });
    request.send(JSON.stringify(json));
  }

  function updateResults(url, json) {
    const request = new XMLHttpRequest();
    request.open("PATCH", url);
    request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    request.addEventListener("load", () => {
      // Handle "load"
    });
    request.addEventListener("error", () => {
      // Handle "error"
    });
    request.send(JSON.stringify(json));
  }

  const surveyComplete = useCallback((sender) => {
    saveSurveyResults("/api/submission/" + id, sender.data);
  }, []);

  const surveyUpdate = useCallback((sender) => {
    console.log("update data: ", sender);
    updateResults("/api/sub_data/" + id, sender.valuesHash);
  });

  survey.onComplete.add(surveyComplete);
  survey.onValueChanged.add(surveyUpdate);

  return (
    <Fragment>
      <Message
        title="Submission"
        type="info"
        text="This is the current state of the submission."
      />
      <Card loading={submission?.loading}>
        {!formData?.loading && <Survey model={survey} />}
      </Card>
    </Fragment>
  );
}
