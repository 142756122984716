import React, { useCallback, useEffect, useState } from "react";
import { Row, Card, Image, Grid, Message, useAPI } from "components/lib";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
// import "survey-core/modern.min.css";
import "survey-core/defaultV2.css";
import Axios from "axios";

// StylesManager.applyTheme("modern");
StylesManager.applyTheme("defaultV2");

export function Submission(props) {
  const id = props.match.params.id;
  const formData = useAPI("/api/form-fields/" + id);
  const [campaign, setCampaign] = useState({});
  const [submission, setSubmission] = useState({});
  //   const formData = useAPI("/api/form/920757c1-5ca8-4bd3-b8fa-4b24f413deb8");
  // get submission status
  const submission_status = useAPI("/api/submission/status/" + id);

  const fieldData = formData?.data;
  const survey = new Model(fieldData);
  survey.focusFirstQuestionAutomatic = true;
  console.log("submission data", submission);
  survey.data = submission?.data?.submission_data;

  const getCampaign = async () => {
    //  getting campign info
    const campaignInfo = await Axios.get(
      "/api/campaign/" + submission?.data?.campaign_id
    );
    setCampaign(campaignInfo?.data);
  };

  useEffect(async () => {
    const submisisonResponse = await Axios.get("/api/submission/" + id);
    setSubmission(submisisonResponse?.data);
  }, [submission_status]);

  useEffect(async () => {
    if (submission?.loading === false && submission?.data) {
      await getCampaign();
    }
  }, [submission]);

  if (submission?.data?.submission_data?.pageNo) {
    survey.currentPageNo = submission.data.submission_data.pageNo;
  }

  function saveSurveyResults(url, json) {
    const request = new XMLHttpRequest();
    request.open("POST", url);
    request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    request.addEventListener("load", () => {
      // Handle "load"
    });
    request.addEventListener("error", () => {
      // Handle "error"
    });
    request.send(JSON.stringify(json));
  }

  function updateResults(url, json) {
    const request = new XMLHttpRequest();
    request.open("PATCH", url);
    request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    request.addEventListener("load", () => {
      // Handle "load"
    });
    request.addEventListener("error", () => {
      // Handle "error"
    });
    request.send(JSON.stringify(json));
  }

  const surveyComplete = useCallback((sender) => {
    saveSurveyResults("/api/submission/" + id, sender.data);
  }, []);

  const surveyUpdate = useCallback((sender) => {
    console.log("update data: ", sender);
    updateResults("/api/sub_data/" + id, sender.valuesHash);
  });

  const uploadFiles = useCallback((sender, options) => {
    // console.log("sender", sender);
    // console.log("options", options);
    var formData = new FormData();
    options.files.forEach(function (file) {
      formData.append("file", file);
      formData.append("submissionId", id);
      formData.append("questionName", options.name);
    });
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/api/utility/gcp/upload");
    xhr.onload = function () {
      var data = JSON.parse(xhr.responseText);
      // console.log("data", data.url);
      options.callback(
        "success",
        options.files.map(function (file) {
          // console.log("file", file);
          return {
            file: file,
            content: data.url,
          };
        })
      );
    };
    console.log("form data", formData);
    xhr.send(formData);
  });

  survey.onComplete.add(surveyComplete);
  survey.onValueChanged.add(surveyUpdate);
  survey.onUploadFiles.add(uploadFiles);

  if (submission_status?.data?.status === "SUBMITTED") {
    return (
      <>
        <h1>You application has been submitted</h1>
      </>
    );
  }

  return (
    <>
      {!formData?.loading && (
        <>
          <Grid>
            {campaign?.data?.[0].status === "DRAFT" && (
              <Message
                title="DRAFT MODE"
                text="This will be the candidate experiance when completing their submission."
                type="warning"
              />
            )}
            <Card>
              <Survey model={survey} />
            </Card>
          </Grid>
        </>
      )}
    </>
  );
}
