import React, { Fragment, useState } from "react";
import { Loader, Card, Table, useAPI, Button, History } from "components/lib";
import { v4 as uuidv4 } from "uuid";

export function Notification(props) {
  const campaignId = props.computedMatch.params.campaignId;
  const notifications = useAPI("/api/notification/campaign/" + campaignId);

  if (!notifications) return false;

  const handleAddNotification = async () => {
    console.log("Add notification");
    const notificationId = uuidv4();
    console.log(notificationId);
    History.push(`/campaign/${campaignId}/notification/${notificationId}`);
  };

  const goToNotification = (row) => {
    console.log("add notification", row);
    History.push(`/campaign/${campaignId}/notification/${row.id}`);
  };

  return (
    <Fragment>
      <Card>
        <div>
          <Button
            text="Add Notification"
            action={handleAddNotification}
          ></Button>
        </div>
        {/* Table of notifications */}
        <p>Table of notifications</p>
      </Card>

      <Card title="Notifications">
        <Table
          search
          data={notifications?.data}
          show={[
            "name",
            "type",
            "audience",
            "execution_time",
            "status",
            "updated_at",
          ]}
          loading={notifications?.loading}
          badge={{ col: "status", color: "red" }}
          actions={{
            custom: [
              {
                icon: "eye",
                action: goToNotification,
              },
            ],
          }}
          sort={true}
        ></Table>
      </Card>

      {/* render view data */}
    </Fragment>
  );
}
