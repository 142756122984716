import React, { useState, Fragment, useEffect, useCallback } from "react";
import {
  useAPI,
  Card,
  Article,
  Grid,
  Row,
  Loader,
  FormSelect,
  Form,
} from "components/lib";
import qs from "querystring";
import { ReactFormBuilder, ElementStore, Preview } from "react-form-builder2";
import "./form.css";
import Axios from "axios";
// import "./defaultV2.css";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
const SURVEY_ID = 1;

StylesManager.applyTheme("defaultV2");

const surveyJson = {
  elements: [
    {
      name: "FirstName",
      title: "Enter your first name:",
      type: "text",
    },
    {
      name: "LastName",
      title: "Enter your last name:",
      type: "text",
    },
  ],
};

const saveSurveyResults = async (url, json) => {
  await Axios.post(url, json);
};
export function SurveyTest(props) {
  const survey = new Model(surveyJson);
  const surveyComplete = useCallback((sender) => {
    saveSurveyResults(
      "http://localhost:8080/api/form" + SURVEY_ID,
      sender.data
    );
  }, []);
  survey.onComplete.add(surveyComplete);
  return <>{/* <Survey model={survey} /> */}</>;
}
