import React, { Fragment, useCallback, useContext } from "react";
import { Card, AuthContext, useAPI } from "components/lib";
import { StylesManager, Model, FunctionFactory } from "survey-core";
import * as SurveyCore from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import notificationSettingsJson from "./notificationSettingsJson";
import $ from "jquery";
import * as widgets from "surveyjs-widgets";
window["$"] = window["jQuery"] = $;

widgets.ckeditor(SurveyCore);

// const self = window;

// if (!self.alreadyRendered) {
//   const script = document.createElement("script");

//   script.src = "https://cdn.ckeditor.com/4.14.1/standard/ckeditor.js";

//   document.head.append(script);

//   script.onload = function () {
//     const CKEDITOR = window.CKEDITOR;

//     self.alreadyRendered = true;

//     if (self.forceUpdate) self.forceUpdate(); // need only for REACT
//   };
// }

StylesManager.applyTheme("defaultV2");

// const defaultFollowUpImageFunction = (params) => {
//   if (!params[0]) {
//     return "https://storage.googleapis.com/submithero-images/SVG/SubmitHero_Logo_RGB_OnRed.svg";
//   }
//   const welcomeBanner = params[0];
//   return welcomeBanner[0].content;
// };

// const showFollowUpButtonFunction = (params) => {
//   if (params[0]) {
//     return `<button class="button">${params[1]}</button>`;
//   } else return `<p><p>`;
// };

const defaultCampaignEndImageFunction = (params) => {
  if (!params[0]) {
    return "https://storage.googleapis.com/submithero-images/SVG/SubmitHero_Logo_RGB_OnRed.svg";
  }
  const welcomeBanner = params[0];
  return welcomeBanner[0].content;
};

const showCampaignEndButtonFunction = (params) => {
  if (params[0]) {
    return `<button class="button">${params[1]}</button>`;
  } else return "<p><p>";
};

// const defaultCampaignEndImageFunction24h = (params) => {
//   if (!params[0]) {
//     return "https://storage.googleapis.com/submithero-images/SVG/SubmitHero_Logo_RGB_OnRed.svg";
//   }
//   const welcomeBanner = params[0];
//   return welcomeBanner[0].content;
// };

// const showCampaignEndButtonFunction24h = (params) => {
//   if (params[0]) {
//     return `<button class="button">${params[1]}</button>`;
//   } else return "<p><p>";
// };

// const defaultCampaignEndImageFunction72h = (params) => {
//   if (!params[0]) {
//     return "https://storage.googleapis.com/submithero-images/SVG/SubmitHero_Logo_RGB_OnRed.svg";
//   }
//   const welcomeBanner = params[0];
//   return welcomeBanner[0].content;
// };

// const showCampaignEndButtonFunction72h = (params) => {
//   if (params[0]) {
//     return `<button class="button">${params[1]}</button>`;
//   } else return "<p><p>";
// };

// FunctionFactory.Instance.register(
//   "defaultFollowUpImageFunction",
//   defaultFollowUpImageFunction
// );
// FunctionFactory.Instance.register(
//   "showFollowUpButtonFunction",
//   showFollowUpButtonFunction
// );

FunctionFactory.Instance.register(
  "defaultCampaignEndImageFunction",
  defaultCampaignEndImageFunction
);
FunctionFactory.Instance.register(
  "showCampaignEndButtonFunction",
  showCampaignEndButtonFunction
);

// FunctionFactory.Instance.register(
//   "defaultCampaignEndImageFunction72h",
//   defaultCampaignEndImageFunction72h
// );
// FunctionFactory.Instance.register(
//   "showCampaignEndButtonFunction72h",
//   showCampaignEndButtonFunction72h
// );

export function NotificationSettings(props) {
  const uploadFiles = useCallback((sender, options) => {
    // console.log("sender", sender);
    console.log("options", options);
    var formData = new FormData();
    options.files.forEach(function (file) {
      formData.append("file", file);
      formData.append("notificationId", notificationId);
      formData.append("questionName", options.name);
    });
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/api/utility/gcp/upload/campaign");
    xhr.setRequestHeader("Authorization", `Bearer ${context.user?.token}`);
    xhr.onload = function () {
      var data = JSON.parse(xhr.responseText);
      // console.log("data", data.url);
      options.callback(
        "success",
        options.files.map(function (file) {
          // console.log("file", file);
          return {
            file: file,
            content: data.url,
          };
        })
      );
    };
    console.log("form data", formData);
    xhr.send(formData);
  });

  function saveSurveyResults(url, json) {
    const request = new XMLHttpRequest();
    request.open("POST", url);
    request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    request.setRequestHeader("Authorization", `Bearer ${context.user?.token}`);
    request.addEventListener("load", () => {
      // Handle "load"
    });
    request.addEventListener("error", () => {
      // Handle "error"
    });
    request.send(JSON.stringify(json));
  }

  const notificationComplete = useCallback((sender) => {
    saveSurveyResults("/api/notification/" + notificationId, sender.data);
  }, []);

  const campaignId = props.computedMatch.params.campaignId;
  const notificationId = props.computedMatch.params.notificationId;
  const campaign = useAPI("/api/campaign/" + campaignId);
  const notification = useAPI("/api/notification/" + notificationId);
  console.log("notification", notification.data);
  console.log("exeution time", notification?.data?.[0]?.execution_time);
  const context = useContext(AuthContext);

  const survey = new Model(notificationSettingsJson);
  survey.data = {
    campaignId: campaignId,
    notificationId: notificationId,
    type: notification?.data?.[0]?.type,
    audience: notification?.data?.[0]?.audience,
    status: notification?.data?.[0]?.status,
    execution_time: notification?.data?.[0]?.data.execution_time,
    subject: notification?.data?.[0]?.data?.subject,
    body: notification?.data?.[0]?.data?.body,
    title: notification?.data?.[0]?.data?.title,
    banner: notification?.data?.[0]?.data?.banner,
    button: notification?.data?.[0]?.data?.button,
    buttonText: notification?.data?.[0]?.data?.buttonText,
    name: notification?.data?.[0]?.data?.name,
  };

  notificationSettingsJson.navigateToUrl =
    "/campaign/" + campaignId + "/notification";

  survey.onUploadFiles.add(uploadFiles);
  survey.onComplete.add(notificationComplete);

  return (
    <Fragment>
      <Card loading={campaign?.loading}>
        {!campaign?.loading && <Survey model={survey} />}
      </Card>
    </Fragment>
  );
}
