/***
 *
 *   PUBLIC CAMPAIGN FORM
 *   Public campaign form
 *
 **********/

import React from "react";
import {
  Row,
  Form,
  Card,
  Article,
  Message,
  Image,
  Loader,
  useAPI,
  AuthContext,
} from "components/lib";
import parse from "html-react-parser";

export function GenericCampaign(props) {
  const campaignId = props.match.params.campaignId;
  const campaign = useAPI("/api/campaign/info/" + campaignId);

  // Get title
  const title = campaign?.data?.info?.campaignLandingTitle;
  // Get image
  const image = campaign?.data?.info?.campaignLandingImage?.[0].content;
  // Get intro text
  const text = campaign?.data?.info?.campaignLandingText;

  if (campaign?.loading) {
    return <Loader />;
  }

  // campaign not live or expired past the dates
  const unixEndTime = Date.parse(campaign?.data?.end_date);
  const endDate = new Date(unixEndTime);
  // NOT live or draft or expired by time
  if (
    !["LIVE", "DRAFT"].includes(campaign?.data?.status) ||
    endDate.getTime() < Date.now()
  ) {
    return (
      <>
        <Card>
          <Article>
            <h2>
              Sorry, the campaign you are looking to access it not accepting new
              applications.
            </h2>
          </Article>
        </Card>
      </>
    );
  }

  return (
    !campaign?.loading && (
      <Row title={title}>
        <Card restrictWidth>
          {campaign?.data?.status === "DRAFT" && (
            <Message
              title="DRAFT MODE"
              text="All submissions will be deleted when transitioned to LIVE mode. Feel free to experiment and initiate submissions from registered users of SubmitHero"
              type="warning"
            />
          )}

          <div style={{ marginRight: "auto", marginLeft: "auto" }}>
            <img
              src={image}
              width={250}
              height="auto"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            />
          </div>
          <Article>{parse(text || "some text")}</Article>
          <Form
            data={{
              email: {
                label: "Your Email",
                type: "email",
                required: true,
                errorMessage: "Please enter your email",
              },
              campaign_id: {
                label: "campaign id",
                type: "hidden",
                value: campaignId,
              },
            }}
            method="POST"
            url={`/api/submission/start/${campaignId}`}
            redirect={"/campaign/start/" + campaignId}
            buttonText="LET'S GO!"
          />
          <p
            style={{
              fontSize: "0.8em",
            }}
          >
            {parse(
              campaign?.data?.info?.campaignLandingFooterText || "some text"
            )}
            <br />
            By clicking 'Let's Go!' you agree to our{" "}
            <a href="/privacy">Privacy Policy</a> and{" "}
            <a href="https://stackgo.io/tos/">Terms of Service</a>
          </p>
        </Card>
      </Row>
    )
  );
}
