import React from "react";
import { Row, Grid, Message, Card, Link, Article } from "components/lib";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
// import "../../../node_modules/survey-creator-core/survey-creator-core.min.css";
// import "../../../node_modules/survey-core/defaultV2.css";

export function FormBuilderDemo(props) {
  const fieldData = {
    pages: [
      {
        name: "page1",
        elements: [
          { name: "First Name", type: "text" },
          { name: "Last Name", type: "text" },
        ],
      },
    ],
    logoPosition: "right",
    title: "My Accelerator",
  };
  const creatorOptions = {
    showLogicTab: true,
    isAutoSave: true,
    haveCommercialLicense: true,
    showJSONEditorTab: false,
    pageEditMode: "bypage",
  };
  const creator = new SurveyCreator(creatorOptions);
  creator.JSON = fieldData;
  console.log("creator survey", creator.survey);
  return (
    <>
      <Grid cols="1">
        <Card>
          <Message title="Welcome to the SubmitHero Form Builder" type="info">
            <p>
              Note that any changes you make can not be saved.{" "}
              <Link url="/signup">Create a free account here</Link> to save your
              progress.
            </p>
          </Message>
          <SurveyCreatorComponent creator={creator} />
        </Card>
      </Grid>
    </>
  );
}
