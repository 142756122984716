import React, { Fragment } from "react";
import { Loader, Card, Form, useAPI, Row, Table, Grid } from "components/lib";

export function Campaign(props) {
  const campaign = useAPI("/api/campaign");
  const formSelect = useAPI("/api/form/select");
  console.log("campaign", campaign.data);
  return (
    <Fragment>
      {/* render view data */}
      <Grid>
        <Row>
          <h1>Campaigns</h1>
          <br />
          <Card restrictWidth>
            {formSelect.loading === false ? (
              <Form
                data={{
                  name: {
                    label: "Campaign name",
                    type: "text",
                    required: true,
                    description: "description",
                    errorMessage: "Please enter your campaign name",
                  },
                  start_date: {
                    label: "Start Date",
                    type: "date",
                    required: true,
                    errorMessage: "Please enter the campaign start date",
                  },
                  end_date: {
                    label: "End Date",
                    type: "date",
                    required: true,
                    errorMessage: "Please enter the campaign end date",
                  },
                  form_id: {
                    label: "Form",
                    type: "select",
                    options: formSelect?.data,
                    required: true,
                  },
                }}
                url="/api/campaign"
                buttonText="Create"
                method="post"
                callback={(res) => {
                  console.log("message in callback", res);
                  History.push(`/campaign/view?campaignId=${res.data.data.id}`);
                }}
              />
            ) : (
              <Loader />
            )}
          </Card>
        </Row>
        <br />
        <Row>
          <h1>Existing Campaigns</h1>
          <br />
          <Table
            data={campaign?.data}
            search
            show={[
              "id",
              "name",
              "created_at",
              "updated_at",
              "account_id",
              "start_date",
              "end_date",
              "form_id",
              "email",
            ]}
            loading={campaign?.loading}
            actions={{
              view: { url: "/campaign", col: "id" },
            }}
          ></Table>
        </Row>
      </Grid>
    </Fragment>
  );
}
