import React, { Fragment } from 'react';
import { useAPI, Card, Article, Grid, Row } from 'components/lib';
import qs from 'querystring';
import { ReactFormBuilder } from 'react-form-builder2';
import './form.css';
import Axios from 'axios';

export function FormView(props) {
  const locations = props.location.search.substring(1);
  const queryParams = qs.parse(locations);
  const formData = useAPI(`/api/form/${queryParams.formId || ''}`);
  let formDataInfo = formData?.data?.at(0)?.name;

  return (
    <Fragment>
      {/* render view data */}
      <Card>
        <Article>
          <p>testing form view</p>
          <p>Name {formData?.data?.at(0)?.name}</p>
        </Article>
      </Card>
      <Grid>
        <Row>
          <ReactFormBuilder
            onPost={async (data) => {
              console.log('in post', queryParams.formId);
              const response = await Axios.post('/api/form/save', {
                id: queryParams.formId,
                fieldData: data.task_data,
              });
              console.log('response', data);
            }}
            onLoad={async () => {
              console.log('on load');
              const response = await Axios.get(
                `/api/form/${queryParams.formId}`
              );
              console.log('resp', response.data.data.at(0).fieldData.save);
              return response.data.data.at(0).fieldData.save;
            }}
          />
        </Row>
      </Grid>
    </Fragment>
  );
}
