import React, { useState, Fragment, useEffect, useContext } from "react";
import {
  useAPI,
  Card,
  Article,
  Grid,
  Row,
  Loader,
  FormSelect,
  Form,
  Button,
  ViewContext,
} from "components/lib";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.min.css";
import Axios from "axios";
import { get } from "jquery";

export function FormBuilder(props) {
  const campaignId = props.computedMatch.params.campaignId;
  const forms = useAPI("/api/form");
  const campaign = useAPI("/api/campaign/" + campaignId);
  const viewContext = useContext(ViewContext);
  const [formData, setFormData] = useState({});
  const [formId, setFormId] = useState("");
  const [fieldData, setFieldData] = useState({});
  const formSelect = useAPI("/api/form/select");
  const [formSelection, setFormSelection] = useState("");
  const [formCreatorMode, setFormCreatorMode] = useState("bypage");

  const getFormData = async (formId) => {
    const response = await Axios.get("/api/form/" + formId);
    setFormData(response.data.data);
    setFormId(response.data.data.id);
    setFieldData(response.data.data.fieldData);
  };

  useEffect(() => {
    if (campaign.data) {
      getFormData(campaign.data?.[0]?.form_id);
    }
  }, [campaign]);

  const handleChange = (e) => {
    console.log("in handle change", e.target.value);
    setFormSelection(e.target.value);
  };

  const creatorOptions = {
    showLogicTab: true,
    isAutoSave: true,
    haveCommercialLicense: true,
    showJSONEditorTab: false,
    pageEditMode: formCreatorMode,
    readOnly:
      !campaign?.loading && campaign?.data?.[0]?.status === "LIVE"
        ? true
        : false,
  };

  const creator = new SurveyCreator(creatorOptions);

  const propertyStopList = ["storeDataAsText", "valueName", "allowMultiple"];

  creator.onShowingProperty.add(function (sender, options) {
    options.canShow = propertyStopList.indexOf(options.property.name) == -1;
  });
  creator.JSON = fieldData;

  // save no does not exist or is 0
  if (!formData?.misc?.saveNo) {
    // add some sample fields
    creator.JSON = {
      pages: [
        {
          name: "page1",
          elements: [
            { name: "name", type: "text", title: "Name" },
            { name: "companyName", type: "text", title: "Company Name" },
            {
              name: "problem",
              type: "comment",
              title: "What problem are your solving?",
            },
            {
              name: "solution",
              type: "comment",
              title: "What is your solution?",
            },
          ],
        },
      ],
      title: "Sample Campaign",
      logoPosition: "right",
    };
  }
  creator.saveSurveyFunc = async (saveNo, callback) => {
    await saveSurveyJSON(
      "/api/form-fields/" + formId,
      creator.JSON,
      (formData.misc?.saveNo || 0) + saveNo,
      callback
    );
  };

  const saveSurveyJSON = async (url, json, saveNo, callback) => {
    const response = await Axios.patch(url, {
      fieldData: json,
      misc: { saveNo: saveNo },
    });
    if (response.data.success) {
      callback(saveNo, true);
    } else {
      callback(saveNo, false);
    }
  };

  const duplicate = async (data) => {
    const formInfo = forms.data.find(
      (form) => data.duplicateFormId === form.id
    );
    console.log("form info", formInfo);
    setFieldData(formInfo.fieldData);
    Axios.patch("/api/form/" + data.formId, {
      fieldData: formInfo.fieldData,
      misc: { saveNo: formData.misc.saveNo + 1 },
    });
    viewContext.notification.show(
      "New fields have been duplicated into this form",
      "success",
      true
    );
  };

  return (
    <>
      {campaign?.data?.[0]?.status === "LIVE" && (
        <Card className="align-center">
          <h1>Form is read only since the campaign is live.</h1>
        </Card>
      )}
      <Card>
        {!campaign?.loading && <SurveyCreatorComponent creator={creator} />}
      </Card>
      <Grid cols="2">
        <Row>
          <Card restrictWidth>
            {/* add label */}
            <FormSelect
              label="Select an existing campaign to copy it's form"
              onChange={handleChange}
              options={formSelect.data}
            />
            <Button
              text="Duplicate"
              color="blue"
              action={duplicate}
              params={{ duplicateFormId: formSelection, formId: formId }}
            />
            <p>Warning: This action WILL overwrite existing form.</p>
            <br />
            <FormSelect
              label="Select Form Builder Mode"
              onChange={(e) => setFormCreatorMode(e.target.value)}
              default={formCreatorMode}
              options={[
                {
                  value: "bypage",
                  label: "By Page",
                },
                {
                  value: "standard",
                  label: "Standard",
                },
              ]}
            />
          </Card>
        </Row>
        <Row>
          <Card className="align-center">
            <p>
              When you are finished making changes, hit next to set up the
              campaign details
            </p>
            <Button text="Next" goto={`/wizard/campaign/${campaignId}`} />
            <br />
            <br />
            <br />
            <br />
          </Card>
        </Row>
      </Grid>
    </>
  );
}
