// import { Home } from "views/website/home";
import { Contact } from "views/website/contact";
import { Pricing } from "views/website/pricing";
import { Terms } from "views/website/terms";
import { Privacy } from "views/website/privacy";
import { DemoForm } from "views/website/demoform";
import { PublicCampaign } from "views/website/campaign";
import { Thanks } from "views/website/thanks";
import { Signin } from "views/auth/signin";
import { GetStarted } from "views/website/getstarted";
// import { MonashCampaign } from "views/website/monash";
// import { MonashForm } from "views/website/monashForm";
// import { MonashForm2 } from "views/website/monashForm2";
import { MonashForm3 } from "views/website/monashForm3";
import { ContinueLater } from "views/website/continueLater";
import { DemoCampaign } from "views/website/demoCampaign";
import { DemoForm2 } from "views/website/demoForm2";
import { Submission } from "views/website/submission";
import { FishburnersCampaign } from "views/website/fishburners";
import { CampaignOver } from "views/website/campaignOver";
import { FormBuilderDemo } from "views/website/formBuilderDemo";
import { GenericCampaign } from "views/website/generic/campaign";
import { GenGetStarted } from "views/website/generic/getStarted";
import { FormBuilderDocs } from "views/website/docs/formBuilderDocs";
import { HubspotIntegrationDocs } from "views/website/docs/hubspotIntegrationDocs";
import { CampaignWizardDocs } from "views/website/docs/campaignWizardDocs";
import { CampaignOverGeneric } from "views/website/generic/campaignOver";

const Routes = [
  {
    path: "/",
    view: Signin,
    layout: "auth",
    title: "SubmitHero",
  },
  {
    path: "/contact",
    view: Contact,
    layout: "home",
    title: "Contact",
  },
  {
    path: "/pricing",
    view: Pricing,
    layout: "home",
    title: "Pricing",
  },
  {
    path: "/privacy",
    view: Privacy,
    layout: "home",
    title: "Privacy Policy",
  },
  {
    path: "/terms",
    view: Terms,
    layout: "home",
    title: "Terms & Conditions",
  },
  {
    path: "/campaign/view/:campaignId",
    view: GenericCampaign,
    layout: "campaign",
    title: "SubmitHero Campaign",
  },
  {
    path: "/campaign/start/:campaignId",
    view: GenGetStarted,
    layout: "campaign",
    title: "Get Started!",
  },
  {
    path: "/impact",
    view: DemoForm,
    layout: "onboarding",
    title: "Campaign Form",
  },
  {
    path: "/demo/campaign",
    view: DemoCampaign,
    layout: "onboarding",
    title: "Demo Campaign",
  },
  {
    path: "/demo/form",
    view: DemoForm2,
    layout: "onboarding",
    title: "Demo Form",
  },
  {
    path: "/demo/form/:id",
    view: Submission,
    layout: "onboarding",
    title: "Demo Form",
  },
  {
    path: "/public/campaign/monash",
    view: CampaignOver,
    layout: "onboarding",
    title: "Monash Generator Accelerator 2022",
  },
  {
    path: "/public/campaign/fishburners",
    view: FishburnersCampaign,
    layout: "onboarding",
    title: "GNI Application 2022",
  },
  {
    path: "/public/campaign",
    view: PublicCampaign,
    layout: "onboarding",
    title: "Campaign",
  },
  {
    path: "/public/form/monash",
    view: CampaignOver,
    layout: "onboarding",
    title: "Monash Generator Accelerator 2022",
  },
  {
    path: "/docs/form-builder",
    view: FormBuilderDocs,
    layout: "home",
    title: "Form Builder Documentation",
  },
  {
    path: "/docs/campaign-wizard",
    view: CampaignWizardDocs,
    layout: "home",
    title: "Campaign Wizard Documentation",
  },
  {
    path: "/docs/integrations/hubspot",
    view: HubspotIntegrationDocs,
    layout: "home",
    title: "HubSpot Integration Setup Documentation",
  },
  {
    path: "/public/form2/monash",
    view: MonashForm3,
    layout: "onboarding",
    title: "Monash Generator - Bottom up",
  },
  {
    path: "/public/form",
    view: DemoForm,
    layout: "onboarding",
    title: "Campaign",
  },
  {
    path: "/thanks/:campaign",
    view: Thanks,
    layout: "onboarding",
    title: "Thanks",
  },
  {
    path: "/get-started/:campaign",
    view: GetStarted,
    layout: "onboarding",
    title: "Get Started",
  },
  {
    path: "/get-started",
    view: GetStarted,
    layout: "onboarding",
    title: "Get Started",
  },
  {
    path: "/form-builder",
    view: FormBuilderDemo,
    layout: "home",
    title: "Form Builder",
  },
  {
    path: "/v2/submission/:id",
    view: Submission,
    layout: "campaign",
    title: "",
  },
  {
    path: "/continue-later",
    view: ContinueLater,
    layout: "onboarding",
    title: "Continue Later!",
  },
];

export default Routes;
