import React from "react";
import { Article, Image, List } from "components/lib";

export function CampaignWizardDocs(props) {
  return (
    <Article>
      <h1>Campaign Wizard - End User Documentation</h1>
      <p>
        Campaign wizard is where the SubmitHero users get to customize and
        personalize their campaign so that fits their needs. <br />
        There are 5 screens to navigate to get your campaign up and running:
        <br />
        <br />
        <List
          items={[
            "Key Dates and Contacts",
            "Landing Page",
            "Welcome Email",
            "Thanks Email",
            "Draft or Live",
          ]}
        />
      </p>
      <h2>Set key dates and email</h2>
      <Image source="https://storage.googleapis.com/submithero-images/Docs/campaign-wiz/cw-intro.png" />
      <p>
        Campaign submissions will only be collected between <b>Start Date</b>{" "}
        and <b>End Date</b>
      </p>
      <h2>Design the campaign Landing Page</h2>
      <img src="https://storage.googleapis.com/submithero-images/Docs/campaign-wiz/cw-landing-page.png" />
      <p>
        With SubmitHero, your campaign is automatically opened and closed on the
        dates selected. The submitter is directed to a landing page with a
        single field form: email. The banner and words displayed are
        customisable to better reflect the brand. When a potential applicant
        submits their email, they are sent a welcome email with a unique link to
        their application. This email link is open us their form submission
        which is auto saved.
      </p>
      <h2>Design the Welcome Email</h2>
      <img src="https://storage.googleapis.com/submithero-images/Docs/campaign-wiz/cw-landing-preview.png" />
      <p>
        When a potential applicant submits their email, they are sent a welcome
        email with a unique link to their application. This email link is open
        us their form submission which is auto saved. The applicant can return
        to it as many times as required to finalise their details before the end
        date - all their progress is auto saved.
      </p>
      <img src="https://storage.googleapis.com/submithero-images/Docs/campaign-wiz/cw-thanks-email.png" />
      <h2>Customize the welcome email</h2>
      <p>
        Upon completing the submission they will receive a “thanks email” which
        can also be customized.
      </p>
      <h2>Campaign Mode</h2>
      <img src="https://storage.googleapis.com/submithero-images/Docs/campaign-wiz/cw-thanks-preview.png" />
      <p>Finally you can launch the program in 2 modes: Draft Mode</p>
      <p>
        This is used to test out the campaign email and the form by internal
        teams. All submissions made during this time are deleted when moving to
        live mode.
      </p>
      <p>
        Live Mode: No more changes to the form or the campaign information in
        the wizard are allowed.
      </p>
      <img src="https://storage.googleapis.com/submithero-images/Docs/campaign-wiz/cw-final.png" />
    </Article>
  );
}
