import React from "react";
import { Row, Card } from "components/lib";

export function Thanks(props) {
  const campaignName = props.match.params.campaign;
  return campaignName === "monash" ? (
    <Row title="Thank you!">
      <Card>
        <p>
          Thank you for completing an application to join the Monash Generator's
          Accelerator Program in 2022.
          <br />
          Applications will close at 11.59pm AEST Monday 1 August 2022. All
          applications will be assessed after this date and you will be
          contacted about the outcome of your application following our
          assessment.
          <br />
          <br />
          Second stage interviews will be held across two days - Tuesday 16 and
          Wednesday 17 August and the program will commence on Monday 5
          September.
          <br />
          <br />
          For more details on the program, please check out{" "}
          <a href="https://www.monash.edu/entrepreneurship/programs/accelerator">
            https://www.monash.edu/entrepreneurship/programs/accelerator
          </a>
          . If you have any further enquiries, please email{" "}
          <a href="mailto:entrepreneurship@monash.edu">
            entrepreneurship@monash.edu
          </a>
        </p>
      </Card>
    </Row>
  ) : (
    <Row title="Thank You">
      <Card>
        <p>
          Thanks, for submitting your application.
          <br />
          We’ll keep you informed of the progress of your entry via email
          notifications.
        </p>
      </Card>
    </Row>
  );
}
