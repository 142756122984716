import React from "react";
import { Row, Card, Image, Article } from "components/lib";
import GENLogo from "./images/gen_banner.png";

export function GetStarted(props) {
  const campaignName = props.match.params.campaign;
  return campaignName === "monash" ? (
    <Row title="Let's Get Started!">
      <Card>
        <Image source={GENLogo} width={1000} height={1200} />
        <Article>
          <h2>
            Please check your email for your application link. Make sure to
            check your spam or promotions folders if it hasn't made it to your
            inbox!.
          </h2>
          <p>
            Completing the application form will take approximately 30 - 40
            minutes and require some background on you, your cofounders/team
            members and your project. As part of the application form you will
            also need to include a link to 60-second video introducing yourself
            and any cofounders.
            <br />
            The application will autosave, and until you press submit you can
            leave and return to edit your application as many times as you like.
            Make sure to submit your completed application prior to the
            application deadline, 11.59pm AEST Monday 1 August 2022.
            <br />
            <br />
          </p>

          <p>
            If you have any problems or questions please contact{" "}
            <a href="mailto:entrepreneurship@monash.edu">
              entrepreneurship@monash.edu
            </a>
          </p>
        </Article>
      </Card>
    </Row>
  ) : (
    <Row title="Get Started!">
      <Card>
        <h2>Please check your email.</h2>
        <p>
          Thanks, for your interest in SHIFT!
          <br />
          You will receive an email shortly with instructions on how to get
          started. If you don’t receive an email, please check your spam folder
          or contact us at{" "}
          <a href="mailto:shift@blueimpacts.com"> shift@blueimpacts.com</a>
        </p>
      </Card>
    </Row>
  );
}
