import { Notification } from "views/notification";
import { Submission } from "views/submission";
import { Campaign } from "views/campaign";
import { FormComponent } from "views/form";
import { FormView } from "views/formView";
import { Dashboard } from "views/dashboard/dashboard";
import { Help } from "views/dashboard/help";
import { OnboardingView } from "views/onboarding/onboarding";
import { Thanks } from "views/website/thanks";
import { SubmissionView } from "views/dashboard/submissionView";
import { SubmissionViewV2 } from "views/dashboard/submissionViewV2";
import { SurveyTest } from "views/SurveyTest";
import { SurveyBuilder } from "views/surveyBuilder";
import { CSV } from "views/csv";
import { CampaignTable } from "views/campaignTable";
import { CampaignWizard } from "views/dashboard/campaignWizard";
import { FormBuilder } from "views/dashboard/formBuilder";
import { HubSpotSetup } from "views/integration/hubspotSetup";
import { NotionSetup } from "views/integration/notionSetup";
import { NotionExport } from "views/integration/notionExport";
import { CampaignSummary } from "views/campaignSummary";
import { CampaignSendNow } from "views/campaignSendNow";
import { NotificationSettings } from "views/notificationSettings";

const Routes = [
  {
    path: "/dashboard",
    view: Dashboard,
    layout: "app",
    permission: "user",
    title: "Dashboard",
  },
  {
    path: "/welcome",
    view: OnboardingView,
    layout: "onboarding",
    permission: "user",
    title: "Welcome",
  },
  {
    path: "/help",
    view: Help,
    layout: "app",
    permission: "user",
    title: "Get Help",
  },
  {
    path: "/form",
    view: FormComponent,
    layout: "app",
    permission: "user",
    title: "Form",
  },
  {
    path: "/formexp",
    view: SurveyTest,
    layout: "app",
    permission: "user",
    title: "Survey Tests",
  },
  {
    path: "/fb/:formId",
    view: SurveyBuilder,
    layout: "app",
    permission: "user",
    title: "Form Builder",
  },
  {
    path: "/form/view",
    view: FormView,
    layout: "app",
    permission: "user",
    title: "View Form",
  },
  {
    path: "/campaign",
    view: Campaign,
    layout: "app",
    permission: "user",
    title: "Campaign",
  },
  {
    path: "/cw",
    view: CampaignWizard,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/wizard/form/:campaignId",
    view: FormBuilder,
    layout: "app",
    permission: "user",
    title: "Form Builder",
  },
  {
    path: "/wizard/campaign/:campaignId",
    view: CampaignWizard,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/campaign/:campaignId/view",
    view: CampaignSummary,
    layout: "app",
    permission: "user",
    title: "Campaign Control Centre",
  },
  {
    path: "/campaign/send/:campaignId",
    view: CampaignSendNow,
    layout: "app",
    permission: "user",
    title: "Send Campaign Emails",
  },
  {
    path: "/campaign/:campaignId/notification",
    view: Notification,
    layout: "app",
    permission: "user",
    title: "Campaign Notification Settings",
  },
  {
    path: "/campaign/:campaignId/notification/:notificationId",
    view: NotificationSettings,
    layout: "app",
    permission: "user",
    title: "Notification Settings",
  },
  {
    path: "/submission",
    view: Submission,
    layout: "app",
    permission: "user",
    title: "Submission",
  },
  {
    path: "/integrations/hubspot/setup",
    view: HubSpotSetup,
    layout: "app",
    permission: "user",
    title: "Setup HubSpot Integration",
  },
  {
    path: "/integration/notion/setup",
    view: NotionSetup,
    layout: "app",
    permission: "user",
    title: "Export Campaign Submission to Notion",
  },
  {
    path: "/integration/notion/setup/:campaignId",
    view: NotionExport,
    layout: "app",
    permission: "user",
    title: "Export Campaign Submission to Notion",
  },
  {
    path: "/integrations/csv",
    view: CSV,
    layout: "app",
    permission: "user",
    title: "CSV",
  },
  {
    path: "/campaign/:id",
    view: CampaignTable,
    layout: "app",
    permission: "user",
    title: "Campaign Table Viewer",
  },
  {
    path: "/submission/view/:id",
    view: SubmissionViewV2,
    layout: "app",
    permission: "user",
    title: "Submission",
  },
  {
    path: "/submission/:id",
    view: SubmissionView,
    layout: "app",
    permission: "user",
    title: "View Submission",
  },
];

export default Routes;
