import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Loader,
  useAPI,
  Grid,
  Article,
  Row,
  ViewContext,
  Table,
  History,
} from "components/lib";
import Axios from "axios";

export function HubSpotSetup(props) {
  let campaignsAPI = useAPI("/api/campaign");
  const [campaigns, setCampaigns] = useState([]);
  const viewContext = useContext(ViewContext);
  // const hubspotConnectionCheck = useAPI("/api/");

  useEffect(() => {
    const flattenCampaign = async () => {
      console.log("in flatten", campaignsAPI);

      if (campaignsAPI?.data?.length > 0) {
        const campaignsFlat = campaignsAPI?.data.map((c) => {
          return {
            id: c.id,
            name: c.name,
            start_date: c.start_date,
            end_date: c.end_date,
            active: c.misc.hsActive || false,
          };
        });

        setCampaigns(campaignsFlat);
      }
    };
    flattenCampaign();
  }, [campaignsAPI]);

  if (campaignsAPI?.data?.length === 0) {
    return (
      <Fragment>
        <Grid>
          <Row>
            <Article>
              <h3>
                No campaigns found. You can configure your HubSpot settings
                later.
              </h3>
            </Article>
          </Row>
        </Grid>
      </Fragment>
    );
  }

  if (campaignsAPI?.loading) {
    return <Loader />;
  }

  const markAsHubspotActive = async (data) => {
    console.log(data);
    const res = await Axios.post("/api/integration/hubspot/active/" + data.id);
    viewContext.notification.show(
      "HubSpot integration has been turned ON",
      "success",
      true
    );
    const updatedCampaigns = campaigns.map((c) => {
      if (c.id === data.id) {
        c.active = true;
      }
      return c;
    });
    setCampaigns(updatedCampaigns);
  };

  const markAsHubspotInactive = async (data) => {
    console.log(data);
    const res = await Axios.post(
      "/api/integration/hubspot/inactive/" + data.id
    );
    viewContext.notification.show(
      "HubSpot integration has been turned OFF",
      "warning",
      true
    );
    const updatedCampaigns = campaigns.map((c) => {
      if (c.id === data.id) {
        c.active = false;
      }
      return c;
    });
    setCampaigns(updatedCampaigns);
  };

  return (
    <Fragment>
      <Grid>
        <Row>
          <h1>Campaigns</h1>
          <p>
            Select campaigns where you would like SubmitHero to update HubSpot
            with applicant activity in real time
          </p>
          <br />

          {!campaignsAPI?.loading && (
            <Table
              data={campaigns}
              show={["name", "start_date", "end_date", "active"]}
              search
              actions={{
                custom: [
                  {
                    icon: "play",
                    action: markAsHubspotActive,
                    condition: { col: "active", value: false },
                  },
                  {
                    icon: "pause",
                    action: markAsHubspotInactive,
                    condition: { col: "active", value: true },
                  },
                ],
              }}
            ></Table>
          )}
        </Row>
      </Grid>
    </Fragment>
  );
}
