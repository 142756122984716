const campaignSendNowJson = {
  title: "Send Emails Now",
  description: "Use this builder to send email notifications now",
  logoPosition: "right",
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "text",
          name: "campaignId",
          visible: false,
          title: "Campaign Id",
          isRequired: true,
        },
        {
          type: "text",
          name: "supportEmail",
          visible: false,
          title: "Support Email",
          isRequired: true,
        },
        {
          type: "radiogroup",
          name: "recipientList",
          title: "Which list needs to be sent an email?",
          isRequired: true,
          choices: [
            {
              value: "all",
              text: "All",
            },
            {
              value: "CREATED",
              text: "Created",
            },
            {
              value: "IN_PROGRESS",
              text: "In Progress",
            },
            {
              value: "SUBMITTED",
              text: "Submitted",
            },
          ],
        },
      ],
    },
    {
      name: "page2",
      elements: [
        {
          type: "text",
          name: "subject",
          title: "Subject",
          isRequired: true,
        },
        {
          type: "text",
          name: "sendNowTitle",
          title: "Title",
        },
        {
          type: "file",
          name: "sendNowEmailBanner",
          title: "Email Banner",
          description:
            "Upload an image to be shown on the first email users receive.",
          allowMultiple: true,
        },
        {
          type: "comment",
          name: "emailBody",
          title: "Body (HTML)",
          isRequired: true,
        },
        {
          type: "boolean",
          name: "linkToSubmission",
          title: "Link to Submission",
          isRequired: true,
        },
      ],
      title: "What to send",
    },
    {
      name: "page3",
      elements: [
        {
          type: "html",
          name: "preview",
          title: "Preview",
          html: `
          <head>
          <style media="all" type="text/css">
            .outerbox {
              background-color: #FF554B;
            }
        
            .logo {
              padding: 10px;
              margin: 10px;
            }
        
            .content {
              background-color: #fff;
              margin: 25px;
              padding: 10px;
              text-align: left;
              font-family: sans-serif;
              font-size: 14px;
              vertical-align: top;
              padding-bottom: 15px;
            }
        
            .button {
              padding: 20px;
              display: block;
              color: #ffffff;
              background-color: #0032E4;
              border-radius: 4px;
              box-sizing: border-box;
              cursor: pointer;
              text-decoration: none;
              font-family: \'Source Sans Pro\', helvetica, arial, sans-serif;
              font-size: 18px;
              font-weight: 700;
              margin: 0;
              text-transform: uppercase;
              border-color: #0032E4;
              margin-left: auto;
              margin-right: auto;
            }
          </style>
        </head>
        <div>
        Subject: {subject}
        </div>
        <div style class="outerbox">
          <div align="center" class="logo">
            <img src="{validatedImage}" width="500" alt="Logo" />
            <div class="content">
              <h1>{sendNowTitle}</h1>
              <article>{emailBody}</article>
             {showButtonFunction}
            </div>
          </div>   
          `,
        },
      ],
    },
  ],
  showPageTitles: false,
  showQuestionNumbers: "off",
  completeText: "Send",
  completedHtml: "<h3>Emails are now enqueued to be sent!</h3>",
  calculatedValues: [
    {
      name: "validatedImage",
      expression: "defaultImageFunction({sendNowEmailBanner})",
      includeIntoResult: false,
    },
    {
      name: "showButtonFunction",
      expression: "showButtonFunction({linkToSubmission})",
      includeIntoResult: false,
    },
  ],
};

export default campaignSendNowJson;
