import React, {
  Fragment,
  // useContext,
  // useEffect,
  // useState,
} from "react";
import {
  Loader,
  useAPI,
  Grid,
  Article,
  Row,
  Link,
  // Button,
  // Fieldset,
  // FormSelect,
  // Form,
  // ViewContext,
} from "components/lib";
import "../../../src/components/list/list.scss";
// import Axios from "axios";

export function NotionSetup(props) {
  let campaignsAPI = useAPI("/api/campaign");
  // const notionPages = useAPI("/api/integration/notion/pages");
  // console.log("notion pages", notionPages);
  // const [selectedCampaign, setSelectedCampaign] = useState("");
  // const [selectedNotionPage, setSelectedNotionPage] = useState(null);
  // const [successNotification, setSuccessNotification] = useState(false);
  // const viewContext = useContext(ViewContext);
  // const [formData, setFormData] = useState({});

  // async function addOptions(campaignId) {
  //   if (selectedCampaign === "") {
  //     return;
  //   }
  //   const campaignInfo = await Axios.get("/api/campaign/" + campaignId);
  //   console.log("campaign info", campaignInfo);
  //   const formId = campaignInfo.data.data[0].form_id;
  //   const formInfo = await Axios.get("/api/form/" + formId);
  //   const pages = formInfo.data.data.fieldData.pages;
  //   const pagedElements = pages.map((p) => p.elements);
  //   const elements = pagedElements.flat();
  //   const fields = elements.map((e) => e.title || e.name);
  //   console.log("form info pages", fields);
  //   console.log("id", campaignId);
  //   let newFormData = formData;
  //   newFormData.fields = {
  //     type: "checkbox",
  //     label:
  //       "Select which fields should be added to the main table as columns?",
  //     max: 5,
  //     options: fields,
  //     // options: [
  //     //   "Events",
  //     //   "The Validator",
  //     //   "Startup Sprint",
  //     //   "Researcher to Innovator",
  //     //   "The Accelerator",
  //     //   "1:1 Office hours",
  //     //   "Other",
  //     // ],
  //   };
  //   console.log("newform", newFormData);
  //   // Object.assign(formData, newFormData);
  //   setFormData(newFormData);
  // }

  // useEffect(() => {
  //   console.log("in campaign effect");
  //   if (selectedCampaign !== "") {
  //     addOptions(selectedCampaign);
  //   }
  // }, [selectedCampaign, addOptions]);

  // useEffect(() => {
  //   console.log("in effect");
  //   if (notionPages.data) {
  //     // formData =
  //     setFormData({
  //       sectionHeader: {
  //         title: "Notion Export",
  //         type: "header",
  //       },
  //       campaigns: {
  //         type: "radio",
  //         label: "Please select a campaign",
  //         options: campaignsAPI?.data?.map((campaign) => ({
  //           value: campaign.id,
  //           label: campaign.name,
  //         })),
  //       },
  //     });
  //   }
  // }, [notionPages]);

  // const formData = {
  //   sectionHeader: {
  //     title: "Select a Campaign to export",
  //     type: "header",
  //   },
  //   campaign: {
  //     type: "radio",
  //     label: "Please select a campaign",
  //     required: true,
  //     options: campaignsAPI?.data?.map((campaign) => ({
  //       value: campaign.id,
  //       label: campaign.name,
  //     })),
  //   },
  // };

  if (campaignsAPI?.data?.length === 0) {
    return (
      <Fragment>
        <Grid>
          <Row>
            <Article>
              <h3>
                No campaigns found. You can configure your Notion settings
                later.
              </h3>
            </Article>
          </Row>
        </Grid>
      </Fragment>
    );
  }

  // const handleChange = (e) => {
  //   console.log("in handle change", e.target.value);
  //   setSelectedCampaign(e.target.value);
  // };

  // const handleSelection = (e) => {
  //   console.log("in handle selection", e);
  //   // setSelectedCampaign(e.target.value);
  // };

  // const handlePageChange = (e) => {
  //   setSelectedNotionPage(e.target.value);
  // };

  // const exportToNotion = async () => {
  //   if (!selectedCampaign || !selectedNotionPage) {
  //     viewContext.notification.show(
  //       "Select both a campaign and a notion page",
  //       "error",
  //       true
  //     );
  //     return;
  //   }
  //   const res = await Axios.post("/api/integration/notion/export", {
  //     campaignId: selectedCampaign,
  //     pageId: selectedNotionPage,
  //   });
  //   setSuccessNotification(true);
  // };

  if (campaignsAPI?.loading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Grid>
        <Row>
          {/* <h1>Export to notion</h1> */}
          {/* <br /> */}

          {/* {!campaignsAPI?.loading && ( */}
          <>
            {/* <FormSelect
                onChange={handleChange}
                label="Select a Campaign"
                options={campaignsAPI?.data?.map((campaign) => ({
                  value: campaign.id,
                  label: campaign.name,
                }))}
              ></FormSelect> */}
            {/* <Fieldset
                label="What has your previous involvement with the Monash Generator been?"
                type="checkbox"
                options={[
                  "Events",
                  "The Validator",
                  "Startup Sprint",
                  "Researcher to Innovator",
                  "The Accelerator",
                  "1:1 Office hours",
                  "Other",
                ]}
                onChange={handleSelection}
              /> */}

            {/* <fieldset className={css}>
                <Legend text={props.label} required={props.required} />

                <Grid cols="3">
                  {props.options.map((option, index) => {
                    return (
                      <Input
                        key={index}
                        name={props.name}
                        index={index}
                        option={option}
                        checked={checked[index]}
                        callback={change}
                      />
                    );
                  })}
                </Grid>
              </fieldset> */}
          </>
          {/* )} */}

          {/* {!notionPages.loading && notionPages.data !== null && (
            <>
              <FormSelect
                onChange={handlePageChange}
                label="Select the destination Notion page"
                options={notionPages?.data.map((page) => ({
                  value: page.id,
                  label: page?.properties?.title?.title[0]?.plain_text,
                }))}
              ></FormSelect>
            </>
          )} */}
          {/* <Button text="Export" action={exportToNotion} /> */}
          {/* {successNotification && <p>Export Successful</p>} */}

          {/* {!campaignsAPI?.loading && (
            <Form
              data={formData}
              method="POST"
              // updateOnChange
              // onChange={(e) => {
              //   setSelectedCampaign(e.value);
              // }}
              buttonText="Select"
              url={`/api/integration/notion/select`}
              // url={"/integration/notion/setup/" + "abx"}
            />
          )} */}
          {!campaignsAPI?.loading && (
            <ul>
              <h1>Please select a campaign to export</h1>
              {campaignsAPI?.data?.map((campaign) => (
                <>
                  <li key={campaign.id}>
                    <h2>
                      <Link
                        url={`/integration/notion/setup/${campaign.id}`}
                        text={`- ${campaign.name}`}
                      />
                    </h2>
                  </li>
                </>
              ))}
            </ul>
          )}
        </Row>
      </Grid>
    </Fragment>
  );
}
