/***
 *
 *   PUBLIC CAMPAIGN FORM - Monash
 *   Public campaign form
 *
 **********/

import React from "react";
import { Row, Form, Card, Article, Image } from "components/lib";
import qs from "querystring";

export function FishburnersCampaign(props) {
  const locations = props.location.search.substring(1);
  const queryParams = qs.parse(locations);

  return (
    <Row title="GNI Applications">
      <Card>
        <Image
          source={
            "https://media.cdn.gradconnection.com/uploads/5e563bb6-5b06-4ff0-a276-daca65a352d3-fb-banner.png"
          }
          width={1000}
          height={1200}
        />
        <Article>
          <p>Intro text</p>
          <p>
            If you have any problems or questions please contact{" "}
            <a href="mailto:entrepreneurship@fishburners.org">
              entrepreneurship@fishburners.org
            </a>
            <br />
            <br />
            <p>To start or continue, please enter your email below.</p>
          </p>
          <Card restrictWidth center>
            <Form
              data={{
                email: {
                  label: "Your Email",
                  type: "email",
                  required: true,
                  errorMessage: "Please enter your email",
                },
                campaign_id: {
                  label: "campaign id",
                  type: "hidden",
                  value: queryParams.id,
                },
              }}
              method="POST"
              url="/api/submission/start/fishburners"
              redirect="/get-started/fishburners"
              buttonText="Let's Go!"
            />
          </Card>
        </Article>
      </Card>
    </Row>
  );
}
