import React from "react";
import { Row, Card } from "components/lib";

export function ContinueLater(props) {
  return (
    <Row title="Your changes are saved!">
      <Card>
        <p>
          When you're ready to continue editing, please use the original
          application form link that was sent to your email address or{" "}
          <a href="https://www.submithero.io/public/campaign/monash?id=8e310e72-87ce-4ec0-8936-27fddeb93169">
            go here and enter your email to get the link again
          </a>
          <br />
          <br />
          Applications will close at{" "}
          <strong>11.59pm AEST Monday 1 August 2022</strong>. All applications
          will be assessed after this date and you will be contacted about the
          outcome of your application following our assessment.
          <br />
          <br />
          Second stage interviews will be held across two days - Tuesday 16 and
          Wednesday 17 August and the program will commence on Monday 5
          September.
          <br />
          <br />
          For more details on the program, please check out{" "}
          <a href="https://www.monash.edu/entrepreneurship/programs/accelerator">
            https://www.monash.edu/entrepreneurship/programs/accelerator
          </a>
          . If you have any further enquiries, please email{" "}
          <a href="mailto:entrepreneurship@monash.edu">
            entrepreneurship@monash.edu
          </a>
        </p>
      </Card>
    </Row>
  );
}
