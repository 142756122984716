/***
 *
 *   Submission Viewer
 *   View the current state of the submission
 *
 **********/

import React, { Fragment, useState, useEffect } from "react";
import { Card, Form, Message, Loader, useAPI, Grid } from "components/lib";
import countries from "../website/countries";
import axios from "axios";
import { Submission } from "views/submission";

export function SubmissionView(props) {
  const submission = useAPI("/api/submission/" + props.computedMatch.params.id);
  const savedFormData = submission;
  const user = useAPI("/api/user");
  const [teamLeadAffiliation, setTeamLeadAffiliation] = useState("");
  const [formValues, setFormValues] = useState({});
  const [teamMemberAffiliation2, setTeamMemberAffiliation2] = useState("");
  const [teamMemberAffiliation3, setTeamMemberAffiliation3] = useState("");
  const [teamMemberAffiliation4, setTeamMemberAffiliation4] = useState("");
  const [teamMemberAffiliation5, setTeamMemberAffiliation5] = useState("");

  const impactFormData = {
    // Contact Details
    sectionHeader: {
      title: "Primary Contact Details",
      type: "header",
    },
    primaryFirstName: {
      label: "First Name",
      type: "text",
      required: true,
      value: submission?.data?.submission_data?.primaryFirstName,
      errorMessage: "Please enter the primary contact's first name",
    },
    primarySurname: {
      label: "Surname",
      type: "text",
      required: true,
      value: submission?.data?.submission_data?.primarySurname,
      errorMessage: "Please enter the primary contact's surname",
      // maxlength: 4,
    },
    mobilePhone: {
      label: "Mobile Phone (Please include country code)",
      type: "text",
      required: false,
      value: submission?.data?.submission_data?.mobilePhone,
      errorMessage: "Please enter a mobile phone",
    },
    city: {
      label: "Please indicate the city you reside in",
      type: "text",
      required: true,
      value: submission?.data?.submission_data?.city,
      errorMessage: "Please enter the city you're based in",
    },
    imppactxCity: {
      label: "SHIFT! City",
      type: "select",
      required: true,
      value: submission?.data?.submission_data?.imppactxCity,
      errorMessage: "Please select an Impactx City",
      options: [
        { value: "SYD", label: "Sydney" },
        { value: "JKT", label: "Jakarta" },
        { value: "KL", label: "Kuala Lumpur" },
        // { value: 'CTU', label: 'Chengdu' },
        // { value: 'HAK', label: 'Haikou' },
        // { value: 'MNL', label: 'Manilla' },
        // { value: 'PEK', label: 'Beijing' },
        // { value: 'SIN', label: 'Singapore' },
      ],
    },
    country: {
      label: "Country",
      type: "select",
      options: countries,
      default: submission?.data?.submission_data?.country,
      required: true,
      errorMessage: "Please select a country",
    },
    title: {
      label: "Role in Startup",
      type: "text",
      required: true,
      value: submission?.data?.submission_data?.title,
      errorMessage: "Please enter your title title",
    },
    sectionYourStartup: {
      title: "Your Startup",
      type: "header",
    },
    // your startup
    startupName: {
      label: "Startup Name",
      type: "text",
      required: true,
      value: submission?.data?.submission_data?.startupName,
      errorMessage: "Please enter a startup name",
    },
    url: {
      label: "Startup Website",
      type: "url",
      required: false,
      value: submission?.data?.submission_data?.url,
      errorMessage: "Please enter your startup's website",
    },
    startupSocialMedia: {
      label: "Startup's social media account",
      type: "url",
      required: false,
      value: submission?.data?.submission_data?.startupSocialMedia,
      errorMessage: "Please enter your startup's social media account",
    },
    stage: {
      label: "What stage is your startup at?",
      type: "select",
      options: [
        { value: "Idea", label: "Idea" },
        { value: "Prototype", label: "Prototype" },
        { value: "MVP", label: "MVP" },
        { value: "Version 1.0", label: "Version 1.0" },
        { value: "Product/Customer Fit", label: "Product/Customer Fit" },
        { value: "Other", label: "Other" },
      ],
      required: true,
      default: submission?.data?.submission_data?.stage,
      errorMessage: "Please select a stage",
    },
    sector: {
      label:
        "Which sector best describes your startup and the problem it's solving?",
      type: "select",
      options: [
        { value: "Food Systems", label: "Food Systems" },
        { value: "Energy and carbon", label: "Energy and carbon" },
        { value: "Resilient cities", label: "Resilient cities" },
        { value: "Circular economy", label: "Circular economy" },
      ],
      required: true,
      default: submission?.data?.submission_data?.sector,
      errorMessage: "Please make a selection for the sector",
    },
    primaryTargetCustomers: {
      label: "Primary Customers",
      hint: "Describe your primary target customers",
      type: "textarea",
      required: true,
      value: submission?.data?.submission_data?.primaryTargetCustomers,
      errorMessage: "Please describe your primary target customers",
      // maxlength: 24,
    },
    secondaryTargetCustomers: {
      label: "Secondary Customers",
      hint: "Describe your secondary target customers",
      type: "textarea",
      required: true,
      value: submission?.data?.submission_data?.secondaryTargetCustomers,
      errorMessage: "Please describe your secondary target customers",
    },
    problem: {
      hint: "Describe the problem your company is solving in one sentence",
      label: "Problem",
      type: "textarea",
      required: true,
      value: submission?.data?.submission_data?.problem,
      errorMessage:
        "Please describe the problem you are solving in one sentence",
    },
    solution: {
      hint: "How are you solving that problem (what does your company do?) in one sentence",
      label: "Solution",
      type: "textarea",
      required: true,
      value: submission?.data?.submission_data?.solution,
      errorMessage: "Please describe the what does your company do?",
    },
    currentSolution: {
      hint: "What is the most common way this problem is solved currently?",
      label: "Current Solution",
      type: "textarea",
      required: true,
      value: submission?.data?.submission_data?.currentSolution,
      errorMessage:
        "Please describe the common way this problem is solved currently",
    },
    whyBetter: {
      label: "How is your solution better?",
      type: "textarea",
      value: submission?.data?.submission_data?.whyBetter,
      required: true,
      errorMessage: "Please describe how is your solution better?",
    },
    moat: {
      hint: "Why isn’t someone already doing this?",
      label: "Moat",
      type: "textarea",
      value: submission?.data?.submission_data?.moat,
      required: true,
      errorMessage: "Please describe why isn’t someone already doing this",
    },
    whySuceed: {
      label: "Why will you succeed over others?",
      type: "textarea",
      value: submission?.data?.submission_data?.whySuceed,
      required: true,
      errorMessage: "Please describe why will you succeed over others?",
    },
    sectionGTM: {
      title: "Path to market",
      type: "header",
    },
    marketingChannel: {
      hint: "Do you sell directly or through a partner or distribution network?",
      label: "Direct or Indirect sales model?",
      type: "textarea",
      required: true,
      value: submission?.data?.submission_data?.marketingChannel,
      errorMessage: "Please describe your sales channels",
    },
    primaryChannel: {
      hint: "What is your primary marketing channel - how do customers find out about you?",
      label: "Marketing Channel",
      type: "textarea",
      required: true,
      value: submission?.data?.submission_data?.primaryChannel,
      errorMessage: "Please describe your primary channel",
    },
    traction: {
      hint: "Give us an idea of your traction to date. Do you have customers? How much revenue are you generating? If you don't have customers, what have you done to validate the idea you are solving?",
      label: "Traction",
      type: "textarea",
      required: true,
      value: submission?.data?.submission_data?.traction,
      errorMessage: "Please describe your traction",
    },
    achievements: {
      hint: "What's your startup's most impressive achievement so far?",
      label: "Achievements",
      type: "textarea",
      required: true,
      value: submission?.data?.submission_data?.achievements,
      errorMessage: "Please share your startups achievement",
    },
    sectionFinancials: {
      title: "Financials",
      type: "header",
    },
    incorporationStatus: {
      label: "Is the startup an incorporated company?",
      type: "radio",
      default: submission?.data?.submission_data?.incorporationStatus,
      required: "yes",
      options: ["Yes", "No"],
    },
    countryOfIncorporation: {
      label: "If yes, which country was the startup incorporated in?",
      type: "text",
      required: false,
      value: submission?.data?.submission_data?.countryOfIncorporation,
      errorMessage: "Please select a country of incorporation",
    },
    grantFunding: {
      label: "What grant funding have you already received? (In USD)",
      type: "number",
      min: 0,
      required: true,
      value: submission?.data?.submission_data?.grantFunding || 0,
    },
    investmentFunding: {
      label: "What investment funding have you already received? (In USD)",
      type: "number",
      required: true,
      min: 0,
      value: submission?.data?.submission_data?.investmentFunding || 0,
    },
    raiseAmount: {
      label: "If you're currently raising, how much are you raising? (In USD)",
      type: "number",
      required: true,
      min: 0,
      value: submission?.data?.submission_data?.raiseAmount || 0,
    },
    sectionTeam: {
      title: "Team",
      type: "header",
    },
    howManyCofounders: {
      label: "How many co-founders in total?",
      type: "number",
      required: false,
      max: 5,
      value: submission?.data?.submission_data?.howManyCofounders,
    },
    cofounder1Name: {
      label: "Founder 1 Full Name",
      type: "text",
      required: false,
      value: submission?.data?.submission_data?.cofounder1Name,
    },
    coFounder1Social: {
      label: "Founder 1 social media profile",
      type: "url",
      required: false,
      value: submission?.data?.submission_data?.coFounder1Social,
    },
    cofounder2Name: {
      label: "Founder 2 Full Name",
      type: "text",
      required: false,
      value: submission?.data?.submission_data?.cofounder2Name,
    },
    coFounder2Social: {
      label: "Founder 2 social media profile",
      type: "url",
      required: false,
      value: submission?.data?.submission_data?.coFounder2Social,
    },
    cofounder3Name: {
      label: "Founder 3 Full Name",
      type: "text",
      required: false,
      value: submission?.data?.submission_data?.cofounder3Name,
    },
    coFounder3Social: {
      label: "Founder 3 social media profile",
      type: "url",
      required: false,
      value: submission?.data?.submission_data?.coFounder3Social,
    },
    cofounder4Name: {
      label: "Founder 4 Full Name",
      type: "text",
      required: false,
      value: submission?.data?.submission_data?.cofounder4Name,
    },
    coFounder4Social: {
      label: "Founder 4 social media profile",
      type: "url",
      required: false,
      value: submission?.data?.submission_data?.coFounder4Social,
    },
    fullTime: {
      hint: "Are all founders full time on the business? (If not - describe the situation and when they will be joining full-time)?",
      label: "Employment",
      type: "textarea",
      required: true,
      errorMessage: "Please share the cofounder work status",
      value: submission?.data?.submission_data?.fullTime,
    },
    video: {
      hint: `Upload or link to a short (maximum 3 minutes) video of you/your founding team addressing: \n
      1.	the problem your startup is solving \n
      2.	how you are addressing the problem \n
      3.	what you have achieved to date (traction)
      4.	why you are the right team to address the problem
      Use a service list youtube to host the video.
      `,
      label: "Video",
      type: "url",
      required: true,
      errorMessage: "Please provide a link to the video",
      value: submission?.data?.submission_data?.video,
    },
    sectionImpact: {
      title: "Impact",
      type: "header",
    },
    yourImpact: {
      hint: "Does your startup have any frameworks to estimate the impact per service/ product sold? If yes, can you give a few examples?",
      label: "Your Imapact",
      type: "textarea",
      required: false,
      value: submission?.data?.submission_data?.yourImpact,
    },
    scalingPlans: {
      hint: "What are your plans for scaling impact with your service/ product over time?",
      label: "Scaling Impact",
      type: "textarea",
      required: true,
      errorMessage: "Please describe your plans to scale",
      value: submission?.data?.submission_data?.scalingPlans,
    },
    communicatingImpact: {
      hint: "How do you communicate these vision/ metrics to your stakeholders (clients, partners, government, etc.)?",
      label: "Communicating your impact",
      type: "textarea",
      required: true,
      errorMessage: "Please describe your plans to communicate your vision",
      value: submission?.data?.submission_data?.communicatingImpact,
    },
    impactResponsibilty: {
      label:
        "Does your team have a dedicated person in charge of the impact’s measurement and reporting?",
      type: "radio",
      options: ["Yes", "No"],
      required: true,
      errorMessage: "Please make a selection",
      default: submission?.data?.submission_data?.impactResponsibilty,
    },
    sectionDiversity: {
      title: "Diversity, Inclusiveness and Accessibility",
      type: "header",
    },
    woman: {
      label: "Do you or any of your founding team identify as a woman?",
      type: "select",
      options: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
        { value: "NA", label: "Prefer not to disclose" },
      ],
      required: false,
      default: submission?.data?.submission_data?.woman,
    },
    sectionConnection: {
      title: "Connection to SHIFT",
      type: "header",
    },
    connection: {
      hint: "How did you hear about SHIFT?",
      label: "Connection",
      type: "textarea",
      required: "yes",
      errorMessage: "Please describe how your heard about SHIFT",
      value: submission?.data?.submission_data?.connection,
    },
    metWithImpact: {
      label:
        "Have you met or spoken with any of the Impact X team? If yes, who?",
      type: "text",
      value: submission?.data?.submission_data?.metWithImpact,
    },
    referral: {
      label: "Do you have a referral code for your application?",
      type: "text",
      required: false,
      value: submission?.data?.submission_data?.referral,
    },
    keepConnected: {
      label:
        "Would you like to receive emails from ImpactX to keep you in the loop on future events and other updates?",
      type: "radio",
      options: ["Yes", "No"],
      required: true,
      error: "Please make a selection",
      default: submission?.data?.submission_data?.keepConnected,
    },
  };

  const monashFormDataInit = {
    aboutVenture: {
      title: "About Your Venture",
      type: "header",
    },
    teamName: {
      label: "Startup or Team Name",
      type: "text",
      required: true,
      value: savedFormData?.data?.submission_data?.teamName,
      errorMessage: "Please enter your team name",
      hint: "",
    },
    companyWebsite: {
      label: "Company Website (if applicable)",
      type: "url",
      required: false,
      value: savedFormData?.data?.submission_data?.companyWebsite,
    },
    idea: {
      label: "Describe your venture in 2-3 sentences (e.g. elevator pitch)",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.idea,
      errorMessage: "Please describe your elevator pitch",
      // maxlength: 24,
    },
    video: {
      label:
        "Please enter the url of a ONE MINUTE unlisted (not private) YouTube video introducing yourself and your cofounders",
      type: "url",
      required: true,
      value: savedFormData?.data?.submission_data?.video,
      errorMessage: "Please enter you intro video",
    },
    problem: {
      label: "What problem are you solving? (In 50 words or less)",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.problem,
      errorMessage: "Please describe the problem you are solving",
    },
    solution: {
      label: "Describe your solution (in 50 words or less)",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.solution,
      errorMessage: "Please describe the solution",
    },
    origin: {
      label: "How did you come about this idea?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.origin,
    },
    interviews: {
      label:
        "How many one-on-one customer interviews have you conducted so far?",
      type: "number",
      required: true,
      value: savedFormData?.data?.submission_data?.interviews,
      errorMessage: "Please provide a number",
    },
    currentSolution: {
      label:
        "What substitutes do your customers resort to, i.e. without your solution?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.currentSolution,
      errorMessage:
        "Please describe the common way this problem is solved currently",
    },
    unique: {
      label:
        "How is your solution unique/innovative/better than other solutions?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.unique,
      errorMessage: "Please describe how your solution is better",
    },
    businessModel: {
      label: "What is your business model?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.businessModel,
      errorMessage: "Please enter your business model",
    },
    traction: {
      hint: "For example: Yes - we launched a landing page 2 weeks ago explaining what we are going to do and are collecting email addresses to form a mailing list",
      label: "Describe the progress/traction you have achieved to date",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.traction,
      errorMessage: "Please describe your traction",
    },
    rightTeam: {
      label: "Why are you the right team to solve this problem?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.rightTeam,
      errorMessage:
        "Please describe why you are the right team to solve this problem",
    },
    whyApply: {
      label:
        "What motivates you? What do you care about? Why do you care about this problem?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.whyApply,
      errorMessage: "Please describe your motivation to apply",
    },
    whatGain: {
      label: "What do you hope to gain by participating in the Accelerator?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.whatGain,
      errorMessage: "Please describe your you want to participate",
    },
    hear: {
      label: "How did you hear about the Startup Sprint? ",
      type: "select",
      options: [
        { value: "Generator website", label: "Generator website" },
        { value: "Generator mailing list", label: "Generator mailing list" },
        { value: "Generator Facebook page", label: "Generator Facebook page" },
        {
          value: "Generator Instagram Page",
          label: "Generator Instagram Page",
        },
        { value: "Generator Twitter Page", label: "Generator Twitter Page" },
        {
          value: "University Communications",
          label: "University Communications",
        },
        { value: "A Monash student", label: "A Monash Student" },
        { value: "A Monash staff member", label: "A Monash staff member" },
        { value: "A Monash alumni", label: "A Monash alumni" },
        {
          value: "I've previously engaged with the Generator",
          label: "I've previously engaged with the Generator",
        },
        { value: "Other", label: "Other" },
      ],
      required: true,
      default: savedFormData?.data?.submission_data?.hear,
    },
    sector: {
      label: "What industry/market does your project sit within?",
      type: "select",
      options: [
        { value: "Aerospace", label: "Aerospace" },
        { value: "Agriculture", label: "Agriculture" },
        { value: "AI", label: "AI" },
        { value: "Analytics", label: "Analytics" },
        { value: "Biotech", label: "Biotech" },
        { value: "Blockchain/crypto", label: "Blockchain/crypto" },
        { value: "E-Commerce", label: "E-Commerce" },
        { value: "Education/EdTech", label: "Education/EdTech" },
        { value: "Energy", label: "Energy" },
        {
          value: "Environment/Sustainabilty",
          label: "Environment/Sustainabilty",
        },
        { value: "Fintech", label: "Fintech" },
        { value: "Food/Beverage", label: "Food/Beverage" },
        { value: "Gaming", label: "Gaming" },
        { value: "Hardware", label: "Hardware" },
        { value: "Health/Wellbeing", label: "Health/Wellbeing" },
        { value: "MedTech", label: "MedTech" },
        { value: "Mental Health", label: "Mental Health" },
        { value: "Retail", label: "Retail" },
        { value: "Robotics", label: "Robotics" },
        { value: "SaaS", label: "SaaS" },
        { value: "Small Business", label: "Small Business" },
        { value: "Social Entreprenuership", label: "Social Entreprenuership" },
        { value: "Student Life", label: "Student Life" },
        { value: "Other", label: "Other" },
      ],
      required: true,
      default: savedFormData?.data?.submission_data?.sector,
      errorMessage: "Please make a selection for the industry",
    },
    stage: {
      label: "What stage best describes your startup?",
      type: "select",
      options: [
        { value: "Research & Ideation", label: "Research & Ideation" },
        {
          value: "Prototype/Minimum Viable Product",
          label: "Prototype/Minimum Viable Product",
        },
        { value: "Taking pre orders", label: "Taking pre orders" },
        {
          value: "Launched, but no users yet",
          label: "Launched, but no users yet",
        },
        { value: "Launched with users", label: "Launched with users" },
        {
          value: "Launched with customers (<$10k)",
          label: "Launched with customers (<$10k)",
        },
        {
          value: "$10k-$100k revenue for the last 12 months",
          label: "$10k-$100k revenue for the last 12 months",
        },
        {
          value: ">$100k revenue in last 12 months",
          label: ">$100k revenue in last 12 months",
        },
      ],
      required: true,
      default: savedFormData?.data?.submission_data?.stage,
    },
    currentlyGeneratingRevenue: {
      label: "Are you currently generating revenue?",
      type: "radio",
      options: ["Yes", "No"],
      default: savedFormData?.data?.submission_data?.currentlyGeneratingRevenue,
      required: true,
      errorMessage: "Please make a selection",
    },
    previousInvolvement: {
      label:
        "What has your previous involvement with the Monash Generator been?",
      type: "checkbox",
      options: [
        "Events",
        "The Validator",
        "Startup Sprint",
        "Researcher to Innovator",
        "The Accelerator",
        "1:1 Office hours",
        "Other",
      ],
      value: savedFormData?.data?.submission_data?.previousInvolvement,
      default: savedFormData?.data?.submission_data?.previousInvolvement,
      required: true,
    },
    participation: {
      label:
        "Have you ever participated in an accelerator program? If so, which.",
      type: "text",
      value: savedFormData?.data?.submission_data?.participation,
      required: true,
      errorMessage:
        "Please let us know if you have ever particiapted in an accerlator program. NA otherwise",
    },
    // howLong: {
    //   label: "How long have you been working on this project?",
    //   type: "textarea",
    //   required: true,
    //   value: savedFormData?.data?.submission_data?.howLong,
    //   errorMessage:
    //     "Please share how long you hace been working on this project",
    // },
    // users: {
    //   hint: "If you have launched a mailing list, let us know how many people are currently on this.",
    //   label: "Do you currently have any users or customers? If so, how many?",
    //   type: "textarea",
    //   required: true,
    //   value: savedFormData?.data?.submission_data?.users,
    //   errorMessage: "Please share how many users",
    // },
    // whyApply: {
    //   hint: "In 250 words or less, let us know why you want to take part in the Startup Sprint, what type of help you are hoping to receive and what you hope to achieve by the end of the five weeks.",
    //   label:
    //     "Why are you applying to the Generator's Startup Sprint program? What do you hope to get out of the program?",
    //   type: "textarea",
    //   required: true,
    //   value: savedFormData?.data?.submission_data?.whyApply,
    //   errorMessage: "Please describe your motivation to apply",
    // },
    // areasOfHelp: {
    //   label: "What area(s) do you need the most help in right now?",
    //   type: "checkbox",
    //   options: [
    //     "Marketing",
    //     "Investment",
    //     "Company Structuring",
    //     "Product Development",
    //     "Coaching/Guidance",
    //     "Growth/Scaling",
    //     "Other",
    //   ],
    //   value: savedFormData?.data?.submission_data?.areasOfHelp,
    //   default: savedFormData?.data?.submission_data?.areasOfHelp,
    //   required: true,
    // },
    // Contact Details
    sectionHeader: {
      title: "About You",
      type: "header",
    },
    noOfTeam: {
      label:
        "How many people are currently working on this startup/project (including yourself)?",
      type: "select",
      options: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5+" },
      ],
      required: true,
      default: savedFormData?.data?.submission_data?.noOfTeam,
      errorMessage: "Please select how many people on your team",
      // maxlength: 4,
    },
    teamLeadFirstName: {
      label: "Team Lead First Name",
      type: "text",
      required: true,
      value: savedFormData?.data?.submission_data?.teamLeadFirstName,
      errorMessage: "Please enter the team lead's first name",
    },
    teamLeadLastName: {
      label: "Team Lead Last Name",
      type: "text",
      required: true,
      value: savedFormData?.data?.submission_data?.teamLeadLastName,
      errorMessage: "Please enter the team lead's last name",
    },
    teamLeadEmailAddress: {
      label: "Email Address",
      type: "email",
      required: true,
      value: savedFormData?.data?.submission_data?.teamLeadEmailAddress,
      errorMessage: "Please enter the team lead's email address",
    },
    teamLeadMobile: {
      label: "Team Lead's Mobile Number",
      type: "text",
      required: true,
      value: savedFormData?.data?.submission_data?.teamLeadMobile,
      errorMessage: "Please enter the team lead's mobile number",
    },
    teamLeadGender: {
      label: "Team Lead's Gender",
      type: "radio",
      default: savedFormData?.data?.submission_data?.teamLeadGender,
      errorMessage: "Please select the Team Lead's gender",
      options: [
        "Female",
        "Male",
        "Non-binary/gender diverse",
        "Prefer not to answer",
      ],
    },
    teamLeadAboriginal: {
      label:
        "Team Lead - Are you of Aboriginal or Torres Strait Islander origin?",
      type: "radio",
      options: ["Yes", "No", "Prefer not to say"],
      default: savedFormData?.data?.submission_data?.teamLeadAboriginal,
      required: true,
      errorMessage: "Please make a selection",
    },
    teamLeadLocation: {
      label: "Where are you and your team currently located? (City/Country)",
      required: true,
      type: "text",
      value: savedFormData?.data?.submission_data?.teamLeadLocation,
      errorMessage: "Please describe your motivation to apply",
    },
    teamLeadMailingList: {
      label:
        "Generator mailing list. I would like to be added to the Generator's email mailing list and agree to receive emails from the Generator about their programs and events (you can opt-out at any time).",
      type: "radio",
      options: ["Yes please", "No thanks", "I'm already on the list"],
      default: savedFormData?.data?.submission_data?.teamLeadMailingList,
      required: true,
      errorMessage: "Please make a selection",
    },
    teamLeadAffiliation: {
      label: "Team lead - Affiliation to Monash University ?",
      type: "radio",
      options: ["Student", "Staff", "Alumni", "No Affiliation"],
      default: savedFormData?.data?.submission_data?.teamLeadAffiliation,
      required: true,
      errorMessage: "Please make a selection",
    },
    teamLeadStudentStatus: {
      label: "Team Lead - Student status",
      type: "radio",
      options: ["Domestic", "International"],
      default: savedFormData?.data?.submission_data?.teamLeadStudentStatus,
      required: teamLeadAffiliation === "Student",
      show: teamLeadAffiliation === "Student",
    },
    teamLeadMonashId: {
      label: "Team Lead - Monash ID",
      type: "text",
      value: savedFormData?.data?.submission_data?.teamLeadMonashId,
      required: teamLeadAffiliation === "Student",
      show: teamLeadAffiliation === "Student",
    },
    teamLeadDegreeUndertaken: {
      label: "Team lead - Degree undertaking/undertaken",
      type: "radio",
      options: [
        "Undergraduate",
        "Honours",
        "PG - Masters By Research",
        "PG - Masters by Coursework",
        "PhD",
      ],
      default: savedFormData?.data?.submission_data?.teamLeadDegreeUndertaken,
      required:
        teamLeadAffiliation === "Student" || teamLeadAffiliation === "Alumni",
      show:
        teamLeadAffiliation === "Student" || teamLeadAffiliation === "Alumni",
    },
    teamLeadFacultyOrPortfolio: {
      type: "select",
      label: "Team lead - Faculty/Portfolio affiliation",
      show:
        teamLeadAffiliation === "Student" ||
        teamLeadAffiliation === "Staff" ||
        teamLeadAffiliation === "Alumni",
      required:
        teamLeadAffiliation === "Student" ||
        teamLeadAffiliation === "Staff" ||
        teamLeadAffiliation === "Alumni",
      default: savedFormData?.data?.submission_data?.teamLeadFacultyOrPortfolio,
      options: [
        {
          value: "Art, Design & Architecture (MADA)",
          label: "Art, Design & Architecture (MADA)",
        },
        { value: "Arts", label: "Arts" },
        {
          value: "Business, Economics and Commerce",
          label: "Business, Economics and Commerce",
        },
        { value: "Education", label: "Education" },
        { value: "Engineering", label: "Engineering" },
        { value: "Information Technology", label: "Information Technology" },
        { value: "Law", label: "Law" },
        {
          value: "Medicine, Nursing & Health Science",
          label: "Medicine, Nursing & Health Science",
        },
        {
          value: "Pharmacy & Pharmaceutical Sciences",
          label: "Pharmacy & Pharmaceutical Sciences",
        },
        { value: "Science", label: "Science" },
        {
          value: "Office of the Vice-Chancellor",
          label: "Office of the Vice-Chancellor",
        },
        {
          value: "Office of the Provost and Senior Vice-President",
          label: "Office of the Provost and Senior Vice-President",
        },
        {
          value:
            "Office of the Deputy Vice-Chancellor and Vice-President (Education)",
          label:
            "Office of the Deputy Vice-Chancellor and Vice-President (Education)",
        },
        {
          value:
            "Office of the Deputy Vice-Chancellor and Vice-President (Enterprise and Governance)",
          label:
            "Office of the Deputy Vice-Chancellor and Vice-President (Enterprise and Governance)",
        },
        {
          value:
            "Office of the Deputy Vice-Chancellor and Vice-President (Global Engagement)",
          label:
            "Office of the Deputy Vice-Chancellor and Vice-President (Global Engagement)",
        },
        {
          value:
            "Office of the Chief Operating Officer and Senior Vice-President",
          label:
            "Office of the Chief Operating Officer and Senior Vice-President",
        },
        {
          value:
            "Office of the Chief Financial Officer and Senior Vice-President",
          label:
            "Office of the Chief Financial Officer and Senior Vice-President",
        },
        { value: "Other", label: "Other" },
      ],
    },
    teamLeadPrimaryCampus: {
      type: "select",
      label: "Team lead - Primary campus",
      show:
        teamLeadAffiliation === "Student" || teamLeadAffiliation === "Staff",
      required:
        teamLeadAffiliation === "Student" || teamLeadAffiliation === "Staff",
      default: savedFormData?.data?.submission_data?.teamLeadPrimaryCampus,
      options: [
        { label: "Clayton", value: "Clayton" },
        { label: "Caulfield", value: "Caulfield" },
        { label: "Peninsula", value: "Peninsula" },
        { label: "City", value: "City" },
        { label: "Monash Malaysia", value: "Monash Malaysia" },
        { label: "Other", value: "Other" },
      ],
    },
    teamLeadGraduationYear: {
      type: "text",
      label: "Team lead - Graduation year",
      show: teamLeadAffiliation === "Alumni",
      required: teamLeadAffiliation === "Alumni",
      value: savedFormData?.data?.submission_data?.teamLeadGraduationYear,
    },
    teamLeadStaffEmployment: {
      label: "Team Lead - Staff employment",
      type: "radio",
      show: teamLeadAffiliation === "Staff",
      required: teamLeadAffiliation === "Staff",
      default: savedFormData?.data?.submission_data?.teamLeadStaffEmployment,
      options: ["Professional staff", "Academic staff", "PostDoc"],
    },
  };

  let monashFormData = monashFormDataInit;

  const [noOfTeamMembers, setNoOfTeamMembers] = useState(0);

  const addTeamMembers = (noOfT) => {
    let newFormData = formData;
    newFormData.teamMemmberInfoHeader = {
      title: "Team Member Information",
      type: "header",
    };

    const aff = {
      2: teamMemberAffiliation2,
      3: teamMemberAffiliation3,
      4: teamMemberAffiliation4,
      5: teamMemberAffiliation5,
    };

    for (let i = 2; i <= noOfT; i++) {
      newFormData[`teamMemberFirstName${i}`] = {
        label: `Team Member ${i} First Name`,
        type: "text",
        required: true,
        value:
          savedFormData?.data?.submission_data?.[`teamMemberFirstName${i}`],
        errorMessage: "Please enter the team Member's first name",
      };
      newFormData[`teamMemberLastName${i}`] = {
        label: `Team Member ${i} Last Name`,
        type: "text",
        required: true,
        value: savedFormData?.data?.submission_data?.[`teamMemberLastName${i}`],
        errorMessage: "Please enter the team Member's last name",
      };
      newFormData[`teamMemberEmailAddress${i}`] = {
        label: `Team Member ${i} Email Address`,
        type: "email",
        required: true,
        value:
          savedFormData?.data?.submission_data?.[`teamMemberEmailAddress${i}`],
        errorMessage: "Please enter the team Member's email address",
      };
      newFormData[`teamMemberMobile${i}`] = {
        label: `Team Member ${i} Mobile Number`,
        type: "text",
        required: true,
        value: savedFormData?.data?.submission_data?.[`teamMemberMobile${i}`],
        errorMessage: "Please enter the team Member's mobile number",
      };
      newFormData[`teamMemberGender${i}`] = {
        label: `Team Member ${i} Gender`,
        type: "radio",
        required: true,
        default: savedFormData?.data?.submission_data?.[`teamMemberGender${i}`],
        errorMessage: "Please select the Team Member's gender",
        options: [
          "Female",
          "Male",
          "Non-binary/gender diverse",
          "Prefer not to answer",
        ],
      };
      newFormData[`teamMemberAboriginal${i}`] = {
        label: `Team Member ${i} - Are you of Aboriginal or Torres Strait Islander origin?`,
        type: "radio",
        options: ["Yes", "No", "Prefer not to say"],
        default:
          savedFormData?.data?.submission_data?.[`teamMemberAboriginal${i}`],
        required: true,
        errorMessage: "Please make a selection",
      };
      newFormData[`teamMemberLocation${i}`] = {
        label: "Where are you and your team currently located? (City/Country)",
        required: true,
        type: "text",
        value: savedFormData?.data?.submission_data?.[`teamMemberLocation${i}`],
        errorMessage: "Please enter your location here",
      };
      newFormData[`teamMemberMailingList${i}`] = {
        label:
          "Generator mailing list. I would like to be added to the Generator's email mailing list and agree to receive emails from the Generator about their programs and events (you can opt-out at any time).",
        type: "radio",
        options: ["Yes please", "No thanks", "I'm already on the list"],
        default:
          savedFormData?.data?.submission_data?.[`teamMemberMailingList${i}`],
        required: true,
        errorMessage: "Please make a selection",
      };
      newFormData[`teamMemberAffiliation${i}`] = {
        label: `Team Member ${i} - Affiliation to Monash University ?`,
        type: "radio",
        options: ["Student", "Staff", "Alumni", "No Affiliation"],
        default:
          savedFormData?.data?.submission_data?.[`teamMemberAffiliation${i}`],
        required: true,
        errorMessage: "Please make a selection",
      };
      console.log("aff", aff);
      console.log("is aff student", aff[i]);
      newFormData[`teamMemberStudentStatus${i}`] = {
        label: `Team Member ${i} - Student status`,
        type: "radio",
        options: ["Domestic", "International"],
        default:
          savedFormData?.data?.submission_data?.[`teamMemberStudentStatus${i}`],
        required: aff[i] === "Student",
        show: aff[i] === "Student",
      };
      newFormData[`teamMemberFacultyOrPortfolio${i}`] = {
        type: "select",
        label: `Team Member ${i} - Faculty/Portfolio affiliation`,
        show: aff[i] === "Student" || aff[i] === "Staff" || aff[i] === "Alumni",
        required:
          aff[i] === "Student" || aff[i] === "Staff" || aff[i] === "Alumni",
        default:
          savedFormData?.data?.submission_data?.[
            `teamMemberFacultyOrPortfolio${i}`
          ],
        options: [
          {
            value: "Art, Design & Architecture (MADA)",
            label: "Art, Design & Architecture (MADA)",
          },
          { value: "Arts", label: "Arts" },
          {
            value: "Business, Economics and Commerce",
            label: "Business, Economics and Commerce",
          },
          { value: "Education", label: "Education" },
          { value: "Engineering", label: "Engineering" },
          { value: "Information Technology", label: "Information Technology" },
          { value: "Law", label: "Law" },
          {
            value: "Medicine, Nursing & Health Science",
            label: "Medicine, Nursing & Health Science",
          },
          {
            value: "Pharmacy & Pharmaceutical Sciences",
            label: "Pharmacy & Pharmaceutical Sciences",
          },
          { value: "Science", label: "Science" },
          {
            value: "Office of the Vice-Chancellor",
            label: "Office of the Vice-Chancellor",
          },
          {
            value: "Office of the Provost and Senior Vice-President",
            label: "Office of the Provost and Senior Vice-President",
          },
          {
            value:
              "Office of the Deputy Vice-Chancellor and Vice-President (Education)",
            label:
              "Office of the Deputy Vice-Chancellor and Vice-President (Education)",
          },
          {
            value:
              "Office of the Deputy Vice-Chancellor and Vice-President (Enterprise and Governance)",
            label:
              "Office of the Deputy Vice-Chancellor and Vice-President (Enterprise and Governance)",
          },
          {
            value:
              "Office of the Deputy Vice-Chancellor and Vice-President (Global Engagement)",
            label:
              "Office of the Deputy Vice-Chancellor and Vice-President (Global Engagement)",
          },
          {
            value:
              "Office of the Chief Operating Officer and Senior Vice-President",
            label:
              "Office of the Chief Operating Officer and Senior Vice-President",
          },
          {
            value:
              "Office of the Chief Financial Officer and Senior Vice-President",
            label:
              "Office of the Chief Financial Officer and Senior Vice-President",
          },
          { value: "Other", label: "Other" },
        ],
      };
      newFormData[`teamMemberMonashId${i}`] = {
        label: `Team Member ${i} - Monash ID`,
        type: "text",
        value: savedFormData?.data?.submission_data?.[`teamMemberMonashId${i}`],
        required: aff[i] === "Student",
        show: aff[i] === "Student",
      };
      newFormData[`teamMemberDegreeUndertaken${i}`] = {
        label: `Team Member ${i} - Degree undertaking/undertaken`,
        type: "radio",
        options: [
          "Undergraduate",
          "Honours",
          "PG - Masters By Research",
          "PG - Masters by Coursework",
          "PhD",
        ],
        default:
          savedFormData?.data?.submission_data?.[
            `teamMemberDegreeUndertaken${i}`
          ],
        required: aff[i] === "Student" || aff[i] === "Alumni",
        show: aff[i] === "Student" || aff[i] === "Alumni",
      };
      newFormData[`teamMemberPrimaryCampus${i}`] = {
        type: "select",
        label: `Team Member ${i} - Primary campus`,
        show: aff[i] === "Student" || aff[i] === "Staff",
        required: aff[i] === "Student" || aff[i] === "Staff",
        default:
          savedFormData?.data?.submission_data?.[`teamMemberPrimaryCampus${i}`],
        options: [
          { label: "Clayton", value: "Clayton" },
          { label: "Caulfield", value: "Caulfield" },
          { label: "Peninsula", value: "Peninsula" },
          { label: "City", value: "City" },
          { label: "Monash Malaysia", value: "Monash Malaysia" },
          { label: "Other", value: "Other" },
        ],
      };
      newFormData[`teamMemberGraduationYear${i}`] = {
        type: "text",
        label: `Team Member ${i} - Graduation year`,
        show: aff[i] === "Alumni",
        required: aff[i] === "Alumni",
        value:
          savedFormData?.data?.submission_data?.[
            `teamMemberGraduationYear${i}`
          ],
      };
      newFormData[`teamMemberStaffEmployment${i}`] = {
        label: `Team Member ${i} - Staff employment`,
        type: "radio",
        show: aff[i] === "Staff",
        required: aff[i] === "Staff",
        default:
          savedFormData?.data?.submission_data?.[
            `teamMemberStaffEmployment${i}`
          ],
        options: ["Professional staff", "Academic staff", "PostDoc"],
      };
    }
    console.log("new form data", newFormData);
    monashFormData = newFormData;
  };

  let formData =
    user?.data?.account_name === "monash" ? monashFormData : impactFormData;

  useEffect(() => {
    if (noOfTeamMembers > 1) {
      console.log("in setting effect", noOfTeamMembers);
      addTeamMembers(noOfTeamMembers);
    }
  }, [
    noOfTeamMembers,
    addTeamMembers,
    teamMemberAffiliation2,
    teamMemberAffiliation3,
    teamMemberAffiliation4,
    teamMemberAffiliation5,
  ]);

  useEffect(() => {
    const noOfTeam = submission?.data?.submission_data?.noOfTeam;
    console.log("in load hook", noOfTeam);
    if (noOfTeam > 1) {
      addTeamMembers(noOfTeam);
    }
  }, [submission, formData]);

  useEffect(() => {
    console.log("in loading hook - only set affs");
    setTeamMemberAffiliation2(
      submission?.data?.submission_data?.teamMemberAffiliation2
    );
    setTeamMemberAffiliation3(
      submission?.data?.submission_data?.teamMemberAffiliation3
    );
    setTeamMemberAffiliation4(
      submission?.data?.submission_data?.teamMemberAffiliation4
    );
    setTeamMemberAffiliation5(
      submission?.data?.submission_data?.teamMemberAffiliation5
    );
    setTeamLeadAffiliation(
      submission?.data?.submission_data?.teamLeadAffiliation
    );
    setFormValues(savedFormData);
  }, [submission]);

  if (submission?.loading || submission?.data === null) {
    console.log("loading");
    return <Loader />;
  }

  if (user.loading) return <Loader />;
  console.log("user", user);
  console.log("submission", submission);
  const fields = Object.keys(submission?.data?.submission_data);

  return (
    <Fragment>
      <Message
        title="Submission"
        type="info"
        text="This is the current state of the submission."
      />
      <Fragment>
        <Card>
          <Form
            data={formData}
            method="POST"
            url={`/api/submission/${props.computedMatch.params.id}`}
            updateOnChange
            onChange={(e) => {
              console.log("on change", e);
              if (e.valid === true || e.valid === 1) {
                axios.patch(
                  `/api/submission_data/${props.computedMatch.params.id}`,
                  e
                );
                const newFormValues = formValues;
                newFormValues.data.submission_data[`${e.input}`] = e.value;
                console.log("setting form value to", newFormValues);
                setFormValues(newFormValues);
                // setFormValues()
                if (e.input === "noOfTeam") {
                  setNoOfTeamMembers(e.value);
                }
                console.log("prop", props);
                if (e.input === "teamLeadAffiliation") {
                  setTeamLeadAffiliation(e.value);
                }

                if (e.input === "teamMemberAffiliation2") {
                  console.log("changing team member aff to ", e.value);
                  setTeamMemberAffiliation2(e.value);
                }

                if (e.input === "teamMemberAffiliation3") {
                  setTeamMemberAffiliation3(e.value);
                }

                if (e.input === "teamMemberAffiliation4") {
                  setTeamMemberAffiliation4(e.value);
                }

                if (e.input === "teamMemberAffiliation5") {
                  setTeamMemberAffiliation5(e.value);
                }
              }
            }}
            buttonText="Submit"
            redirect="/thanks"
          />
        </Card>
      </Fragment>
    </Fragment>
  );
}
