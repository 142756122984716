import React, { Fragment, useContext } from "react";
import {
  Loader,
  useAPI,
  Grid,
  Button,
  Row,
  ViewContext,
  Table,
  Form,
  Card,
  History,
} from "components/lib";

export function FormComponent(props) {
  const formData = useAPI("/api/form");
  const viewContext = useContext(ViewContext);

  const handleCreate = () => {
    console.log("handle create");
    viewContext.modal.show(
      {
        title: "Form",
        form: {
          name: {
            label: "Form name",
            type: "text",
            required: true,
            description: "description",
            errorMessage: "Please enter your form name",
          },
        },
        buttonText: "Create",
        url: "/api/form",
        method: "POST",
      },
      (res) => {
        History.push("/form");
      }
    );

    // viewContext.notification.show('msg', 'error');
  };
  // const data = await Axios({
  //   url: '/api/form',
  //   method: 'post',
  // });
  // console.log(data);
  // };

  function editForm(data, callback) {
    History.push("/fb/" + data.id);
  }

  if (formData.loading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Grid>
        <Row>
          {/* <Button
            text="Create"
            small={true}
            fullWidth={false}
            className="float-right"
            action={handleCreate}
          ></Button> */}
          <h1>Forms</h1>
          <br />
          <Card restrictWidth>
            <Form
              data={{
                name: {
                  label: "Form name",
                  type: "text",
                  required: true,
                  description: "description",
                  errorMessage: "Please enter your form name",
                },
              }}
              url="/api/form"
              buttonText="Create"
              method="post"
              callback={(res) => {
                console.log("message in callback", res);
                History.push(`/fb/${res.data.data.id}`);
              }}
            />
          </Card>

          <br />
        </Row>
        <Row>
          <h1>Existing Forms</h1>
          <br />
          <Table
            data={formData?.data}
            show={["id", "name", "created_at", "updated_at"]}
            search
            actions={{
              edit: editForm,
            }}
          ></Table>
        </Row>
      </Grid>
    </Fragment>
  );
}
