/***
 *
 *   INTEGRATIONS
 *   Integrations management page for managing connections
 *
 **********/

import React, { useContext } from "react";
import {
  AuthContext,
  Animate,
  Grid,
  Card,
  Icon,
  Link,
  useAPI,
  Loader,
} from "components/lib";
// import { CSVLink } from "react-csv";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Integration(props) {
  const context = useContext(AuthContext);
  const user = useAPI("/api/user");
  // const notionAuthLink = useAPI("/api/account/integration/notion/auth");
  // const submissionDataList = useAPI("/api/submission");
  const hubspotAuthLink = useAPI("/api/integration/hubspot/install");
  const notionAuthLink = useAPI("/api/integration/notion/install");

  if (user.loading) return <Loader />;

  const date = new Date();

  return (
    <Animate>
      <Grid cols="4">
        {context.permission?.admin && (
          <Card>
            {/* <FontAwesomeIcon
              icon={[
                { iconName: "brands", prefix: "fa" },
                { iconName: "hubspot", prefix: "fa" },
              ]}
            /> */}
            {/* <Icon pack="fontawesome" icon={["fab", "facebook"]} size="lg" /> */}
            <h2>HubSpot</h2>
            <div>Connect your HubSpot account</div>
            <Link
              url={hubspotAuthLink?.data?.url || ""}
              text="Connect"
              btn={false}
            />
          </Card>
        )}
        {context.permission?.admin && (
          <Card>
            <Icon image="edit-3" />
            <h2>Notion</h2>
            <div>Connect to your notion instance</div>
            <Link
              url={notionAuthLink?.data?.url || ""}
              text="Connect"
              btn={false}
            />
          </Card>
        )}

        {context.permission?.admin && (
          <Card>
            <Icon image="lock" />
            <h2>Airtable</h2>
            <div>Connect to your notion instance (TBD)</div>
            <Link url="/account/profile">Connect</Link>
          </Card>
        )}
        {/* {!submissionDataList?.loading && submissionDataList?.data ? (
          <Card>
            <Icon image="download" />
            <h2>CSV</h2>
            <div>Export as CSV</div>
            <CSVLink
              data={submissionDataList.data.map((sub) => {
                const subInfo = sub.submission_data;
                subInfo.contactEmail = sub.name;
                subInfo.status = sub.status;
                subInfo.created_at = sub.created_at;
                subInfo.updated_at = sub.updated_at;
                return subInfo;
              })}
              onClick={() => {
                console.log("You click the download link");
              }}
              filename={`${date.getDate()}-${
                date.getMonth() + 1
              }-${date.getFullYear()}-Submissions.csv`}
            >
              Download
            </CSVLink>
          </Card>
        ) : (
          <Card>
            <h2>Loading</h2>
          </Card>
        )} */}
      </Grid>
    </Animate>
  );
}
