import React, { useEffect, useState } from "react";
import {
  Loader,
  useAPI,
  Grid,
  Article,
  Row,
  Form,
  ViewContext,
} from "components/lib";
import Axios from "axios";

export function NotionExport(props) {
  const campaignId = props.computedMatch.params.campaignId;
  const notionPages = useAPI("/api/integration/notion/pages");
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});

  async function addOptions(campaignId) {
    if (!notionPages.data) {
      return;
    }
    console.log("in options");
    const campaignInfo = await Axios.get("/api/campaign/" + campaignId);
    console.log("campaign info", campaignInfo);
    const formId = campaignInfo.data.data[0].form_id;
    const formInfo = await Axios.get("/api/form/" + formId);
    const pages = formInfo.data.data.fieldData.pages;
    const pagedElements = pages.map((p) => p.elements);
    const elements = pagedElements.flat();
    const fields = elements.map((e) => e.title || e.name);
    console.log("form info pages", fields);
    console.log("id", campaignId);
    setFormData({
      sectionHeader: {
        title: "Configure your export",
        type: "header",
      },
      fields: {
        type: "checkbox",
        label:
          "Select which fields should be added to the main table as columns?",
        max: 5,
        options: fields,
      },
      campaignId: {
        type: "hidden",
        label: "campaign id",
        value: campaignId,
      },
      pageId: {
        type: "radio",
        label: "Select the notion page",
        options: notionPages?.data.map((page) => ({
          value: page.id,
          label: page?.properties?.title?.title[0]?.plain_text,
        })),
      },
    });
    setLoading(false);
  }

  useEffect(() => {
    addOptions(campaignId);
  }, [notionPages]);

  return (
    <>
      {!loading && (
        <>
          {console.log("in her", formData)}
          <Form
            data={formData}
            method="POST"
            buttonText="Select"
            url={`/api/integration/notion/export`}
          />
        </>
      )}
    </>
  );
}
