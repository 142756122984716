import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  AuthContext,
  Animate,
  Grid,
  Card,
  Icon,
  Table,
  Link,
  FormSelect,
  useAPI,
  Loader,
} from "components/lib";
import { CSVLink } from "react-csv";
import Axios from "axios";
import { parse } from "json2csv";

export function CSV(props) {
  const context = useContext(AuthContext);
  const user = useAPI("/api/user");
  const campaigns = useAPI("/api/campaign");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [submissionDataList, setSubmissionDataList] = useState([]);
  const [loadingHeaders, setLoadingHeaders] = useState(false);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    async function getHeaders() {
      setLoadingHeaders(true);
      const campaign = campaigns.data.filter(
        (c) => c.id === selectedCampaign
      )[0];
      const formInfo = await Axios.get(`/api/form/${campaign.form_id}`);
      const fieldData = formInfo.data.data.fieldData;
      const elements = fieldData.pages.map((page) => page.elements);
      console.log("elements", elements);
      const flatElements = elements.flat();
      let headers = flatElements.map((element) => {
        return { label: element.title || element.name, key: element.name };
      });
      headers.push({ label: "Email", key: "email" });
      headers.push({ label: "Created At", key: "created_at" });
      headers.push({ label: "Updated At", key: "updated_at" });
      headers.push({ label: "Status", key: "status" });
      console.log("headers", headers);
      //   const headers = fieldData.map((field) => {label: field.label);
      Axios.get(`/api/campaign/${selectedCampaign}/submissions`, {
        headers: {
          Authorization: `Bearer ${context.user.token}`,
        },
      })
        .then((response) => {
          const campaignSubData = response.data.data.map((sub) => {
            const subInfo = sub.submission_data;
            subInfo.contactEmail = sub.name;
            subInfo.status = sub.status;
            subInfo.created_at = sub.created_at;
            subInfo.updated_at = sub.updated_at;
            return subInfo;
          });
          return campaignSubData;
        })
        .then((campaignSubData) => {
          //   const data = parse(campaignSubData);
          //   setSubmissionDataList(data);
          //   console.log("data", data);
          setSubmissionDataList(campaignSubData);
          console.log("submissionDataList", submissionDataList);
        });
      setHeaders(headers);
      setLoadingHeaders(false);
    }
    if (selectedCampaign.length > 0) {
      getHeaders();
    }
  }, [selectedCampaign]);

  let campaignOptions;
  if (campaigns?.data?.length > 0) {
    campaignOptions = campaigns?.data?.map((campaign) => {
      return { label: campaign.name, value: campaign.id };
    });
  }

  const handleChange = (e) => {
    setSelectedCampaign(e.target.value);
  };

  if (user.loading && campaigns.loading) return <Loader />;

  const download = (event, done) => {
    console.log("download");
    done(true);
  };

  return (
    <Animate>
      <Card>
        <h2>Pick a campaign to download</h2>
        <FormSelect options={campaignOptions} onChange={handleChange} />
        {loadingHeaders ? (
          "loading.."
        ) : (
          <CSVLink
            data={submissionDataList}
            asyncOnClick={true}
            onClick={download}
            headers={headers}
          >
            Download
          </CSVLink>
        )}
      </Card>
    </Animate>
  );
}
