import React from "react";
import { Article, List } from "components/lib";

export function FormBuilderDocs(props) {
  return (
    <Article>
      <h1>End-User Documentation for Form Builder</h1>
      <p>
        This document illustrates how to perform basic tasks in the Form Builder
        component. The component's UI is organized in multiple tabs. Each
        section in this document describes an individual tab.
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-tabs.png"
        alt="Form Builder - Tabs"
        width="100%"
      />

      <h2>Designer Tab</h2>
      <ul className="list">
        <li>
          <a href="#single-input">Single Input</a>
        </li>
        <li>
          <a href="#checkbox">Checkbox</a>
        </li>
        <li>
          <a href="#radiogroup">Radiogroup</a>
        </li>
        <li>
          <a href="#dropdown">Dropdown</a>
        </li>
        <li>
          <a href="#comment">Comment</a>
        </li>
        <li>
          <a href="#rating">Rating</a>
        </li>
        <li>
          <a href="#ranking">Ranking</a>
        </li>
        <li>
          <a href="#image-picker">Image Picker</a>
        </li>
        <li>
          <a href="#boolean">Boolean</a>
        </li>
        <li>
          <a href="#image">Image</a>
        </li>
        <li>
          <a href="#html">HTML</a>
        </li>
        <li>
          <a href="#signature-pad">Signature Pad</a>
        </li>
        <li>
          <a href="#expression">Expression</a>
        </li>
        <li>
          <a href="#file">File</a>
        </li>
        <li>
          <a href="#single-choice-matrix">Single-Choice Matrix</a>
        </li>
        <li>
          <a href="#multiple-choice-matrix">Multiple-Choice Matrix</a>
        </li>
        <li>
          <a href="#dynamic-matrix">Dynamic Matrix</a>
        </li>
        <li>
          <a href="#multiple-text">Multiple Text</a>
        </li>
        <li>
          <a href="#panel">Panel</a>
        </li>
        <li>
          <a href="#dynamic-panel">Dynamic Panel</a>
        </li>
        <li>
          <a href="#adorners">Adorners</a>
        </li>
        <li>
          <a href="#how-to-add-a-question-to-the-survey">
            How to: Add a question to the form
          </a>
        </li>
        <li>
          <a href="#how-to-add-a-page-to-the-survey">
            How to: Add a page to the form
          </a>
        </li>
        <li>
          <a href="#how-to-change-the-question-type">
            How to: Change the question type
          </a>
        </li>
        <li>
          <a href="#how-to-clear-the-survey">How to: Clear the form</a>
        </li>
      </ul>

      <h2>Preview Tab</h2>
      <ul className="list">
        <li>
          <a href="#how-to-re-run-the-preview">How to: Re-run the preview</a>
        </li>
        <li>
          <a href="#how-to-preview-the-survey-on-different-devices">
            How to: Preview the survey on different devices
          </a>
        </li>
      </ul>

      <h2>Logic Tab</h2>
      <ul className="list">
        <li>
          <a href="#how-to-add-a-new-rule">How to: Add a new rule</a>
        </li>
        <li>
          <a href="#how-to-edit-a-rule">How to: Edit a rule</a>
        </li>
        <li>
          <a href="#how-to-filter-rules">How to: Filter rules</a>
        </li>
      </ul>

      <h2>Designer Tab</h2>
      <p>
        The Designer tab allows you to configure your survey. You can drag and
        drop questions and panels from the Toolbox onto the design surface and
        then use the Property Grid to change the question, panel, and form
        settings.
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-tabs-designer.png"
        alt="Form Builder - Designer tab"
        width="100%"
      ></img>
      <br />
      <br />

      <h1>Question and Panel Types</h1>
      <p>The Toolbox contains the question and panel types described below.</p>
      <h2 id="single-input">Single Input</h2>
      <p>
        Respondents enter their answer into a single-line text editor. Use the
        Single Input type for open-ended questions that require short answers.
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/question-types-single-input.png"
        alt="Question types - Single input"
        width="50%"
      />
      <p>See also:</p>
      <ul>
        <li>
          <a href="#comment">Comment</a>
        </li>
        <li>
          <a href="#multiple-text">Multiple Text</a>
        </li>
      </ul>
      <h2 id="checkbox">Checkbox</h2>
      <p>
        Respondents click one or several checkboxes to select answers. Use the
        Checkbox type for questions that accept multiple answers.
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/question-types-checkbox.png"
        alt="Question types - Checkbox"
        width="50%"
      />
      <h2 id="radiogroup">Radiogroup</h2>
      <p>
        Respondents use radio buttons to select a single answer. Use the
        Radiogroup type for questions that can have multiple options but accept
        only one answer.
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/question-types-radiogroup.png"
        alt="Question types - Radiogroup"
        width="50%"
      />
      <p>
        See also: <a href="#dropdown">Dropdown</a>
      </p>
      <h2 id="dropdown">Dropdown</h2>
      <p>
        Respondents select a single answer from a drop-down list. Like{" "}
        <a href="#radiogroup">Radiogroup</a>, the Dropdown type can be used for
        questions that have multiple options but accept only one answer.
        However, the Dropdown UI can display more options while occupying less
        screen space.{" "}
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/question-types-dropdown.png"
        alt="Question types - Dropdown"
        width="50%"
      />
      <h2 id="comment">Comment</h2>
      <p>
        Respondents enter their lengthy answer into a resizable multi-line text
        area. Use the Comment type for open-ended questions that accept
        multi-line answers.
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/question-types-comment.png"
        alt="Question types - Comment"
        width="50%"
      />
      <p>See also:</p>
      <ul>
        <li>
          <a href="#single-input">Single Input</a>
        </li>
        <li>
          <a href="#multiple-text">Multiple Text</a>
        </li>
      </ul>
      <h2 id="rating">Rating</h2>
      <p>
        Respondents select one number within a range. Use the Rating type when
        you want respondents to enter a rating.
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/question-types-rating.png"
        alt="Question types - Rating"
        width="50%"
      />
      <h2 id="ranking">Ranking</h2>
      <p>
        Respondents drag and drop items from a list to rearrange them according
        to rank or preference. Use the Ranking type for questions in which
        respondents must set the order of items.
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/question-types-ranking.png"
        alt="Question types - Ranking"
        width="50%"
      />
      <h2 id="image-picker">Image Picker</h2>
      <p>
        Respondents select one or several images or videos from a series. A
        value associated with this image or video is saved to the survey
        results.
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/question-types-imagepicker.png"
        alt="Question types - Image Picker"
        width="50%"
      />
      <ul>
        <li>
          <p>
            <strong>How to: Associate values with images or videos</strong>{" "}
            Expand the <strong>Choices</strong> category and enter the values in
            the <strong>Choices</strong> table:
          </p>
          <img
            src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-choices-value.png"
            alt="Form Builder - Image value"
            width="40%"
          />
        </li>
        <li>
          <p>
            <strong>How to: Enable multiple image or video selection</strong>{" "}
            Expand the <strong>General</strong> category and select{" "}
            <strong>Allow multiple selection</strong>:
          </p>
          <img
            src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-allow-multiple-selection.png"
            alt="Form Builder - Allow multiple selection"
            width="40%"
          />
        </li>
      </ul>
      <h2 id="boolean">Boolean</h2>
      <p>
        Respondents switch a Boolean editor to Yes or No. The response is saved
        in the survey results as true for Yes or false for No.
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/question-types-boolean.png"
        alt="Question types - Boolean"
        width="50%"
      />
      <h2 id="image">Image</h2>
      <p>
        Use the Image type to add an image or video to the survey. This type is
        used for presentation only and does not produce a value to be saved in
        the survey results.
      </p>
      <h2 id="html">HTML</h2>
      <p>
        Use the HTML type to format text as needed, include links, and insert
        media or other custom elements into the survey. This type is used for
        presentation only and does not produce a value to be saved in the survey
        results. Be aware that HTML content can be vulnerable to security
        breaches. Ensure that the links you insert lead to trusted resources.
      </p>
      <h2 id="signature-pad">Signature Pad</h2>
      <p>
        Respondents use mouse or touch gestures to draw their signature within
        the allocated input area. Use this type to obtain the respondent's
        signature or any hand-drawn input.
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/question-types-signature-pad.png"
        alt="Question types - Signature pad"
        width="50%"
      />
      <ul>
        <li>
          <p>
            <strong>How to: Resize the allocated area</strong> Expand the{" "}
            <strong>General</strong> category and change the{" "}
            <strong>Width</strong> and <strong>Height</strong> values:
          </p>
          <img
            src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-change-size.png"
            alt="Form Builder - Change size"
            width="40%"
          />
        </li>
        <li>
          <p>
            <strong>How to: Change the image format</strong> The respondent's
            input is saved as a base64-encoded image in one of the following
            formats: PNG, JPEG, SVG. To select the desired format, use the{" "}
            <strong>Image format</strong> drop-down menu in the{" "}
            <strong>General</strong> category:
          </p>
          <img
            src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-image-format.png"
            alt="Form Builder - Image format"
            width="40%"
          />
        </li>
        <li>
          <p>
            <strong>How to: Change the pen color</strong> Expand the{" "}
            <strong>General</strong> category and select the desired color from
            the <strong>Pen color</strong> drop-down menu:
          </p>
          <img
            src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-pen-color.png"
            alt="Form Builder - Pen color"
            width="40%"
          />
        </li>
      </ul>
      <h2 id="expression">Expression</h2>
      <p>
        Use the Expression type to calculate values and present them to
        respondents. For example, you can sum up the scores of previous
        responses, display current date and time, or find an average value. The
        calculated value is saved in the survey results. In the following image,
        the Expression type concatenates the first and last names to display a
        full name:
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/question-types-expression.png"
        alt="Question types - Expression"
        width="50%"
      />
      <p>
        To specify an expression, enter it into the <strong>Expression</strong>{" "}
        field in the <strong>General</strong> category. Your expression can
        reference other survey questions (for example, {"question1"} +{" "}
        {"question2"}) or use built-in calculation functions. Refer to the
        following help topics for more information:{" "}
      </p>
      <ul>
        <li>
          <a href="#boolean-expressions">Boolean Expressions</a>
        </li>
        <li>
          <a href="#built-in-functions">Built-In Functions</a>
        </li>
      </ul>
      <h2 id="file">File</h2>
      <p>
        Use the File type to allow respondents to upload files. Respondents drag
        and drop one or several files onto the allocated area or select files in
        the browser's Upload File dialog window. The uploaded files are saved in
        the survey results as base64-encoded strings.
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/question-types-file.png"
        alt="Question types - File"
        width="50%"
      />
      <ul>
        <li>
          <p>
            <strong>How to: Restrict uploaded file types</strong> The File type
            is built upon the standard &lt;input type="file"&gt; HTML element.
            To restrict file types, the standard element uses the{" "}
            <a href="https://www.w3schools.com/tags/att_input_accept.asp">
              accept
            </a>{" "}
            attribute. Enter its value into the{" "}
            <strong>Accepted file types</strong> field in the{" "}
            <strong>General</strong> category:
          </p>
          <img
            src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-accepted-file-types.png"
            alt="Form Builder - Accepted file types"
            width="40%"
          />
        </li>
      </ul>
      <p>
        <strong>IMPORTANT</strong>: Always restrict the allowed file types to
        prevent possible security vulnerabilities.
      </p>
      <ul>
        <li>
          <p>
            <strong>How to: Enable multiple file selection</strong> Expand the{" "}
            <strong>General</strong> category and select{" "}
            <strong>Allow multiple files</strong>:
          </p>
          <img
            src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-allow-multiple-files.png"
            alt="Form Builder - Allow multiple files"
            width="40%"
          />
        </li>
      </ul>
      <h2 id="single-choice-matrix">Single-Choice Matrix</h2>
      <p>
        The Single-Choice Matrix type displays radio buttons in rows and
        columns. Respondents can select only one radio button in each row.
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/question-types-matrix-single-choice.png"
        alt="Question types - Single-Choice Matrix"
        width="50%"
      />
      <ul>
        <li>
          <p>
            <strong>How to: Add new rows</strong> Expand the{" "}
            <strong>Rows</strong> category and click <strong>Add New</strong>:
          </p>
          <img
            src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-add-new-row.png"
            alt="Form Builder - Add new row to a matrix question"
            width="40%"
          />
        </li>
        <li>
          <p>
            <strong>How to: Randomize rows</strong> Expand the{" "}
            <strong>Rows</strong> category, find the <strong>Row order</strong>{" "}
            editor, and switch it to Random:
          </p>
          <img
            src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-row-order.png"
            alt="Form Builder - Row order in a matrix question"
            width="40%"
          />
        </li>
      </ul>
      <p>See also:</p>
      <ul>
        <li>
          <a href="#multiple-choice-matrix">Multiple-Choice Matrix</a>
        </li>
        <li>
          <a href="#dynamic-matrix">Dynamic Matrix</a>
        </li>
      </ul>
      <h2 id="multiple-choice-matrix">Multiple-Choice Matrix</h2>
      <p>
        The Multiple-Choice Matrix type displays rows and columns. At their
        intersections, the matrix can display the following editors:
      </p>
      <ul>
        <li>
          <a href="#dropdown">Dropdown</a>
        </li>
        <li>
          <a href="#checkbox">Checkbox</a>
        </li>
        <li>
          <a href="#radiogroup">Radiogroup</a>
        </li>
        <li>
          <a href="#single-input">Single Input</a>
        </li>
        <li>
          <a href="#comment">Comment</a>
        </li>
        <li>
          <a href="#boolean">Boolean</a>
        </li>
        <li>
          <a href="#expression">Expression</a>
        </li>
        <li>
          <a href="#rating">Rating</a>
        </li>
      </ul>
      <p>
        Respondents use these editors to select a desired value in each cell.
        The following image illustrates the Multiple-Choice Matrix type with the
        default Dropdown editors:
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/question-types-matrix-multiple-choices.png"
        alt="Question types - Multiple-Choice Matrix"
        width="50%"
      />
      <ul>
        <li>
          <p>
            <strong>How to: Change the editor type</strong> Expand the{" "}
            <strong>General</strong> category and select the desired editor type
            from the <strong>Cell type</strong> drop-down menu:
          </p>
          <img
            src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-matrix-cell-type.png"
            alt="Form Builder - Matrix cell type"
            width="40%"
          />
        </li>
      </ul>
      <p>See also:</p>
      <ul>
        <li>
          <a href="#single-choice-matrix">Single-Choice Matrix</a>
        </li>
        <li>
          <a href="#dynamic-matrix">Dynamic Matrix</a>
        </li>
      </ul>
      <h2 id="dynamic-matrix">Dynamic Matrix</h2>
      <p>
        The Dynamic Matrix type is similar to a{" "}
        <a href="#multiple-choice-matrix">Multiple-Choice Matrix</a>, but
        respondents can add and remove matrix rows.{" "}
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/question-types-matrix-dynamic-rows.png"
        alt="Question types - Dynamic Matrix"
        width="50%"
      />
      <h2 id="multiple-text">Multiple Text</h2>
      <p>
        Respondents enter their answers into multiple single-line text editors.
        Use the Multiple Text type for open-ended questions that require more
        than one short answer.
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/question-types-multiple-text.png"
        alt="Question types - Multiple text"
        width="50%"
      />
      <ul>
        <li>
          <p>
            <strong>How to: Specify default answers</strong> Expand the{" "}
            <strong>Data</strong> category and click{" "}
            <strong>Set Default Answer</strong> to open the Default Answer
            pop-up window. Enter the default answers into the text editors and
            click <strong>Apply</strong>:
          </p>
          <img
            src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-multiple-text-set-default-answer.png"
            alt="Form Builder - Matrix cell type"
            width="80%"
          />
        </li>
      </ul>
      <h2 id="panel">Panel</h2>
      <p>
        The Panel type is a container for other questions and panels. Use this
        type to group several questions or panels and control them all together.
      </p>
      <h2 id="dynamic-panel">Dynamic Panel</h2>
      <p>
        The Dynamic Panel type is a template panel that can contain multiple
        questions. Respondents can add and remove panels based on the template.
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/question-types-dynamic-panel.png"
        alt="Question types - Dynamic panel"
        width="50%"
      />

      <h2 id="adorners">Adorners</h2>
      <p>
        Adorners are design-surface controls for form element manipulation. Use
        adorners to edit UI text in place, reorder choices, duplicate and delete
        elements, specify whether an answer is required, and perform other
        actions on form elements. Settings that you specify using adorners are
        synchronized with settings in the Property Grid, and vice versa.
      </p>
      <p>
        Each element type has an associated set of adorners. The following image
        highlights adorners for a <a href="#dropdown">Dropdown</a> question:{" "}
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-dropdown-adorners.png"
        alt="Form Builder - Adorners"
        width="50%"
      />
      <h2 id="how-to-add-a-question-to-the-survey">
        How to: Add a question to the form
      </h2>
      <p>
        Drag and drop the desired question type from the Toolbox onto the design
        surface. Alternatively, you can click the <strong>Add Question</strong>{" "}
        button to add a <a href="#single-input">Single Input</a> question. This
        button also displays an ellipsis icon that allows you to change the type
        of inserted questions.{" "}
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-add-question-button.png"
        alt="Form Builder - Add Question button"
        width="50%"
      />
      <h2 id="how-to-add-a-page-to-the-survey">
        How to: Add a page to the survey
      </h2>
      <p>
        In the Property Grid, select <strong>Survey</strong>, expand the{" "}
        <strong>Pages</strong> category, and click the <strong>Add New</strong>{" "}
        button in the <strong>Pages</strong> editor:{" "}
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-add-new-page.png"
        alt="Form Builder - Add new page"
        width="40%"
      />
      <p>
        Alternatively, you can add a page on the design surface. The surface
        displays the skeleton of a new page at the bottom. To add a new page,
        you need to{" "}
        <a href="#how-to-add-a-question-to-the-survey">add a question to it</a>.{" "}
      </p>
      <h2 id="how-to-change-the-question-type">
        How to: Change the question type
      </h2>
      <p>Use the element type selector in the question:</p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-element-type-selector.png"
        alt="Form Builder - Element type selector"
        width="50%"
      />
      <p>
        Some question types are non-interchangeable. For example, a{" "}
        <a href="#dropdown">Dropdown</a> question supports choices, while{" "}
        <a href="#single-input">Single Input</a> does not. If you switch the
        question type from Dropdown to Single Input, your choices will be lost.
        In this case, you can click the <strong>Undo</strong> button to
        reinstate the previous configuration:{" "}
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-undo-button.png"
        alt="Form Builder - The Undo button"
        width="50%"
      />
      <h2 id="how-to-clear-the-survey">How to: Clear the survey</h2>
      <p>
        Click the <strong>Clear Survey</strong> button on the Toolbar:{" "}
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-clear-survey.png"
        alt="Form Builder - The Clear Survey button"
        width="50%"
      />
      <h2>Preview Tab</h2>
      <p>
        The Preview tab allows you to view and take the survey as a respondent.
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-preview-tab.png"
        alt="Form Builder - The Preview tab"
        width="50%"
      />
      <h2 id="#how-to-re-run-the-preview">How to: Re-run the preview</h2>
      <p>
        After you complete the survey, you can see the survey results in the
        table or JSON format. Click the Preview Survey Again button above the
        results to preview the survey again:
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-preview-survey-again.png"
        alt="Form Builder - Preview survey again"
        width="50%"
      />
      <h2 id="how-to-preview-the-survey-on-different-devices">
        How to: Preview the survey on different devices
      </h2>
      <p>
        Click the <strong>Device</strong> icon and select the desired device
        from the drop-down menu. You can also click the{" "}
        <strong>Orientation</strong> icon to switch between the landscape and
        portrait orientations:{" "}
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-preview-different-devices.png"
        alt="Form Builder - Preview the survey on different devices"
        width="50%"
      />
      <h2>Logic Tab</h2>
      <p>
        The Logic tab displays and allows you to edit logical rules that specify
        the survey flow.
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-logic-tab.png"
        alt="Form Builder - The Logic tab"
        width="75%"
      />
      <h2 id="how-to-add-a-new-rule">How to: Add a new rule</h2>
      <p>
        Click <strong>Add New Rule</strong> at the bottom of the Logic tab to
        display editors that allow you to specify conditions and select actions
        to perform when these conditions are met.{" "}
      </p>
      <h4>Specify conditions (if-clauses)</h4>
      <p>
        Select a question whose answer should be checked and a logical operation
        from the corresponding drop-down menus. Then, specify the answer that
        applies the rule. Some logical operations do not need an answer (for
        example, Empty or Not empty).
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-logic-tab-add-new-rule.png"
        alt="Form Builder - Add new logical rule"
        width="50%"
      />
      <p>
        If the rule needs more than one condition, click the{" "}
        <strong>Add Condition</strong> button. You can use the AND or OR logical
        operator to combine the new and previous conditions.{" "}
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-multiple-conditions.png"
        alt="Form Builder - Add new logical rule"
        width="33%"
      />
      <p>
        Conditions that you specify in the UI are converted to logical
        expressions. If you prefer to enter the expressions directly, click the{" "}
        <strong>Manual Entry</strong> button on the Toolbar.{" "}
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-logic-tab-manual-entry.png"
        alt="Form Builder - Manual entry"
        width="75%"
      />
      <h4>Specify the action (then-clause)</h4>
      <p>Select one of the following actions from the drop-down menu:</p>
      <ul>
        <li>
          <p>
            <strong>Show (hide) page</strong> Makes the selected page visible.
            If you want to hide the page, invert the logic of your condition.
          </p>
        </li>
        <li>
          <p>
            <strong>Enable (disable) page</strong> Makes the selected page
            editable. If you want to make the page read-only, invert the logic
            of your condition.
          </p>
        </li>
        <li>
          <p>
            <strong>Show (hide) question</strong> Makes the selected question
            visible. If you want to hide the question, invert the logic of your
            condition.
          </p>
        </li>
        <li>
          <p>
            <strong>Enable (disable) question</strong> Makes the selected
            question editable. If you want to make the question read-only,
            invert the logic of your condition.
          </p>
        </li>
        <li>
          <p>
            <strong>Make question required</strong> Requires an answer for the
            selected question.
          </p>
        </li>
        <li>
          <p>
            <strong>Complete survey</strong> Completes the survey.
          </p>
        </li>
        <li>
          <p>
            <strong>Set answer</strong> Sets a specified answer to the selected
            question.
          </p>
        </li>
        <li>
          <p>
            <strong>Copy answer</strong> Copies the answer from the selected
            question to a target question.
          </p>
        </li>
        <li>
          <p>
            <strong>Skip to question</strong> Navigates the survey to the
            selected question.
          </p>
        </li>
        <li>
          <p>
            <strong>Run expression</strong> Runs a custom expression on the
            selected question.
          </p>
        </li>
        <li>
          <p>
            <strong>Set "Survey Complete" page markup</strong> Specifies custom
            HTML markup for the Survey Complete page.
          </p>
        </li>
      </ul>
      <h2 id="how-to-edit-a-rule">How to: Edit a rule</h2>
      <p>
        Click a rule to expand it and use UI elements to edit the rule. Click{" "}
        <strong>Done</strong> to save your changes.{" "}
      </p>
      <h2 id="how-to-filter-rules">How to: Filter rules</h2>
      <p>
        Use the Question Filter to filter rules by a specific question. You can
        also use the Action Type Filter to view only rules for the selected
        action type:
      </p>
      <img
        src="https://surveyjs.io/survey-creator/documentation/images/survey-creator-logic-tab-filter-rules.png"
        alt="Form Builder - Filter rules in the Logic tab"
        width="50%"
      />
    </Article>
  );
}
