/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: select plan and make payment
 *
 **********/

import React, { useContext } from "react";
import {
  AuthContext,
  Animate,
  Row,
  Card,
  SocialSignin,
  Form,
  Link,
} from "components/lib";
import { Helmet } from "react-helmet";

export function Signup(props) {
  const context = useContext(AuthContext);

  return (
    <Animate type="pop">
      <Helmet>
        <meta
          name="description"
          content="SubmitHero - Simple Candidate Submissions - Create your free SubmitHero account."
        ></meta>
        <meta name="title" content="Sign Up for SubmitHero"></meta>
      </Helmet>
      <Row title="Create Your Account">
        <Card loading={false} restrictWidth center>
          {/* <SocialSignin network={['facebook', 'twitter']} showOr signup /> */}

          <Form
            data={{
              name: {
                label: "First Name",
                value: "",
                type: "text",
                required: true,
                errorMessage: "Please enter your first name",
              },
              email: {
                label: "Email",
                value: "",
                type: "email",
                required: true,
              },
              password: {
                label: "Password",
                type: "password",
                required: true,
                complexPassword: true,
              },
              confirm_password: {
                type: "hidden",
                value: null,
              },
            }}
            url="/api/account"
            method="POST"
            buttonText="Create Account"
            callback={(res) => context.signin(res)}
          />

          <span>
            Already registered? <Link url="/signin" text="Sign In" />
          </span>
        </Card>
      </Row>
    </Animate>
  );
}
