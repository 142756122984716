/***
 *
 *   ONBOARDING
 *   Example of onboarding flow*
 *
 **********/

import React, { Fragment, useContext } from "react";
import {
  AuthContext,
  Onboarding,
  Form,
  Message,
  Image,
  History,
} from "components/lib";
// import Astronaut from "./images/astronaut.jpg";

export function OnboardingView(props) {
  const context = useContext(AuthContext);
  const views = [
    {
      name: "Getting Started",
      description: `Welcome, ${context.user.name}!`,
      component: <Welcome />,
    },
  ];

  if (context.permission.admin) {
    views.push({
      name: "Start your campaign",
      description:
        "You are only a few clicks away from getting your campaign running. ",
      component: <StartCampaign />,
    });
  }

  if (context.user.duplicate_user) {
    views.unshift({
      name: "Important!",
      description: "",
      component: <DuplicateUser />,
    });
  }

  return <Onboarding save onFinish="/dashboard" views={views} />;
}

function DuplicateUser() {
  return (
    <Message
      type="warning"
      title={`You already have an account`}
      text="We noticed you have already registered an account, so we used your original password to save you any confusion in the future."
    />
  );
}

function Welcome() {
  return (
    <Fragment>
      <p>
        SubmitHero will help your candidates complete their application and give
        you visibility of their progress. This leads to a higher quality cohort
        of candidates.
      </p>

      <p>Start building your campaign by clicking “Next”!</p>

      <Image source="https://stackgo.io/wp-content/uploads/2022/04/Untitled-design-7.png.webp" />
    </Fragment>
  );
}

function StartCampaign(props) {
  return (
    <Form
      data={{
        name: {
          label: "",
          type: "text",
          placeholder: "My First Campaign",
          required: true,
          errorMessage: "Please enter a campaign name",
        },
      }}
      url="/api/campaign"
      buttonText="Create"
      method="post"
      callback={(res) => {
        console.log(res.data);
        History.push(`/wizard/form/${res.data.data.id}`);
      }}
    />
  );
}
