import React from "react";
import { Article, Image, Card } from "components/lib";

export function HubspotIntegrationDocs(props) {
  return (
    <Article>
      <h1>HubSpot Integration Setup</h1>
      <p>Setting up your HubSpot Integration only takes a few minutes.</p>
      <p>Key features:</p>
      <ul className="list">
        <li>
          Submissions from SubmitHero campaigns are updated as HubSpot contacts
          (new contacts might be added)
        </li>
        <li>
          Notes are added to HubSpot candidates showing their status i.e.
          Created, In Progress or Submitted.
        </li>
      </ul>
      <h2>Step 1: Go to integration settings (account required)</h2>

      <Card restrictWidth>
        <Image source="https://storage.googleapis.com/submithero-images/Docs/Integration/Hubspot/nav.png" />
      </Card>

      <Image source="https://storage.googleapis.com/submithero-images/Docs/Integration/Hubspot/integ.png" />
      <h2>Step 2: Authorise HubSpot</h2>
      <Image source="https://storage.googleapis.com/submithero-images/Docs/Integration/Hubspot/HS%20tile.png" />
      <Image source="https://storage.googleapis.com/submithero-images/Docs/Integration/Hubspot/oauth.png" />
      <h2>Step 3: Enable Integration for a campaign by ▶ or pause via ⏸ </h2>
      <Card>
        <Image source="https://storage.googleapis.com/submithero-images/Docs/Integration/Hubspot/campaign.png" />
      </Card>
      <Image source="https://storage.googleapis.com/submithero-images/Docs/Integration/Hubspot/campaign-on.png" />
    </Article>
  );
}
