/***
 *
 *   FOOTER (homepage)
 *   Static homepage footer
 *
 **********/

import React from "react";
import { Animate, Row, Content, Link } from "components/lib";
import Style from "./footer.module.scss";

export function Footer(props) {
  return (
    <Animate type="slideup">
      <footer className={Style.footer}>
        <Row>
          <Content>
            <nav>
              <Link url="/" text="Home" />
              <Link url="/pricing" text="Pricing" />
              <Link url="/signin" text="Sign in" />
              <Link url="/signup" text="Sign up" />
              <Link url="https://stackgo.io/tos/" text="Terms" />
              <Link url="/privacy" text="Privacy" />
              <Link url="/contact" text="Contact" />
            </nav>

            <span>{`Copyright © StackGo ${new Date().getFullYear()}`}</span>
            <address>Made with ❤️ in Sydney, Australia </address>
          </Content>
        </Row>
      </footer>
    </Animate>
  );
}
