const campaignWizardJson = {
  pages: [
    {
      name: "Dates",
      title: "Provide some dates and a contact email for your campaign",
      elements: [
        {
          name: "name",
          type: "text",
          title: "Campaign Name",
          isRequired: true,
        },
        {
          name: "startDate",
          type: "text",
          title: "Start Date",
          inputType: "datetime-local",
          isRequired: true,
          // minValueExpression: "today()",
        },
        {
          name: "endDate",
          type: "text",
          title: "End Date",
          inputType: "datetime-local",
          hideNumber: true,
          isRequired: true,
        },
        {
          name: "contactEmail",
          type: "text",
          title: "Contact Email",
          inputType: "email",
          isRequired: true,
          placeHolder: "team@company.com",
          description:
            "Applicants will be instructed to reach out to this email address if they have queries.",
          autoComplete: "email",
          isRequired: true,
        },
      ],
      description:
        "Applications will only be accepted when for LIVE campaigns between start and end date",
    },
    {
      name: "campaignLandingPage",
      title: "Campaign Landing Page",
      elements: [
        {
          name: "campaignLandingTitle",
          type: "text",
          title: "Headline",
          isRequired: true,
          defaultValueExpression: "{name} + ' Applications'",
        },
        {
          name: "campaignLandingImage",
          type: "file",
          title: "Banner Image",
          acceptedTypes: "image/*",
          allowMultiple: false,
          storeDataAsText: false,
          waitForUpload: true,
          needConfirmRemoveFile: true,
        },
        {
          name: "campaignLandingText",
          type: "editor",
          title: "Intro Text (HTML)",
          description:
            "Use this area to communicate start and end dates. Key info about the program. Communication channels (email, slack etc) can the candidate reach for help?",
          isRequired: true,
          defaultValueExpression:
            "'<p>Thanks for your interest in ' + {name} + '. Please enter your email to start your application.</p>\n\n<p> We will send you a unique link to prepare your application. Your partially completed application will then be saved so you can come back to it anytime. </p>\n\n<br><p>For any questions, please contact' + ' ' + {contactEmail} + '</p>'",
        },
        {
          name: "campaignLandingFooterText",
          type: "comment",
          title: "Terms and Conditons (HTML)",
          description:
            "Add in fine print such as a link to terms and conditions and privacy policy.",
          isRequired: false,
          defaultValueExpression:
            'By submitting my email me/ my team agree to the <a href="/Privacy">Data Privacy policy.</a>',
        },
      ],
      description:
        "This is the landing page from where applicants will start their journey.",
    },
    {
      name: "campaignLandingPreview",
      title: "Campaign Landing Page Preview",
      elements: [
        {
          html: `<html lang="en">
          <head>
             <meta charset="utf-8" />
             <meta name="viewport" content="width=device-width, initial-scale=1" />
             <title>GNI Application 2022</title>
          </head>
          <body class="color">
             <noscript>You need to enable JavaScript to run this app.</noscript>
             <div id="root">
                <main class="onboarding_onboarding__3kMJ2">
                   <section class="row_row__113Mc">
                      <div class="content_content__1sUD_">
                         <header>
                            <h2>{campaignLandingTitle}</h2>
                         </header>
                         <section class="card_card__1f1QP">
                            <img src="{validatedLandingPageImage}"                class="image_image__35ngB"  width="1000"   />              
                            <article class="article_article__1Kfzr">
                               <div class="content_content__1sUD_">
                                  {campaignLandingText}                    
                                  <p>To start or continue, please enter your email below.</p>
                                  </p>                  
                                  <section  class="card_card__1f1QP card_center__1vz1 card_restrictWidth__1Ot9H">
                                     <form  method="POST" class="form_form__3mg2W"   enctype="0"   novalidate="" >
                                        <div class="input_input__TgSpx"> 
                                        <label  class="label_label__3uOXr label_required__Kv3yO"  for="email">Your Email</label>
                                        <input  type="email" id="email" name="email"  class="input_textbox__3vy2t"  value=""   />                      
                                        </div>
                                        <input type="hidden"                        id="campaign_id"                        name="campaign_id"                        value="e3806189-b7bb-43da-8934-a46f3c619aa5"  
                                        />                      
                                        <div  class="btnLoader button_loader__3PK7c button_fullWidth__3UbPz">
                                           <buttondisabled                           class="button_green__3XkO3 button_fullWidth__3UbPz button_btn__3Eo3S" >
                                           LET\'S GO! </button>                      
                                        </div>
                                        <p style="font-size: 0.8em;">
                                        {campaignLandingFooterText}
                                        <br />
                                        By clicking 'LET'S GO!' you agree to our
                                        <a href="/privacy">Privacy Policy</a> and
                                        <a href="https://stackgo.io/tos/">Terms of Service</a>
                                      </p>
                                     </form>
                                  </section>
                               </div>
                            </article>
                         </section>
                      </div>
                   </section>
                </main>
             </div>
          </body>
       </html>`,
          name: "campaignLandingPreviewHTML",
          type: "html",
        },
      ],
      description:
        "This is what the applicant's will see when they start off their process.",
    },
    {
      name: "thanksPage",
      title: "Thanks Page",
      elements: [
        {
          name: "thanksPageTitle",
          type: "text",
          title: "Headline",
          isRequired: true,
          defaultValueExpression: "'Your ' + {name} + ' Application' ",
        },
        {
          name: "thanksPageBody",
          type: "editor",
          title: "Body Text (HTML)",
          isRequired: true,
          defaultValueExpression: "'Your ' + {name} + ' Application' ",
        },
      ],
    },
    {
      name: "thanksPagePreview",
      title: "Thanks Page Preview",
      elements: [
        {
          name: "thanksPagePreviewHTML",
          type: "html",
          html: `          <head>
          <style media="all" type="text/css">
            .outerbox {
              background-color: #000000;
            }
        
            .logo {
              padding: 10px;
              margin: 10px;
            }

            .content{
              background-color: #fff;
              margin: 25px;
              padding: 10px;
              text-align: left;
              font-family: sans-serif;
              font-size: 14px;
              vertical-align: top;
              padding-bottom: 15px;
              min-height: 400px;
            }
        
            .content_head {
              background-color: #fff;
              margin: 25px;
              padding: 10px;
              text-align: left;
              font-family: sans-serif;
              font-size: 1.5625;
              font-weight: 700;
              color: #000000;
              text-align: center;
              vertical-align: top;
              padding-bottom: 15px;
            }

            .content_body {
              background-color: #fff;
              margin: 25px;
              padding: 10px;
              text-align: left;
              font-family: sans-serif;
              font-size: 1.5625;
              font-weight: 500;
              color: #000000;
              vertical-align: top;
              padding-bottom: 15px;
            } 
        
            .button {
              padding: 20px;
              display: block;
              color: #ffffff;
              background-color: #000000;
              border-radius: 4px;
              box-sizing: border-box;
              cursor: pointer;
              text-decoration: none;
              font-family: \'Source Sans Pro\', helvetica, arial, sans-serif;
              font-size: 18px;
              font-weight: 700;
              margin: 0;
              text-transform: uppercase;
              border-color: #0032E4;
              margin-left: auto;
              margin-right: auto;
            }
          </style>
        </head>
  
        <div style class="outerbox">
          <div align="center" class="logo">
   
            <div class="content">
              <h1 class="content_head">{thanksPageTitle}</h1>
              <article class="content_body">{thanksPageBody}</article>
            </div>
          </div>  `,
        },
      ],
    },
    {
      name: "welcomeEmailPage",
      title: "Welcome Email",
      elements: [
        {
          name: "welcomeEmailSubject",
          type: "text",
          title: "Subject",
          isRequired: true,
          defaultValueExpression: "'Your ' + {name} + ' Application' ",
        },
        {
          name: "welcomeEmailTitle",
          type: "text",
          title: "Title",
          isRequired: true,
          defaultValueExpression: "'Your ' + {name} + ' Application' ",
        },
        {
          name: "welcomeEmailBanner",
          type: "file",
          title: "Banner Image",
          acceptedTypes: "image/*",
          allowMultiple: false,
          storeDataAsText: false,
          waitForUpload: true,
          needConfirmRemoveFile: true,
          description:
            "Upload an image to be shown on the first email users recieve.",
        },
        {
          name: "welcomeEmailBody",
          type: "editor",
          title: "Body (HTML)",
          isRequired: true,
          defaultValueExpression:
            "'<p>Thanks for your interest in <strong>' + {name} + '</strong>. Your personal application link is below. Click  <em>Start my Application</em> to begin.</p><br>\n\n<p> Please contact ' + {contactEmail} + ' with any questions.<p>' ",
        },
        {
          name: "welcomeEmailButtonText",
          type: "text",
          title: "Button Text",
          isRequired: true,
          defaultValueExpression: "'Start my Application' ",
        },
      ],
      description:
        "This is the email sent to the applicant to welcome them to complete their submission with a unique link.  Use {{email}} in subject or body to insert applicant's email address. ",
    },
    {
      name: "welcomeEmailPreview",
      title: "Welcome Email Preview",
      elements: [
        {
          html: `          <head>
          <style media="all" type="text/css">
            .outerbox {
              background-color: #000000;
            }
        
            .logo {
              padding: 10px;
              margin: 10px;
            }
        
            .content {
              background-color: #fff;
              margin: 25px;
              padding: 10px;
              text-align: left;
              font-family: sans-serif;
              font-size: 14px;
              vertical-align: top;
              padding-bottom: 15px;
            }
        
            .button {
              padding: 20px;
              display: block;
              color: #ffffff;
              background-color: #000000;
              border-radius: 4px;
              box-sizing: border-box;
              cursor: pointer;
              text-decoration: none;
              font-family: \'Source Sans Pro\', helvetica, arial, sans-serif;
              font-size: 18px;
              font-weight: 700;
              margin: 0;
              text-transform: uppercase;
              border-color: #0032E4;
              margin-left: auto;
              margin-right: auto;
            }
          </style>
        </head>
        <div>
        Subject: {welcomeEmailSubject}
        </div>
        <div style class="outerbox">
          <div align="center" class="logo">
            <img src="{validatedWelcomeEmailImage}" width="500" alt="Logo" />
            <div class="content">
              <h1>{welcomeEmailTitle}</h1>
              <article>{welcomeEmailBody}</article>
              <button class="button">{welcomeEmailButtonText}</button>
            </div>
          </div> `,
          name: "welcomeEmailPreviewHTML",
          type: "html",
          defaultValueExpression: "{welcomeEmailBody}",
        },
      ],
      description: "This is a preview of what the email would look like",
    },
    {
      name: "thanksEmail",
      title: "Thanks Email",
      elements: [
        {
          name: "thanksEmailSubject",
          type: "text",
          title: "Subject",
          isRequired: true,
          defaultValueExpression:
            "'Your ' + {name} + ' Application - Submitted' ",
        },
        {
          name: "thanksEmailTitle",
          type: "text",
          title: "Title",
          isRequired: true,
          defaultValueExpression: "'Your ' + {name} + ' Application' ",
        },
        {
          name: "thanksEmailImage",
          type: "file",
          title: "Banner Image",
          inputType: "url",
          description:
            "This image will be displayed on the email sent to users when the submission is completed. ",
          acceptedTypes: "image/*",
          allowMultiple: false,
          storeDataAsText: false,
          waitForUpload: true,
          needConfirmRemoveFile: true,
        },
        {
          name: "thanksEmailBody",
          type: "editor",
          title: "Body",
          isRequired: true,
          defaultValueExpression:
            "'<p>Your submission for <strong>' + {name} + '</strong> has been recieved.</p><br>\n\n<p> Please contact ' + {contactEmail} + ' with any questions.</p>' ",
        },
        {
          name: "thanksEmailButton",
          type: "boolean",
          title: "Add Button",
          default: true,
          description:
            "Add a button to the email that links to the submission.",
        },
        {
          name: "thanksEmailButtonText",
          type: "text",
          title: "Button Text",
          isRequired: true,
          defaultValueExpression: "'View My Submission' ",
          visibleIf: "{thanksEmailButton} = true",
        },
      ],
      description:
        "This is the email sent to the applicant to welcome them to complete their submission with a unique link.  Use {{email}} in subject or body to insert applicant's email address. ",
    },
    {
      name: "thanksEmailPreview",
      title: "Thanks Email Preview",
      elements: [
        {
          html: `
          <head>
          <style media="all" type="text/css">
            .outerbox {
              background-color: #000000;
            }
        
            .logo {
              padding: 10px;
              margin: 10px;
            }
        
            .content {
              background-color: #fff;
              margin: 25px;
              padding: 10px;
              text-align: left;
              font-family: sans-serif;
              font-size: 14px;
              vertical-align: top;
              padding-bottom: 15px;
            }
        
            .button {
              padding: 20px;
              display: block;
              color: #ffffff;
              background-color: #000000;
              border-radius: 4px;
              box-sizing: border-box;
              cursor: pointer;
              text-decoration: none;
              font-family: \'Source Sans Pro\', helvetica, arial, sans-serif;
              font-size: 18px;
              font-weight: 700;
              margin: 0;
              text-transform: uppercase;
              border-color: #0032E4;
              margin-left: auto;
              margin-right: auto;
            }
          </style>
        </head>
        <div>
        Subject: {thanksEmailSubject}
        </div>
        <div style class="outerbox">
          <div align="center" class="logo">
            <img src="{validatedThanksEmailImage}" width="500" alt="Logo" />
            <div class="content">
              <h1>{thanksEmailTitle}</h1>
              <article>{thanksEmailBody}</article>
              {showThanksEmailButtonFunction}
            </div>
          </div>   
          `,
          name: "thanksEmailPreviewHTML",
          type: "html",
          defaultValueExpression: "{welcomeEmailBody}",
        },
      ],
      description: "This is a preview of what the email would look like",
    },
    {
      name: "final",
      title: "Let's get started!",
      elements: [
        {
          name: "status",
          type: "boolean",
          title: "Ready to Go?",
          labelTrue: "Live",
          isRequired: true,
          labelFalse: "Draft",
          defaultValue: false,
        },
        {
          html: "<h3>In Draft mode, SubmitHero registered users can test the candidate experience. \n</h3>",
          name: "draftModeComment",
          type: "html",
          visibleIf: "{status} = false",
        },
        {
          html: "<h3>Candidates will be able to  create submissions for this campaign. The campaign settings will become read-only.  </h3>\n<br />\n<h1>\nGood luck!\n</h1>\n",
          name: "liveModeComment",
          type: "html",
          visibleIf: "{status} = true",
        },
      ],
    },
  ],
  title: "Campaign Wizard",
  description:
    "Follow this campaign wizard to configure your world class candidate experience. ",
  logoPosition: "right",
  showCompletedPage: false,
  showQuestionNumbers: "off",
  calculatedValues: [
    {
      name: "validatedLandingPageImage",
      expression: "defaultLandingImageFunction({campaignLandingImage})",
      includeIntoResult: false,
    },
    {
      name: "validatedWelcomeEmailImage",
      expression: "defaultImageFunction({welcomeEmailBanner})",
      includeIntoResult: false,
    },
    {
      name: "validatedThanksEmailImage",
      expression: "defaultImageFunction({thanksEmailImage})",
      includeIntoResult: false,
    },
    {
      name: "showThanksEmailButtonFunction",
      expression:
        "showThanksEmailButtonFunction({thanksEmailButton}, {thanksEmailButtonText})",
      includeIntoResult: false,
    },
  ],
};

export default campaignWizardJson;
