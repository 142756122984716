const campaignNotificationJson = {
  description: "Use this builder to configure email notifications",
  logoPosition: "right",
  completedHtml: "<h3>Campaign Email Notification Settings are updated!</h3>",
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "text",
          name: "name",
          title: "Name",
          isRequired: true,
        },
        {
          type: "dropdown",
          name: "type",
          title: "Notification Type",
          isRequired: true,
          description: "Select the type of notification to send",
          choices: [
            "campaignEndEmails|Campaign Deadline Emails",
            "followUpEmails|Follow up Applicants",
          ],
        },
        {
          type: "tagbox",
          name: "audience",
          title: "Audience",
          isRequired: true,
          choices: [
            "SUBMITTED|Submitted",
            "IN_PROGRESS|In Progress",
            "CREATED|Created",
          ],
        },
        {
          type: "text",
          name: "execution_time",
          title: "Execution Time",
          description: "{timeDescription}",
          inputType: "number",
          isRequired: true,
        },
        {
          type: "boolean",
          name: "status",
          title: "Status",
          description:
            "Save: Stores the settings, Deploy: Stores and enqueue the notifications",
          defaultValue: "false",
          labelTrue: "Deploy",
          labelFalse: "Save",
          isRequired: true,
        },
        {
          type: "text",
          name: "campaignId",
          visible: true,
          readOnly: true,
          title: "Campaign Id",
          isRequired: true,
        },
        {
          type: "text",
          name: "notificationId",
          visible: true,
          readOnly: true,
          title: "Notification Id",
          isRequired: true,
        },
      ],
    },
    {
      name: "campaignEndEmailSettings",
      title: "Campaign Deadline Email Settings",
      elements: [
        {
          type: "text",
          name: "subject",
          title: "Subject",
        },
        {
          type: "text",
          name: "title",
          title: "Title",
        },
        {
          type: "file",
          name: "banner",
          title: "Email Banner",
          description:
            "Upload an image to be shown on the first email users receive.",
          acceptedTypes: "image/*",
          allowMultiple: false,
          storeDataAsText: false,
          waitForUpload: true,
        },
        {
          type: "editor",
          name: "body",
          title: "Body (HTML)",
        },
        {
          type: "boolean",
          name: "button",
          title: "Link to Submission",
        },
        {
          type: "text",
          name: "buttonText",
          visibleIf: "{button} = true",
          title: "Button Text",
          requiredIf: "{button} = true",
          maxLength: 50,
        },
      ],
    },
    {
      name: "campaignEndEmailPreview",
      title: "Campaign End Email - Preview",
      elements: [
        {
          type: "html",
          name: "preview",
          title: "Preview",
          html: `
              <head>
              <style media="all" type="text/css">
                .outerbox {
                  background-color: #000000;
                }
            
                .logo {
                  padding: 10px;
                  margin: 10px;
                }
            
                .content {
                  background-color: #fff;
                  margin: 15px;
                  padding: 30px;
                  text-align: left;
                  font-family: sans-serif;
                  font-size: 14px;
                  vertical-align: top;
                  padding-bottom: 15px;
                }
            
                .button {
                  padding: 20px;
                  display: block;
                  color: #ffffff;
                  background-color: #000000;
                  border-radius: 4px;
                  box-sizing: border-box;
                  cursor: pointer;
                  text-decoration: none;
                  font-family: \'Source Sans Pro\', helvetica, arial, sans-serif;
                  font-size: 18px;
                  font-weight: 700;
                  margin: 0;
                  text-transform: uppercase;
                  border-color: #0032E4;
                  margin-left: auto;
                  margin-right: auto;
                }

                ul {
                  list-style: disc;
                  padding-left: 20px;
                }
                
                li,p {
                  list-style: outside;
                }

              </style>
            </head>
            <div>
            Subject: {subject}
            </div>
            <div style class="outerbox">
              <div align="center" class="logo">
                <img src="{validatedCampaignEndImage}" width="500" alt="Logo" />
                <div class="content">
                  <h1>{emailTitle}</h1>
                  <article>{body}</article>
                 {showCampaignEndButtonFunction}
                </div>
              </div>   
              `,
        },
      ],
    },
  ],
  calculatedValues: [
    {
      name: "validatedCampaignEndImage",
      expression: "defaultCampaignEndImageFunction({banner})",
      includeIntoResult: false,
    },
    {
      name: "showCampaignEndButtonFunction",
      expression: "showCampaignEndButtonFunction({button}, {buttonText})",
      includeIntoResult: false,
    },
    {
      name: "finalLabel",
      expression: "iif({status} = true, 'Deploy' , 'Save & Exit')",
      includeIntoResult: false,
    },
    {
      name: "emailTitle",
      expression: "{title}",
      includeIntoResult: false,
    },
    {
      name: "timeDescription",
      expression:
        "iif({type} = 'campaignEndEmails', 'Select how many hours before the deadline to send, in hours.', 'Select how many after created to follow up, in hours.')",
      includeIntoResult: false,
    },
  ],
  showPageTitles: true,
  completeText: "{finalLabel}",
  showQuestionNumbers: "off",
};

export default campaignNotificationJson;
