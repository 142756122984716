/***
 *
 *   PRICING
 *   Modify the PricePlans component with your own features
 *
 **********/

import React from "react";
import { Animate, Row, PricePlans } from "components/lib";
import { Helmet } from "react-helmet";

export function Pricing(props) {
  return (
    <Animate type="pop">
      <Helmet>
        <meta
          name="description"
          content="SubmitHero - Simple Candidate Submissions - Simple, affordable pricing to get you started on your journey."
        ></meta>
        <meta name="title" content="SubmitHero Pricing"></meta>
      </Helmet>
      <Row color="tint" align="center" title="Simple, affordable pricing">
        <PricePlans />
      </Row>
    </Animate>
  );
}
