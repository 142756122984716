import React from "react";
import { Row, Card, Image, Article, useAPI } from "components/lib";
import parse from "html-react-parser";

export function GenGetStarted(props) {
  const campaignId = props.match.params.campaignId;
  const campaign = useAPI("/api/campaign/info/" + campaignId);
  console.log("body", campaign?.data?.info?.thanksPageBody);
  console.log("title", campaign?.data?.info?.thanksPageTitle);

  return (
    !campaign?.loading && (
      <div style={{ minWidth: "450px" }}>
        <Row
          title={parse(campaign?.data?.info?.thanksPageTitle || "some text")}
        >
          <Card>
            {parse(campaign?.data?.info?.thanksPageBody || "Some body")}
          </Card>
        </Row>
      </div>
    )
  );
}
