import React, { useState, useEffect, Fragment, useContext } from "react";
import { Card, useAPI, Button } from "components/lib";

import { Model } from "survey-core";
import "survey-analytics/survey.analytics.min.css";

import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import "jspdf-autotable";

import * as SurveyAnalyticsTabulator from "survey-analytics/survey.analytics.tabulator";
import "tabulator-tables/dist/css/tabulator.css";
import "survey-analytics/survey.analytics.tabulator.css";

window.jsPDF = jsPDF;
window.XLSX = XLSX;

export function CampaignTable(props) {
  const [survey, setSurvey] = useState(null);
  const [vizPanel, setVizPanel] = useState(null);

  const formData = useAPI(
    "/api/campaign/" + props.computedMatch.params.id + "/form"
  );
  console.log(formData);
  const campaignResponses = useAPI(
    "/api/campaign/" + props.computedMatch.params.id + "/all-submissions"
  );

  console.log("campaign resp", campaignResponses);

  //   const vizPanelOptions = {
  //     allowHideQuestions: false,
  //   };

  useEffect(() => {
    if (vizPanel) {
      vizPanel.render("surveyVizPanel");
      return () => {
        document.getElementById("surveyVizPanel").innerHTML = "";
      };
    }
  }, [vizPanel]);

  //   if (!survey && formData.data) {
  //     console.log("formData", formData.data);
  //     const survey = new Model(formData.data?.fieldData);
  //     setSurvey(survey);
  //   }

  //   if (!vizPanel && !!survey && !!campaignResponses.data) {
  //     const vizPanel = new VisualizationPanel(
  //       survey.getAllQuestions(),
  //       campaignResponses.data,
  //       vizPanelOptions
  //     );
  //     vizPanel.showDataExplorer = true;
  //     vizPanel.showHeader = false;
  //     setVizPanel(vizPanel);
  //   }
  //   const surveyJson = {
  //     elements: [
  //       {
  //         name: "satisfaction-score",
  //         title: "How would you describe your experience with our product?",
  //         type: "radiogroup",
  //         choices: [
  //           { value: 5, text: "Fully satisfying" },
  //           { value: 4, text: "Generally satisfying" },
  //           { value: 3, text: "Neutral" },
  //           { value: 2, text: "Rather unsatisfying" },
  //           { value: 1, text: "Not satisfying at all" },
  //         ],
  //         isRequired: true,
  //       },
  //       {
  //         name: "nps-score",
  //         title:
  //           "On a scale of zero to ten, how likely are you to recommend our product to a friend or colleague?",
  //         type: "rating",
  //         rateMin: 0,
  //         rateMax: 10,
  //       },
  //     ],
  //     showQuestionNumbers: "off",
  //     completedHtml: "Thank you for your feedback!",
  //   };

  const vizPanelOptions = {
    allowHideQuestions: false,
  };

  //   const surveyResults = [
  //     {
  //       "satisfaction-score": 5,
  //       "nps-score": 10,
  //     },
  //     {
  //       "satisfaction-score": 5,
  //       "nps-score": 9,
  //     },
  //     {
  //       "satisfaction-score": 3,
  //       "nps-score": 6,
  //     },
  //     {
  //       "satisfaction-score": 3,
  //       "nps-score": 6,
  //     },
  //     {
  //       "satisfaction-score": 2,
  //       "nps-score": 3,
  //     },
  //   ];

  if (!survey && formData.data) {
    const fieldData = formData.data?.fieldData;
    fieldData.pages.push({
      name: "Info",
      elements: [
        { type: "text", name: "contactEmail", title: "Email" },
        { type: "text", name: "created_at", title: "Created At" },
        { type: "text", name: "updated_at", title: "Updated At" },
        { type: "text", name: "id", title: "ID" },
        { type: "text", name: "status", title: "Status" },
      ],
    });
    const survey = new Model(fieldData);
    setSurvey(survey);
  }

  if (!vizPanel && !!survey && campaignResponses.data) {
    // const vizPanel = new VisualizationPanel(
    //   survey.getAllQuestions(),
    //   campaignResponses.data,
    //   vizPanelOptions
    // );
    // vizPanel.showHeader = false;
    let subData = campaignResponses.data.map((sub) => {
      const subInfo = sub.submission_data;
      subInfo.contactEmail = sub.name;
      subInfo.status = sub.status;
      subInfo.created_at = sub.created_at;
      subInfo.updated_at = sub.updated_at;
      subInfo.id = sub.id;
      return subInfo;
    });
    const vizPanel = new SurveyAnalyticsTabulator.Tabulator(survey, subData);
    vizPanel.haveCommercialLicense = true;
    setVizPanel(vizPanel);
  }

  return (
    <Fragment>
      <Card>
        <Button
          color="blue"
          text="Back to Campaign Control"
          goto={`/campaign/${props.computedMatch.params.id}/view`}
        />
      </Card>
      <Card loading={campaignResponses?.loading}>
        <div id="surveyVizPanel" />
      </Card>
    </Fragment>
  );
}
