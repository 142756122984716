/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/

import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Card,
  Stat,
  Chart,
  Table,
  Message,
  Grid,
  Animate,
  Feedback,
  History,
  useAPI,
  FormSelect,
  Form,
  Footer,
} from "components/lib";
import Axios from "axios";

export function Dashboard(props) {
  let progress = useAPI("/api/demo/progress");
  const campaigns = useAPI("/api/campaign");
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignStats, setCampaignStats] = useState(null);

  const getCampaignStats = async (selectedCampaign) => {
    if (!selectedCampaign) {
      return;
    }
    const stats = await Axios.get(
      `api/submission-data/stats/${selectedCampaign ? selectedCampaign : ""}`
    );
    console.log("stats", stats);
    setCampaignStats(stats.data);
    return stats;
  };

  useEffect(() => {
    if (
      campaigns?.loading === false &&
      campaigns?.data !== null &&
      campaigns?.data.length > 0
    ) {
      setSelectedCampaign(campaigns?.data[0].id);
      const reponse = (async () => await getCampaignStats(selectedCampaign))();
      console.log("response", reponse);
    } else {
      setSelectedCampaign(null);
    }
  }, [campaigns]);

  const handleChange = (e) => {
    console.log("in handle change", e.target.value);
    setSelectedCampaign(e.target.value);
    getCampaignStats(e.target.value);
  };

  const goToCampaign = (data) => {
    console.log("data", data);
    if (data.status === "LIVE" || data.status === "DRAFT" || data.status === "EXPIRED") {
      History.push(`/campaign/${data.id}/view`);
      return;
    }
    History.push("/wizard/form/" + data.id);
  };

  console.log("selectedCampaign", selectedCampaign);

  const submissionStats = useAPI("/api/submission/stats");
  const submissionChart = {
    data: {
      labels: ["CREATED", "IN PROGRESS", "SUBMITTED"],
      datasets: [
        {
          label: "SUBMITTED",
          data: [
            campaignStats?.data?.created,
            campaignStats?.data?.inProgress,
            campaignStats?.data?.submitted,
          ],
        },
      ],
    },
  };
  return (
    <Animate type="pop">
      <Message
        title="Welcome to Submit Hero!"
        text="This is a your dashboard to give you a birds eye view of all your campaigns and submissions."
        type="info"
      />

      <Grid cols="4">
        <Stat
          loading={campaigns?.loading}
          value={campaigns?.data?.length}
          label="Campaigns"
          icon="award"
        />
        <Stat
          loading={submissionStats?.loading}
          value={submissionStats?.data?.created}
          label="Created"
          icon="file-plus"
        />
        <Stat
          loading={submissionStats?.loading}
          value={submissionStats?.data?.inProgress}
          label="In Progress"
          icon="refresh-cw"
        />
        <Stat
          loading={submissionStats?.loading}
          value={submissionStats?.data?.submitted}
          label="Submitted"
          icon="check"
          // change="5%"
        />
      </Grid>

      <Card name="submissions" title="Submissions">
        <Chart
          type="line"
          legend
          loading={submissionStats?.loading}
          data={submissionStats?.data?.chartData}
          color={["red", "blue", "green"]}
        />
      </Card>
      {selectedCampaign ? (
        <>
          <Grid cols="2">
            <Card title="Campaign" restrictWidth loading={progress.loading}>
              {!campaigns?.loading && (
                <Fragment>
                  {/* <Selector loading={campaigns?.loading} value="x" /> */}
                  <FormSelect
                    onChange={handleChange}
                    label="Select a Campaign to begin"
                    default={selectedCampaign}
                    options={campaigns?.data?.map((campaign) => ({
                      value: campaign.id,
                      label: campaign.name,
                    }))}
                  ></FormSelect>
                  {/* <p>Stats for = {campaigns}</p> */}
                  {/* <p>Created: {campaignStats?.data?.created}</p>
                  <p>In Progress: {campaignStats?.data?.inProgress}</p>
                  <p>Submitted: {campaignStats?.data?.submitted}</p> */}
                  <Form
                    data={{
                      name: {
                        label: "Start your campaign",
                        type: "text",
                        placeholder: "Campaign Name",
                        required: true,
                        errorMessage: "Please enter a campaign name",
                      },
                    }}
                    url="/api/campaign"
                    buttonText="Create"
                    method="post"
                    callback={(res) => {
                      console.log(res.data);
                      History.push(`/wizard/form/${res.data.data.id}`);
                    }}
                  />
                </Fragment>
              )}
            </Card>

            <Card title="Submission Stats">
              <Chart
                type="bar"
                legend={true}
                data={submissionChart?.data}
                color={["red", "green", "blue"]}
              />
            </Card>
          </Grid>
        </>
      ) : (
        <p>No Campaigns Found.</p>
      )}

      <Card title="Campaigns">
        <Table
          search
          data={campaigns?.data}
          show={["name", "status", "created_at", "updated_at"]}
          loading={campaigns?.loading}
          badge={{ col: "status", color: "red" }}
          actions={{
            custom: [
              {
                icon: "eye",
                action: goToCampaign,
              },
            ],
          }}
          sort={true}
        ></Table>
      </Card>

      <Feedback />
      <Footer />
    </Animate>
  );
}
