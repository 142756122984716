import React, { Fragment, useContext, useCallback } from "react";
import { Card, AuthContext, useAPI } from "components/lib";
import { StylesManager, Model, FunctionFactory } from "survey-core";
import * as SurveyCore from "survey-core";
import { Survey } from "survey-react-ui";
import campaignWizardJson from "./campaignWizardJson";
import $ from "jquery";
import * as widgets from "surveyjs-widgets";
import "survey-core/defaultV2.css";
window["$"] = window["jQuery"] = $;

StylesManager.applyTheme("defaultV2");
widgets.ckeditor(SurveyCore);

const defaultImageFunction = (params) => {
  if (!params[0]) {
    return "https://storage.googleapis.com/submithero-images/SVG/SubmitHero_Logo_RGB_OnRed.svg";
  }
  const welcomeBanner = params[0];
  return welcomeBanner[0].content;
};

const defaultLandingImageFunction = (params) => {
  if (!params[0]) {
    return "https://storage.googleapis.com/submithero-images/SVG/SubmitHero_Logo_RGB_Red.svg";
  }
  const landingPageImage = params[0];
  return landingPageImage[0].content;
};

const showThanksEmailButtonFunction = (params) => {
  if (params[0]) {
    return `<button class="button">${params[1]}</button>`;
  } else return "<p><p>";
};

FunctionFactory.Instance.register("defaultImageFunction", defaultImageFunction);
FunctionFactory.Instance.register(
  "defaultLandingImageFunction",
  defaultLandingImageFunction
);

FunctionFactory.Instance.register(
  "showThanksEmailButtonFunction",
  showThanksEmailButtonFunction
);

export function CampaignWizard(props) {
  // get existing campaign data
  const campaignId = props.computedMatch.params.campaignId;
  const env = process.env.NODE_ENV;
  const navigateToUrl =
    env === "production"
      ? `https://www.submithero.io/campaign/${campaignId}/view`
      : `http://localhost:3000/campaign/${campaignId}/view`;

  const context = useContext(AuthContext);

  campaignWizardJson.navigateToUrl = navigateToUrl;
  const campaign = useAPI("/api/campaign/" + campaignId);

  // if (campaign?.data?.[0].status === "LIVE") {
  //   campaignWizardJson.mode = "display;
  // }

  function saveSurveyResults(url, json) {
    const campaignSettingsJson = {
      status: json.status ? "LIVE" : "DRAFT",
      start_date: json.startDate,
      end_date: json.endDate,
      name: json.name,
      info: { ...json },
    };
    const request = new XMLHttpRequest();
    request.open("PATCH", url);
    request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    request.setRequestHeader("Authorization", `Bearer ${context.user?.token}`);
    request.addEventListener("load", () => {
      // Handle "load"
    });
    request.addEventListener("error", () => {
      // Handle "error"
    });
    request.send(JSON.stringify(campaignSettingsJson));
  }

  const uploadFiles = useCallback((sender, options) => {
    // console.log("sender", sender);
    console.log("options", options);
    var formData = new FormData();
    options.files.forEach(function (file) {
      formData.append("file", file);
      formData.append("campaignId", campaignId);
      formData.append("questionName", options.name);
    });
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/api/utility/gcp/upload/campaign");
    xhr.setRequestHeader("Authorization", `Bearer ${context.user?.token}`);
    xhr.onload = function () {
      var data = JSON.parse(xhr.responseText);
      // console.log("data", data.url);
      options.callback(
        "success",
        options.files.map(function (file) {
          // console.log("file", file);
          return {
            file: file,
            content: data.url,
          };
        })
      );
    };
    console.log("form data", formData);
    xhr.send(formData);
  });

  const campaignWizardComplete = useCallback((sender) => {
    saveSurveyResults("/api/campaign/" + campaignId, sender.data);
  }, []);

  const survey = new Model(campaignWizardJson);
  const data = campaign?.data?.[0];

  if (data) {
    data.info.name = campaign?.data?.[0].name;
  }

  if (data?.status !== "CREATED") {
    survey.data = data?.info;
  } else {
    survey.setValue("name", data?.name);
  }

  survey.onComplete.add(campaignWizardComplete);
  survey.onUploadFiles.add(uploadFiles);

  return (
    <Fragment>
      <Card loading={campaign?.loading}>
        {!campaign?.loading && <Survey model={survey} />}
      </Card>
    </Fragment>
  );
}
