/***
 *
 *   PUBLIC CAMPAIGN FORM
 *   Public campaign form
 *
 **********/

import React from "react";
import { Row, Form, Card, Article, Image } from "components/lib";
import qs from "querystring";
import SGLogo from "./images/stackgo-logo-1.png.webp";

export function DemoCampaign(props) {
  const locations = props.location.search.substring(1);
  const queryParams = qs.parse(locations);

  return (
    <Row title="StackGo Demo Application">
      <Card>
        <Image source={SGLogo} width={250} height={600} />
        <Article>
          <p>
            Thanks for applying to the DEMO tech startup challenge. <br />
            <br />
            Please allow 30-40mins to fully complete your online application,
            but you can save your partially-completed application and come back
            to it later. If you have any problems or questions please contact
            <a href="mailto:team@stackgo.io"> team@stackgo.io</a>
            <br />
            <br />
            <p>To start or continue, please enter your email below.</p>
          </p>
        </Article>
      </Card>
      <Card restrictWidth center>
        <Form
          data={{
            email: {
              label: "Your Email",
              type: "email",
              required: true,
              errorMessage: "Please enter your email",
            },
            campaign_id: {
              label: "campaign id",
              type: "hidden",
              value: queryParams.id,
            },
          }}
          method="POST"
          url="/api/demo/submission"
          redirect="/get-started/demo"
          buttonText="Start!"
        />
      </Card>
    </Row>
  );
}
