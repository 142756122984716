import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useLayoutEffect,
} from "react";
import {
  Row,
  Card,
  Form,
  useAPI,
  Animate,
  Button,
  ViewContext,
  Image,
  Article,
} from "components/lib";
import qs from "querystring";
import axios from "axios";
import SGLogo from "./images/stackgo-logo-1.png.webp";

export function DemoForm2(props) {
  const locations = props.location.search.substring(1);
  const queryParams = qs.parse(locations);
  const savedFormData = useAPI(`/api/submission/${queryParams.submission_id}`);
  const context = useContext(ViewContext);
  const [formValues, setFormValues] = useState({});
  const [teamLeadAffiliation, setTeamLeadAffiliation] = useState("");
  const [teamMemberAffiliation2, setTeamMemberAffiliation2] = useState("");
  const [teamMemberAffiliation3, setTeamMemberAffiliation3] = useState("");
  const [teamMemberAffiliation4, setTeamMemberAffiliation4] = useState("");
  const [teamMemberAffiliation5, setTeamMemberAffiliation5] = useState("");
  const [noOfTeamMembers, setNoOfTeamMembers] = useState(0);
  const [showOtherHeard, setShowOtherHeard] = useState(false);
  const [showOtherSector, setShowOtherSector] = useState(false);

  const save = (submission_id) => {
    console.log("axios.post()", submission_id);
  };

  const scrollX = window.scrollX;
  const scrollY = window.scrollY;

  useLayoutEffect(() => {
    window.scrollTo(scrollX, scrollY);
  });

  const aboutYouForm = {
    sectionHeader: {
      title: "About You",
      type: "header",
    },
    teamName: {
      label: "Startup or Team Name",
      type: "text",
      required: true,
      value: savedFormData?.data?.submission_data?.teamName,
      errorMessage: "Please enter your team name",
      hint: "",
    },
    noOfTeam: {
      label:
        "How many people are currently working on this startup/project (including yourself)?",
      type: "select",
      options: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5+" },
      ],
      required: true,
      default: savedFormData?.data?.submission_data?.noOfTeam,
      errorMessage: "Please select how many people on your team",
      // maxlength: 4,
    },
    teamLeadFirstName: {
      label: "Team Lead First Name",
      type: "text",
      required: true,
      value: savedFormData?.data?.submission_data?.teamLeadFirstName,
      errorMessage: "Please enter the team lead's first name",
    },
    teamLeadLastName: {
      label: "Team Lead Last Name",
      type: "text",
      required: true,
      value: savedFormData?.data?.submission_data?.teamLeadLastName,
      errorMessage: "Please enter the team lead's last name",
    },
    teamLeadEmailAddress: {
      label: "Email Address",
      type: "email",
      required: true,
      value: savedFormData?.data?.submission_data?.teamLeadEmailAddress,
      errorMessage: "Please enter the team lead's email address",
    },
    teamLeadMobile: {
      label: "Team Lead's Mobile Number",
      type: "text",
      required: true,
      value: savedFormData?.data?.submission_data?.teamLeadMobile,
      errorMessage: "Please enter the team lead's mobile number",
    },
    teamLeadGender: {
      label: "Team Lead's Gender",
      type: "radio",
      default: savedFormData?.data?.submission_data?.teamLeadGender,
      errorMessage: "Please select the Team Lead's gender",
      options: [
        "Female",
        "Male",
        "Non-binary/gender diverse",
        "Prefer not to answer",
      ],
    },
    teamLeadAboriginal: {
      label:
        "Team Lead - Are you of Aboriginal or Torres Strait Islander origin?",
      type: "radio",
      options: ["Yes", "No", "Prefer not to say"],
      default: savedFormData?.data?.submission_data?.teamLeadAboriginal,
      required: true,
      errorMessage: "Please make a selection",
    },
    teamLeadLocation: {
      label: "Where are you and your team currently located? (City/Country)",
      required: true,
      type: "text",
      value: savedFormData?.data?.submission_data?.teamLeadLocation,
      errorMessage: "Please enter your location here",
    },
    teamLeadMailingList: {
      label:
        "I would like to be added to the Generator's email mailing list and agree to receive emails from the Generator about their programs and events (you can opt-out at any time).",
      type: "radio",
      options: ["Yes please", "No thanks", "I'm already on the list"],
      default: savedFormData?.data?.submission_data?.teamLeadMailingList,
      required: true,
      errorMessage: "Please make a selection",
    },
    teamLeadAffiliation: {
      label: "Team lead - Affiliation to Demo University ?",
      type: "radio",
      options: ["Student", "Staff", "Alumni", "No Affiliation"],
      default: savedFormData?.data?.submission_data?.teamLeadAffiliation,
      required: true,
      errorMessage: "Please make a selection",
    },
    teamLeadStudentStatus: {
      label: "Team Lead - Student status",
      type: "radio",
      options: ["Domestic", "International"],
      default: savedFormData?.data?.submission_data?.teamLeadStudentStatus,
      required: teamLeadAffiliation === "Student",
      show: teamLeadAffiliation === "Student",
    },
    teamLeadMonashId: {
      label: "Team Lead - Demo ID",
      type: "text",
      value: savedFormData?.data?.submission_data?.teamLeadMonashId,
      required: teamLeadAffiliation === "Student",
      show: teamLeadAffiliation === "Student",
    },
    teamLeadDegreeUndertaken: {
      label: "Team lead - Degree undertaking/undertaken",
      type: "radio",
      options: [
        "Undergraduate",
        "Honours",
        "PG - Masters By Research",
        "PG - Masters by Coursework",
        "PhD",
      ],
      default: savedFormData?.data?.submission_data?.teamLeadDegreeUndertaken,
      required:
        teamLeadAffiliation === "Student" || teamLeadAffiliation === "Alumni",
      show:
        teamLeadAffiliation === "Student" || teamLeadAffiliation === "Alumni",
    },
    teamLeadFacultyOrPortfolio: {
      type: "select",
      label: "Team lead - Faculty/Portfolio affiliation",
      show:
        teamLeadAffiliation === "Student" ||
        teamLeadAffiliation === "Staff" ||
        teamLeadAffiliation === "Alumni",
      required:
        teamLeadAffiliation === "Student" ||
        teamLeadAffiliation === "Staff" ||
        teamLeadAffiliation === "Alumni",
      default: savedFormData?.data?.submission_data?.teamLeadFacultyOrPortfolio,
      options: [
        {
          value: "Art, Design & Architecture (MADA)",
          label: "Art, Design & Architecture (MADA)",
        },
        { value: "Arts", label: "Arts" },
        {
          value: "Business, Economics and Commerce",
          label: "Business, Economics and Commerce",
        },
        { value: "Education", label: "Education" },
        { value: "Engineering", label: "Engineering" },
        { value: "Information Technology", label: "Information Technology" },
        { value: "Law", label: "Law" },
        {
          value: "Medicine, Nursing & Health Science",
          label: "Medicine, Nursing & Health Science",
        },
        {
          value: "Pharmacy & Pharmaceutical Sciences",
          label: "Pharmacy & Pharmaceutical Sciences",
        },
        { value: "Science", label: "Science" },
        {
          value: "Office of the Vice-Chancellor",
          label: "Office of the Vice-Chancellor",
        },
        {
          value: "Office of the Provost and Senior Vice-President",
          label: "Office of the Provost and Senior Vice-President",
        },
        {
          value:
            "Office of the Deputy Vice-Chancellor and Vice-President (Education)",
          label:
            "Office of the Deputy Vice-Chancellor and Vice-President (Education)",
        },
        {
          value:
            "Office of the Deputy Vice-Chancellor and Vice-President (Enterprise and Governance)",
          label:
            "Office of the Deputy Vice-Chancellor and Vice-President (Enterprise and Governance)",
        },
        {
          value:
            "Office of the Deputy Vice-Chancellor and Vice-President (Global Engagement)",
          label:
            "Office of the Deputy Vice-Chancellor and Vice-President (Global Engagement)",
        },
        {
          value:
            "Office of the Chief Operating Officer and Senior Vice-President",
          label:
            "Office of the Chief Operating Officer and Senior Vice-President",
        },
        {
          value:
            "Office of the Chief Financial Officer and Senior Vice-President",
          label:
            "Office of the Chief Financial Officer and Senior Vice-President",
        },
        { value: "Other", label: "Other" },
      ],
    },
    teamLeadPrimaryCampus: {
      type: "select",
      label: "Team lead - Primary campus",
      show:
        teamLeadAffiliation === "Student" || teamLeadAffiliation === "Staff",
      required:
        teamLeadAffiliation === "Student" || teamLeadAffiliation === "Staff",
      default: savedFormData?.data?.submission_data?.teamLeadPrimaryCampus,
      options: [
        { label: "Clayton", value: "Clayton" },
        { label: "Caulfield", value: "Caulfield" },
        { label: "Peninsula", value: "Peninsula" },
        { label: "City", value: "City" },
        { label: "Demo Malaysia", value: "Demo Malaysia" },
        { label: "Other", value: "Other" },
      ],
    },
    teamLeadGraduationYear: {
      type: "text",
      label: "Team lead - Graduation year",
      show: teamLeadAffiliation === "Alumni",
      required: teamLeadAffiliation === "Alumni",
      value: savedFormData?.data?.submission_data?.teamLeadGraduationYear,
    },
    teamLeadStaffEmployment: {
      label: "Team Lead - Staff employment",
      type: "radio",
      show: teamLeadAffiliation === "Staff",
      required: teamLeadAffiliation === "Staff",
      default: savedFormData?.data?.submission_data?.teamLeadStaffEmployment,
      options: ["Professional staff", "Academic staff", "PostDoc"],
    },
  };

  const yourStartupForm = {
    aboutVenture: {
      title: "About Your Venture",
      type: "header",
    },
    teamName: {
      label: "Startup or Team Name",
      type: "text",
      required: true,
      value: savedFormData?.data?.submission_data?.teamName,
      errorMessage: "Please enter your team name",
    },
    companyWebsite: {
      label: "Company Website (if applicable)",
      type: "url",
      required: false,
      value: savedFormData?.data?.submission_data?.companyWebsite,
    },
    idea: {
      label: "Describe your venture in 2-3 sentences (e.g. elevator pitch)",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.idea,
      errorMessage: "Please describe your elevator pitch",
      maxlength: 300,
    },
    video: {
      label:
        "Please enter the url of a ONE MINUTE publicly accessible video introducing yourself and your cofounders",
      type: "url",
      required: true,
      value: savedFormData?.data?.submission_data?.video,
      errorMessage: "Please enter you intro video",
      hint: "Please ensure that the audio is clear and there is adequate lighting (we want to see and hear you!). The time limit is strict - your video will not be viewed past the 60-second mark.",
    },
    problem: {
      label: "What problem are you solving? (In 50 words or less)",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.problem,
      errorMessage: "Please describe the problem you are solving",
      maxlength: 300,
    },
    solution: {
      label: "Describe your solution (in 50 words or less)",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.solution,
      errorMessage: "Please describe the solution",
      maxlength: 300,
    },
    origin: {
      label: "How did you come about this idea?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.origin,
      maxlength: 2000,
    },
    interviews: {
      label:
        "How many one-on-one customer interviews have you conducted so far?",
      type: "number",
      required: true,
      value: savedFormData?.data?.submission_data?.interviews,
      errorMessage: "Please provide a number",
    },
    currentSolution: {
      label:
        "What substitutes do your customers resort to, i.e. without your solution?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.currentSolution,
      errorMessage:
        "Please describe the common way this problem is solved currently",
      maxlength: 2000,
    },
    unique: {
      label:
        "How is your solution unique/innovative/better than other solutions?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.unique,
      errorMessage: "Please describe how your solution is better",
      maxlength: 2000,
    },
    businessModel: {
      label: "What is your business model?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.businessModel,
      errorMessage: "Please enter your business model",
      maxlength: 2000,
    },
    traction: {
      hint: "For example: Yes - we launched a landing page 2 weeks ago explaining what we are going to do and are collecting email addresses to form a mailing list",
      label: "Describe the progress/traction you have achieved to date",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.traction,
      errorMessage: "Please describe your traction",
      maxlength: 2000,
    },
    rightTeam: {
      label: "Why are you the right team to solve this problem?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.rightTeam,
      errorMessage:
        "Please describe why you are the right team to solve this problem",
      maxlength: 2000,
    },
    whyApply: {
      label:
        "What motivates you? What do you care about? Why do you care about this problem?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.whyApply,
      errorMessage: "Please describe your motivation to apply",
      maxlength: 2000,
    },
    whatGain: {
      label: "What do you hope to gain by participating in the Accelerator?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.whatGain,
      errorMessage: "Please describe your you want to participate",
      maxlength: 2000,
    },
    hear: {
      label: "How did you hear about the Demo Generator Accelerator? ",
      type: "select",
      options: [
        { value: "Generator website", label: "Generator website" },
        { value: "Generator mailing list", label: "Generator mailing list" },
        { value: "Generator Facebook page", label: "Generator Facebook page" },
        {
          value: "Generator Instagram Page",
          label: "Generator Instagram Page",
        },
        { value: "Generator Twitter Page", label: "Generator Twitter Page" },
        {
          value: "University Communications",
          label: "University Communications",
        },
        { value: "A Demo student", label: "A Demo Student" },
        { value: "A Demo staff member", label: "A Demo staff member" },
        { value: "A Demo alumni", label: "A Demo alumni" },
        {
          value: "A friend or colleague encouraged me to apply",
          label: "A friend or colleague encouraged me to apply",
        },
        {
          value: "I've previously engaged with the Generator",
          label: "I've previously engaged with the Generator",
        },
        { value: "Other", label: "Other" },
      ],
      required: true,
      default: savedFormData?.data?.submission_data?.hear,
    },
    otherHear: {
      label: "Please share where did you hear of us?",
      type: "text",
      required: false,
      show: showOtherHeard,
      value: savedFormData?.data?.submission_data?.otherHear,
    },
    sector: {
      label: "What industry/market does your project sit within?",
      type: "select",
      options: [
        { value: "Aerospace", label: "Aerospace" },
        { value: "Agriculture", label: "Agriculture" },
        { value: "AI", label: "AI" },
        { value: "Analytics", label: "Analytics" },
        { value: "Biotech", label: "Biotech" },
        { value: "Blockchain/crypto", label: "Blockchain/crypto" },
        { value: "E-Commerce", label: "E-Commerce" },
        { value: "Education/EdTech", label: "Education/EdTech" },
        { value: "Energy", label: "Energy" },
        {
          value: "Environment/Sustainabilty",
          label: "Environment/Sustainabilty",
        },
        { value: "Fintech", label: "Fintech" },
        { value: "Food/Beverage", label: "Food/Beverage" },
        { value: "Gaming", label: "Gaming" },
        { value: "Hardware", label: "Hardware" },
        { value: "Health/Wellbeing", label: "Health/Wellbeing" },
        { value: "MedTech", label: "MedTech" },
        { value: "Mental Health", label: "Mental Health" },
        { value: "Retail", label: "Retail" },
        { value: "Robotics", label: "Robotics" },
        { value: "SaaS", label: "SaaS" },
        { value: "Small Business", label: "Small Business" },
        { value: "Social Entreprenuership", label: "Social Entreprenuership" },
        { value: "Student Life", label: "Student Life" },
        { value: "Other", label: "Other" },
      ],
      required: true,
      default: savedFormData?.data?.submission_data?.sector,
      errorMessage: "Please make a selection for the industry",
    },
    otherSector: {
      label: "Please share which industry sector your startup belongs to?",
      type: "text",
      required: false,
      show: showOtherSector,
      value: savedFormData?.data?.submission_data?.otherSector,
    },
    stage: {
      label: "What stage best describes your startup?",
      type: "select",
      options: [
        { value: "Research & Ideation", label: "Research & Ideation" },
        {
          value: "Prototype/Minimum Viable Product",
          label: "Prototype/Minimum Viable Product",
        },
        { value: "Taking pre orders", label: "Taking pre orders" },
        {
          value: "Launched, but no users yet",
          label: "Launched, but no users yet",
        },
        { value: "Launched with users", label: "Launched with users" },
        {
          value: "Launched with customers (<$10k)",
          label: "Launched with customers (<$10k)",
        },
        {
          value: "$10k-$100k revenue for the last 12 months",
          label: "$10k-$100k revenue for the last 12 months",
        },
        {
          value: ">$100k revenue in last 12 months",
          label: ">$100k revenue in last 12 months",
        },
      ],
      required: true,
      default: savedFormData?.data?.submission_data?.stage,
    },
    currentlyGeneratingRevenue: {
      label: "Are you currently generating revenue?",
      type: "radio",
      options: ["Yes", "No"],
      default: savedFormData?.data?.submission_data?.currentlyGeneratingRevenue,
      required: true,
      errorMessage: "Please make a selection",
    },
    previousInvolvement: {
      label: "What has your previous involvement with the Demo Generator been?",
      type: "checkbox",
      options: [
        "Events",
        "The Validator",
        "Startup Sprint",
        "Researcher to Innovator",
        "The Accelerator",
        "1:1 Office hours",
        "Other",
      ],
      value: savedFormData?.data?.submission_data?.previousInvolvement,
      default: savedFormData?.data?.submission_data?.previousInvolvement,
      required: true,
    },
    participation: {
      label:
        "Have you ever participated in an accelerator program? If so, which.",
      type: "text",
      value: savedFormData?.data?.submission_data?.participation,
      required: true,
      errorMessage:
        "Please let us know if you have ever particiapted in an accelerator program. NA otherwise",
    },
  };

  const formDataInit = {
    aboutVenture: {
      title: "About Your Venture",
      type: "header",
    },
    teamName: {
      label: "Startup or Team Name",
      type: "text",
      required: true,
      value: savedFormData?.data?.submission_data?.teamName,
      errorMessage: "Please enter your team name",
    },
    companyWebsite: {
      label: "Company Website (if applicable)",
      type: "url",
      required: false,
      value: savedFormData?.data?.submission_data?.companyWebsite,
    },
    idea: {
      label: "Describe your venture in 2-3 sentences (e.g. elevator pitch)",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.idea,
      errorMessage: "Please describe your elevator pitch",
      maxlength: 300,
    },
    video: {
      label:
        "Please enter the url of a ONE MINUTE publicly accessible video introducing yourself and your cofounders",
      type: "url",
      required: true,
      value: savedFormData?.data?.submission_data?.video,
      errorMessage: "Please enter you intro video",
      hint: "Please ensure that the audio is clear and there is adequate lighting (we want to see and hear you!). The time limit is strict - your video will not be viewed past the 60-second mark.",
    },
    problem: {
      label: "What problem are you solving? (In 50 words or less)",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.problem,
      errorMessage: "Please describe the problem you are solving",
      maxlength: 300,
    },
    solution: {
      label: "Describe your solution (in 50 words or less)",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.solution,
      errorMessage: "Please describe the solution",
      maxlength: 300,
    },
    origin: {
      label: "How did you come about this idea?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.origin,
      maxlength: 2000,
    },
    interviews: {
      label:
        "How many one-on-one customer interviews have you conducted so far?",
      type: "number",
      required: true,
      value: savedFormData?.data?.submission_data?.interviews,
      errorMessage: "Please provide a number",
    },
    currentSolution: {
      label:
        "What substitutes do your customers resort to, i.e. without your solution?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.currentSolution,
      errorMessage:
        "Please describe the common way this problem is solved currently",
      maxlength: 2000,
    },

    unique: {
      label:
        "How is your solution unique/innovative/better than other solutions?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.unique,
      errorMessage: "Please describe how your solution is better",
      maxlength: 2000,
    },

    businessModel: {
      label: "What is your business model?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.businessModel,
      errorMessage: "Please enter your business model",
      maxlength: 2000,
    },
    traction: {
      hint: "For example: Yes - we launched a landing page 2 weeks ago explaining what we are going to do and are collecting email addresses to form a mailing list",
      label: "Describe the progress/traction you have achieved to date",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.traction,
      errorMessage: "Please describe your traction",
      maxlength: 2000,
    },
    rightTeam: {
      label: "Why are you the right team to solve this problem?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.rightTeam,
      errorMessage:
        "Please describe why you are the right team to solve this problem",
      maxlength: 2000,
    },
    whyApply: {
      label:
        "What motivates you? What do you care about? Why do you care about this problem?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.whyApply,
      errorMessage: "Please describe your motivation to apply",
      maxlength: 2000,
    },
    whatGain: {
      label: "What do you hope to gain by participating in the Accelerator?",
      type: "textarea",
      required: true,
      value: savedFormData?.data?.submission_data?.whatGain,
      errorMessage: "Please describe your you want to participate",
      maxlength: 2000,
    },
    hear: {
      label: "How did you hear about the Demo Generator Accelerator? ",
      type: "select",
      options: [
        { value: "Generator website", label: "Generator website" },
        { value: "Generator mailing list", label: "Generator mailing list" },
        { value: "Generator Facebook page", label: "Generator Facebook page" },
        {
          value: "Generator Instagram Page",
          label: "Generator Instagram Page",
        },
        { value: "Generator Twitter Page", label: "Generator Twitter Page" },
        {
          value: "University Communications",
          label: "University Communications",
        },
        { value: "A Demo student", label: "A Demo Student" },
        { value: "A Demo staff member", label: "A Demo staff member" },
        { value: "A Demo alumni", label: "A Demo alumni" },
        {
          value: "A friend or colleague encouraged me to apply",
          label: "A friend or colleague encouraged me to apply",
        },
        {
          value: "I've previously engaged with the Generator",
          label: "I've previously engaged with the Generator",
        },
        { value: "Other", label: "Other" },
      ],
      required: true,
      default: savedFormData?.data?.submission_data?.hear,
    },
    otherHear: {
      label: "Please share where did you hear of us?",
      type: "text",
      required: false,
      show: showOtherHeard,
      value: savedFormData?.data?.submission_data?.otherHear,
    },
    sector: {
      label: "What industry/market does your project sit within?",
      type: "select",
      options: [
        { value: "Aerospace", label: "Aerospace" },
        { value: "Agriculture", label: "Agriculture" },
        { value: "AI", label: "AI" },
        { value: "Analytics", label: "Analytics" },
        { value: "Biotech", label: "Biotech" },
        { value: "Blockchain/crypto", label: "Blockchain/crypto" },
        { value: "E-Commerce", label: "E-Commerce" },
        { value: "Education/EdTech", label: "Education/EdTech" },
        { value: "Energy", label: "Energy" },
        {
          value: "Environment/Sustainabilty",
          label: "Environment/Sustainabilty",
        },
        { value: "Fintech", label: "Fintech" },
        { value: "Food/Beverage", label: "Food/Beverage" },
        { value: "Gaming", label: "Gaming" },
        { value: "Hardware", label: "Hardware" },
        { value: "Health/Wellbeing", label: "Health/Wellbeing" },
        { value: "MedTech", label: "MedTech" },
        { value: "Mental Health", label: "Mental Health" },
        { value: "Retail", label: "Retail" },
        { value: "Robotics", label: "Robotics" },
        { value: "SaaS", label: "SaaS" },
        { value: "Small Business", label: "Small Business" },
        { value: "Social Entreprenuership", label: "Social Entreprenuership" },
        { value: "Student Life", label: "Student Life" },
        { value: "Other", label: "Other" },
      ],
      required: true,
      default: savedFormData?.data?.submission_data?.sector,
      errorMessage: "Please make a selection for the industry",
    },
    otherSector: {
      label: "Please share which sector does your stastup belong to?",
      type: "text",
      required: false,
      show: showOtherSector,
      value: savedFormData?.data?.submission_data?.otherSector,
    },
    stage: {
      label: "What stage best describes your startup?",
      type: "select",
      options: [
        { value: "Research & Ideation", label: "Research & Ideation" },
        {
          value: "Prototype/Minimum Viable Product",
          label: "Prototype/Minimum Viable Product",
        },
        { value: "Taking pre orders", label: "Taking pre orders" },
        {
          value: "Launched, but no users yet",
          label: "Launched, but no users yet",
        },
        { value: "Launched with users", label: "Launched with users" },
        {
          value: "Launched with customers (<$10k)",
          label: "Launched with customers (<$10k)",
        },
        {
          value: "$10k-$100k revenue for the last 12 months",
          label: "$10k-$100k revenue for the last 12 months",
        },
        {
          value: ">$100k revenue in last 12 months",
          label: ">$100k revenue in last 12 months",
        },
      ],
      required: true,
      default: savedFormData?.data?.submission_data?.stage,
    },
    currentlyGeneratingRevenue: {
      label: "Are you currently generating revenue?",
      type: "radio",
      options: ["Yes", "No"],
      default: savedFormData?.data?.submission_data?.currentlyGeneratingRevenue,
      required: true,
      errorMessage: "Please make a selection",
    },
    previousInvolvement: {
      label: "What has your previous involvement with the Demo Generator been?",
      type: "checkbox",
      options: [
        "Events",
        "The Validator",
        "Startup Sprint",
        "Researcher to Innovator",
        "The Accelerator",
        "1:1 Office hours",
        "Other",
      ],
      value: savedFormData?.data?.submission_data?.previousInvolvement,
      default: savedFormData?.data?.submission_data?.previousInvolvement,
      required: true,
    },
    participation: {
      label:
        "Have you ever participated in an accelerator program? If so, which.",
      type: "text",
      default: savedFormData?.data?.submission_data?.participation,
      required: true,
      errorMessage:
        "Please let us know if you have ever particiapted in an accerlator program. NA otherwise",
    },
    // howLong: {
    //   label: "How long have you been working on this project?",
    //   type: "textarea",
    //   required: true,
    //   value: savedFormData?.data?.submission_data?.howLong,
    //   errorMessage:
    //     "Please share how long you hace been working on this project",
    // },
    // users: {
    //   hint: "If you have launched a mailing list, let us know how many people are currently on this.",
    //   label: "Do you currently have any users or customers? If so, how many?",
    //   type: "textarea",
    //   required: true,
    //   value: savedFormData?.data?.submission_data?.users,
    //   errorMessage: "Please share how many users",
    // },
    // whyApply: {
    //   hint: "In 250 words or less, let us know why you want to take part in the Startup Sprint, what type of help you are hoping to receive and what you hope to achieve by the end of the five weeks.",
    //   label:
    //     "Why are you applying to the Generator's Startup Sprint program? What do you hope to get out of the program?",
    //   type: "textarea",
    //   required: true,
    //   value: savedFormData?.data?.submission_data?.whyApply,
    //   errorMessage: "Please describe your motivation to apply",
    // },
    // areasOfHelp: {
    //   label: "What area(s) do you need the most help in right now?",
    //   type: "checkbox",
    //   options: [
    //     "Marketing",
    //     "Investment",
    //     "Company Structuring",
    //     "Product Development",
    //     "Coaching/Guidance",
    //     "Growth/Scaling",
    //     "Other",
    //   ],
    //   value: savedFormData?.data?.submission_data?.areasOfHelp,
    //   default: savedFormData?.data?.submission_data?.areasOfHelp,
    //   required: true,
    // },
    // Contact Details
    sectionHeader: {
      title: "About You",
      type: "header",
    },
    noOfTeam: {
      label:
        "How many people are currently working on this startup/project (including yourself)?",
      type: "select",
      options: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5+" },
      ],
      required: true,
      default: savedFormData?.data?.submission_data?.noOfTeam,
      errorMessage: "Please select how many people on your team",
      // maxlength: 4,
    },
    teamLeadFirstName: {
      label: "Team Lead First Name",
      type: "text",
      required: true,
      value: savedFormData?.data?.submission_data?.teamLeadFirstName,
      errorMessage: "Please enter the team lead's first name",
    },
    teamLeadLastName: {
      label: "Team Lead Last Name",
      type: "text",
      required: true,
      value: savedFormData?.data?.submission_data?.teamLeadLastName,
      errorMessage: "Please enter the team lead's last name",
    },
    teamLeadEmailAddress: {
      label: "Email Address",
      type: "email",
      required: true,
      value: savedFormData?.data?.submission_data?.teamLeadEmailAddress,
      errorMessage: "Please enter the team lead's email address",
    },
    teamLeadMobile: {
      label: "Team Lead's Mobile Number",
      type: "text",
      required: true,
      value: savedFormData?.data?.submission_data?.teamLeadMobile,
      errorMessage: "Please enter the team lead's mobile number",
    },
    teamLeadGender: {
      label: "Team Lead's Gender",
      type: "radio",
      default: savedFormData?.data?.submission_data?.teamLeadGender,
      errorMessage: "Please select the Team Lead's gender",
      options: [
        "Female",
        "Male",
        "Non-binary/gender diverse",
        "Prefer not to answer",
      ],
    },
    teamLeadAboriginal: {
      label:
        "Team Lead - Are you of Aboriginal or Torres Strait Islander origin?",
      type: "radio",
      options: ["Yes", "No", "Prefer not to say"],
      default: savedFormData?.data?.submission_data?.teamLeadAboriginal,
      required: true,
      errorMessage: "Please make a selection",
    },
    teamLeadLocation: {
      label: "Where are you and your team currently located? (City/Country)",
      required: true,
      type: "text",
      value: savedFormData?.data?.submission_data?.teamLeadLocation,
      errorMessage: "Please enter your location here",
    },
    teamLeadMailingList: {
      label:
        "I would like to be added to the Generator's email mailing list and agree to receive emails from the Generator about their programs and events (you can opt-out at any time).",
      type: "radio",
      options: ["Yes please", "No thanks", "I'm already on the list"],
      default: savedFormData?.data?.submission_data?.teamLeadMailingList,
      required: true,
      errorMessage: "Please make a selection",
    },
    teamLeadAffiliation: {
      label: "Team lead - Affiliation to Demo University ?",
      type: "radio",
      options: ["Student", "Staff", "Alumni", "No Affiliation"],
      default: savedFormData?.data?.submission_data?.teamLeadAffiliation,
      required: true,
      errorMessage: "Please make a selection",
    },
    teamLeadStudentStatus: {
      label: "Team Lead - Student status",
      type: "radio",
      options: ["Domestic", "International"],
      default: savedFormData?.data?.submission_data?.teamLeadStudentStatus,
      required: teamLeadAffiliation === "Student",
      show: teamLeadAffiliation === "Student",
    },
    teamLeadMonashId: {
      label: "Team Lead - Demo ID",
      type: "number",
      value: savedFormData?.data?.submission_data?.teamLeadMonashId,
      required: teamLeadAffiliation === "Student",
      show: teamLeadAffiliation === "Student",
    },
    teamLeadDegreeUndertaken: {
      label: "Team lead - Degree undertaking/undertaken",
      type: "radio",
      options: [
        "Undergraduate",
        "Honours",
        "PG - Masters By Research",
        "PG - Masters by Coursework",
        "PhD",
      ],
      default: savedFormData?.data?.submission_data?.teamLeadDegreeUndertaken,
      required:
        teamLeadAffiliation === "Student" || teamLeadAffiliation === "Alumni",
      show:
        teamLeadAffiliation === "Student" || teamLeadAffiliation === "Alumni",
    },
    teamLeadFacultyOrPortfolio: {
      type: "select",
      label: "Team lead - Faculty/Portfolio affiliation",
      show:
        teamLeadAffiliation === "Student" ||
        teamLeadAffiliation === "Staff" ||
        teamLeadAffiliation === "Alumni",
      required:
        teamLeadAffiliation === "Student" ||
        teamLeadAffiliation === "Staff" ||
        teamLeadAffiliation === "Alumni",
      default: savedFormData?.data?.submission_data?.teamLeadFacultyOrPortfolio,
      options: [
        {
          value: "Art, Design & Architecture (MADA)",
          label: "Art, Design & Architecture (MADA)",
        },
        { value: "Arts", label: "Arts" },
        {
          value: "Business, Economics and Commerce",
          label: "Business, Economics and Commerce",
        },
        { value: "Education", label: "Education" },
        { value: "Engineering", label: "Engineering" },
        { value: "Information Technology", label: "Information Technology" },
        { value: "Law", label: "Law" },
        {
          value: "Medicine, Nursing & Health Science",
          label: "Medicine, Nursing & Health Science",
        },
        {
          value: "Pharmacy & Pharmaceutical Sciences",
          label: "Pharmacy & Pharmaceutical Sciences",
        },
        { value: "Science", label: "Science" },
        {
          value: "Office of the Vice-Chancellor",
          label: "Office of the Vice-Chancellor",
        },
        {
          value: "Office of the Provost and Senior Vice-President",
          label: "Office of the Provost and Senior Vice-President",
        },
        {
          value:
            "Office of the Deputy Vice-Chancellor and Vice-President (Education)",
          label:
            "Office of the Deputy Vice-Chancellor and Vice-President (Education)",
        },
        {
          value:
            "Office of the Deputy Vice-Chancellor and Vice-President (Enterprise and Governance)",
          label:
            "Office of the Deputy Vice-Chancellor and Vice-President (Enterprise and Governance)",
        },
        {
          value:
            "Office of the Deputy Vice-Chancellor and Vice-President (Global Engagement)",
          label:
            "Office of the Deputy Vice-Chancellor and Vice-President (Global Engagement)",
        },
        {
          value:
            "Office of the Chief Operating Officer and Senior Vice-President",
          label:
            "Office of the Chief Operating Officer and Senior Vice-President",
        },
        {
          value:
            "Office of the Chief Financial Officer and Senior Vice-President",
          label:
            "Office of the Chief Financial Officer and Senior Vice-President",
        },
        { value: "Other", label: "Other" },
      ],
    },
    teamLeadPrimaryCampus: {
      type: "select",
      label: "Team lead - Primary campus",
      show:
        teamLeadAffiliation === "Student" || teamLeadAffiliation === "Staff",
      required:
        teamLeadAffiliation === "Student" || teamLeadAffiliation === "Staff",
      default: savedFormData?.data?.submission_data?.teamLeadPrimaryCampus,
      options: [
        { label: "Clayton", value: "Clayton" },
        { label: "Caulfield", value: "Caulfield" },
        { label: "Peninsula", value: "Peninsula" },
        { label: "City", value: "City" },
        { label: "Demo Malaysia", value: "Demo Malaysia" },
        { label: "Other", value: "Other" },
      ],
    },
    teamLeadGraduationYear: {
      type: "text",
      label: "Team lead - Graduation year",
      show: teamLeadAffiliation === "Alumni",
      required: teamLeadAffiliation === "Alumni",
      value: savedFormData?.data?.submission_data?.teamLeadGraduationYear,
    },
    teamLeadStaffEmployment: {
      label: "Team Lead - Staff employment",
      type: "radio",
      show: teamLeadAffiliation === "Staff",
      required: teamLeadAffiliation === "Staff",
      default: savedFormData?.data?.submission_data?.teamLeadStaffEmployment,
      options: ["Professional staff", "Academic staff", "PostDoc"],
    },
  };

  let formData = formDataInit;

  const addTeamMembers = async (noOfT) => {
    let newFormData = {};
    newFormData.teamMemmberInfoHeader = {
      title: "Team Member Information",
      type: "header",
    };
    console.log("in add team", formValues);
    const aff = {
      2: teamMemberAffiliation2,
      3: teamMemberAffiliation3,
      4: teamMemberAffiliation4,
      5: teamMemberAffiliation5,
    };

    for (let i = 2; i <= noOfT; i++) {
      newFormData[`teamMemberFirstName${i}`] = {
        label: `Team Member ${i} First Name`,
        type: "text",
        required: true,
        value: formValues?.data?.submission_data?.[`teamMemberFirstName${i}`],
        errorMessage: "Please enter the team Member's first name",
      };
      newFormData[`teamMemberLastName${i}`] = {
        label: `Team Member ${i} Last Name`,
        type: "text",
        required: true,
        value: savedFormData?.data?.submission_data?.[`teamMemberLastName${i}`],
        errorMessage: "Please enter the team Member's last name",
      };
      newFormData[`teamMemberEmailAddress${i}`] = {
        label: `Team Member ${i} Email Address`,
        type: "email",
        required: true,
        value:
          savedFormData?.data?.submission_data?.[`teamMemberEmailAddress${i}`],
        errorMessage: "Please enter the team Member's email address",
      };
      newFormData[`teamMemberMobile${i}`] = {
        label: `Team Member ${i} Mobile Number`,
        type: "text",
        required: true,
        value: savedFormData?.data?.submission_data?.[`teamMemberMobile${i}`],
        errorMessage: "Please enter the team Member's mobile number",
      };
      newFormData[`teamMemberGender${i}`] = {
        label: `Team Member ${i} Gender`,
        type: "radio",
        required: true,
        default: savedFormData?.data?.submission_data?.[`teamMemberGender${i}`],
        errorMessage: "Please select the Team Member's gender",
        options: [
          "Female",
          "Male",
          "Non-binary/gender diverse",
          "Prefer not to answer",
        ],
      };
      newFormData[`teamMemberAboriginal${i}`] = {
        label: `Team Member ${i} - Are you of Aboriginal or Torres Strait Islander origin?`,
        type: "radio",
        options: ["Yes", "No", "Prefer not to say"],
        default:
          savedFormData?.data?.submission_data?.[`teamMemberAboriginal${i}`],
        required: true,
        errorMessage: "Please make a selection",
      };
      newFormData[`teamMemberLocation${i}`] = {
        label: "Where are you and your team currently located? (City/Country)",
        required: true,
        type: "text",
        value: savedFormData?.data?.submission_data?.[`teamMemberLocation${i}`],
        errorMessage: "Please enter your location here",
      };
      newFormData[`teamMemberMailingList${i}`] = {
        label:
          "I would like to be added to the Generator's email mailing list and agree to receive emails from the Generator about their programs and events (you can opt-out at any time).",
        type: "radio",
        options: ["Yes please", "No thanks", "I'm already on the list"],
        default:
          savedFormData?.data?.submission_data?.[`teamMemberMailingList${i}`],
        required: true,
        errorMessage: "Please make a selection",
      };
      newFormData[`teamMemberAffiliation${i}`] = {
        label: `Team Member ${i} - Affiliation to Demo University ?`,
        type: "radio",
        options: ["Student", "Staff", "Alumni", "No Affiliation"],
        default:
          savedFormData?.data?.submission_data?.[`teamMemberAffiliation${i}`],
        required: true,
        errorMessage: "Please make a selection",
      };
      console.log("aff", aff);
      console.log("is aff student", aff[i]);
      newFormData[`teamMemberStudentStatus${i}`] = {
        label: `Team Member ${i} - Student status`,
        type: "radio",
        options: ["Domestic", "International"],
        default:
          savedFormData?.data?.submission_data?.[`teamMemberStudentStatus${i}`],
        required: aff[i] === "Student",
        show: aff[i] === "Student",
      };
      newFormData[`teamMemberFacultyOrPortfolio${i}`] = {
        type: "select",
        label: `Team Member ${i} - Faculty/Portfolio affiliation`,
        show: aff[i] === "Student" || aff[i] === "Staff" || aff[i] === "Alumni",
        required:
          aff[i] === "Student" || aff[i] === "Staff" || aff[i] === "Alumni",
        default:
          savedFormData?.data?.submission_data?.[
            `teamMemberFacultyOrPortfolio${i}`
          ],
        options: [
          {
            value: "Art, Design & Architecture (MADA)",
            label: "Art, Design & Architecture (MADA)",
          },
          { value: "Arts", label: "Arts" },
          {
            value: "Business, Economics and Commerce",
            label: "Business, Economics and Commerce",
          },
          { value: "Education", label: "Education" },
          { value: "Engineering", label: "Engineering" },
          { value: "Information Technology", label: "Information Technology" },
          { value: "Law", label: "Law" },
          {
            value: "Medicine, Nursing & Health Science",
            label: "Medicine, Nursing & Health Science",
          },
          {
            value: "Pharmacy & Pharmaceutical Sciences",
            label: "Pharmacy & Pharmaceutical Sciences",
          },
          { value: "Science", label: "Science" },
          {
            value: "Office of the Vice-Chancellor",
            label: "Office of the Vice-Chancellor",
          },
          {
            value: "Office of the Provost and Senior Vice-President",
            label: "Office of the Provost and Senior Vice-President",
          },
          {
            value:
              "Office of the Deputy Vice-Chancellor and Vice-President (Education)",
            label:
              "Office of the Deputy Vice-Chancellor and Vice-President (Education)",
          },
          {
            value:
              "Office of the Deputy Vice-Chancellor and Vice-President (Enterprise and Governance)",
            label:
              "Office of the Deputy Vice-Chancellor and Vice-President (Enterprise and Governance)",
          },
          {
            value:
              "Office of the Deputy Vice-Chancellor and Vice-President (Enterprise and Engagement)",
            label:
              "Office of the Deputy Vice-Chancellor and Vice-President (Enterprise and Engagement)",
          },
          {
            value:
              "Office of the Deputy Vice-Chancellor and Vice-President (Global Engagement)",
            label:
              "Office of the Deputy Vice-Chancellor and Vice-President (Global Engagement)",
          },
          {
            value:
              "Office of the Chief Operating Officer and Senior Vice-President",
            label:
              "Office of the Chief Operating Officer and Senior Vice-President",
          },
          {
            value:
              "Office of the Chief Financial Officer and Senior Vice-President",
            label:
              "Office of the Chief Financial Officer and Senior Vice-President",
          },
          { value: "Other", label: "Other" },
        ],
      };
      newFormData[`teamMemberMonashId${i}`] = {
        label: `Team Member ${i} - Demo ID`,
        type: "number",
        value: savedFormData?.data?.submission_data?.[`teamMemberMonashId${i}`],
        required: aff[i] === "Student",
        show: aff[i] === "Student",
      };
      newFormData[`teamMemberDegreeUndertaken${i}`] = {
        label: `Team Member ${i} - Degree undertaking/undertaken`,
        type: "radio",
        options: [
          "Undergraduate",
          "Honours",
          "PG - Masters By Research",
          "PG - Masters by Coursework",
          "PhD",
        ],
        default:
          savedFormData?.data?.submission_data?.[
            `teamMemberDegreeUndertaken${i}`
          ],
        required: aff[i] === "Student" || aff[i] === "Alumni",
        show: aff[i] === "Student" || aff[i] === "Alumni",
      };
      newFormData[`teamMemberPrimaryCampus${i}`] = {
        type: "select",
        label: `Team Member ${i} - Primary campus`,
        show: aff[i] === "Student" || aff[i] === "Staff",
        required: aff[i] === "Student" || aff[i] === "Staff",
        default:
          savedFormData?.data?.submission_data?.[`teamMemberPrimaryCampus${i}`],
        options: [
          { label: "Clayton", value: "Clayton" },
          { label: "Caulfield", value: "Caulfield" },
          { label: "Peninsula", value: "Peninsula" },
          { label: "City", value: "City" },
          { label: "Demo Malaysia", value: "Demo Malaysia" },
          { label: "Other", value: "Other" },
        ],
      };
      newFormData[`teamMemberGraduationYear${i}`] = {
        type: "text",
        label: `Team Member ${i} - Graduation year`,
        show: aff[i] === "Alumni",
        required: aff[i] === "Alumni",
        value:
          savedFormData?.data?.submission_data?.[
            `teamMemberGraduationYear${i}`
          ],
      };
      newFormData[`teamMemberStaffEmployment${i}`] = {
        label: `Team Member ${i} - Staff employment`,
        type: "radio",
        show: aff[i] === "Staff",
        required: aff[i] === "Staff",
        default:
          savedFormData?.data?.submission_data?.[
            `teamMemberStaffEmployment${i}`
          ],
        options: ["Professional staff", "Academic staff", "PostDoc"],
      };
    }
    console.log("new form data", newFormData);
    Object.assign(formData, yourStartupForm, aboutYouForm, newFormData);
    // console.log("from here", formData2);
    // formData = newFormData;
    console.log("form data in effect", formData);
  };

  // const [formData, setFormData] = useState(formDataInit)

  useEffect(() => {
    if (noOfTeamMembers > 1) {
      console.log("in setting effect", noOfTeamMembers);
      console.log("form vals", formValues);
      addTeamMembers(noOfTeamMembers);
    }
  }, [
    noOfTeamMembers,
    addTeamMembers,
    teamMemberAffiliation2,
    teamMemberAffiliation3,
    teamMemberAffiliation4,
    teamMemberAffiliation5,
  ]);

  async function showNotification() {
    console.log("in show notificaiton");
    context.notification.show(
      "Thank you, your feedback is most appreciated.",
      "success",
      true,
      "toast",
      "heart"
    );
  }

  useEffect(() => {
    const noOfTeam = savedFormData?.data?.submission_data?.noOfTeam;
    console.log("in load hook", noOfTeam);
    if (noOfTeam > 1) {
      addTeamMembers(noOfTeam);
    }
    if (savedFormData?.data?.submission_data?.hear === "Other") {
      setShowOtherHeard(true);
    }

    if (savedFormData?.data?.submission_data?.sector === "Other") {
      setShowOtherSector(true);
    }
    // setTeamLeadAffiliation(
    //   savedFormData?.data?.submission_data?.teamLeadAffiliation
    // );
  }, [savedFormData, addTeamMembers]);

  useEffect(() => {
    console.log("in loading hook - only set affs");
    setTeamMemberAffiliation2(
      savedFormData?.data?.submission_data?.teamMemberAffiliation2
    );
    setTeamMemberAffiliation3(
      savedFormData?.data?.submission_data?.teamMemberAffiliation3
    );
    setTeamMemberAffiliation4(
      savedFormData?.data?.submission_data?.teamMemberAffiliation4
    );
    setTeamMemberAffiliation5(
      savedFormData?.data?.submission_data?.teamMemberAffiliation5
    );
    setTeamLeadAffiliation(
      savedFormData?.data?.submission_data?.teamLeadAffiliation
    );
    setFormValues(savedFormData);
  }, [savedFormData]);

  return (
    <Row title="Demo Accelerator 2022 application">
      <Card>
        <Image source={SGLogo} width={300} height={1200} />
        <br />
        <Button text="Save" action={save} params={queryParams.submission_id} />
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <Button text="Save and Exit" goto={`/continue-later`} />
        <Article>
          <p>
            Completing the application form will take approximately 30 - 40
            minutes and require some background on you, your cofounders/team
            members and your project. As part of the application form you will
            also need to include a link to 60-second video introducing yourself
            and any cofounders.
            <br />
            <br />
            The application will autosave, and until you press submit you can
            leave and return to edit your application as many times as you like.{" "}
            <strong>
              Make sure to submit your completed application prior to the
              application deadline, 11.59pm AEST Monday 1 August 2022.
            </strong>
          </p>
        </Article>
      </Card>
      <Animate>
        {savedFormData?.data && (
          <Fragment>
            <Card>
              <Form
                data={formData}
                method="POST"
                callbackFail={() => {
                  window.alert("Please complete all required fields");
                }}
                url={`/api/submission/${queryParams.submission_id}`}
                updateOnChange
                onChange={async (e) => {
                  console.log("on change", e);
                  if (e.valid === true || e.valid === 1 || e.valid === false) {
                    await axios.patch(
                      `/api/submission_data/${queryParams.submission_id}`,
                      e
                    );
                    const newFormValues = formValues;
                    newFormValues.data.submission_data[`${e.input}`] = e.value;
                    console.log("setting form value to", newFormValues);
                    setFormValues(newFormValues);

                    if (e.input === "noOfTeam") {
                      setNoOfTeamMembers(e.value);
                    }
                    if (e.input === "teamLeadAffiliation") {
                      setTeamLeadAffiliation(e.value);
                    }

                    if (e.input === "teamMemberAffiliation2") {
                      console.log("changing team member aff to ", e.value);
                      setTeamMemberAffiliation2(e.value);
                    }

                    if (e.input === "teamMemberAffiliation3") {
                      setTeamMemberAffiliation3(e.value);
                    }

                    if (e.input === "teamMemberAffiliation4") {
                      setTeamMemberAffiliation4(e.value);
                    }

                    if (e.input === "teamMemberAffiliation5") {
                      setTeamMemberAffiliation5(e.value);
                    }

                    if (e.input === "hear" && e.value === "Other") {
                      console.log("set other heard true");
                      setShowOtherHeard(true);
                    }
                    if (e.input === "hear" && e.value !== "Other") {
                      console.log("set other heard false");
                      setShowOtherHeard(false);
                    }

                    if (e.input === "sector" && e.value === "Other") {
                      console.log("set other sector true");
                      setShowOtherSector(true);
                    }
                    if (e.input === "sector" && e.value !== "Other") {
                      console.log("set other sector false");
                      setShowOtherSector(false);
                    }
                  }
                }}
                buttonText="Submit"
                redirect="/thanks/shift"
              />
            </Card>
          </Fragment>
        )}
      </Animate>
    </Row>
  );
}
