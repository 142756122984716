import React from "react";
import { Row, Card } from "components/lib";

export function CampaignOver(props) {
  return (
    <Row title="Thank you!">
      <Card>
        <p>
          Thank you for your interest in applying to join the Monash Generator's
          Accelerator Program in 2022.
          <br />
          Applications are now closed. You will be contacted about the outcome
          of your application following our assessment.
          <br />
          <br />
          Second stage interviews will be held across two days - Tuesday 16 and
          Wednesday 17 August and the program will commence on Monday 5
          September.
          <br />
          <br />
          For more details on the program, please check out{" "}
          <a href="https://www.monash.edu/entrepreneurship/programs/accelerator">
            https://www.monash.edu/entrepreneurship/programs/accelerator
          </a>
          . If you have any further enquiries, please email{" "}
          <a href="mailto:entrepreneurship@monash.edu">
            entrepreneurship@monash.edu
          </a>
        </p>
      </Card>
    </Row>
  );
}
