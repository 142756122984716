import React, { useCallback, useState } from "react";
import { Row, Card, Image, Article, useAPI } from "components/lib";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import qs from "querystring";
// import "survey-core/modern.min.css";
// import "survey-core/defaultV2.css";

// StylesManager.applyTheme("modern");
StylesManager.applyTheme("defaultV2");

export function DemoForm(props) {
  const locations = props.location.search.substring(1);
  const queryParams = qs.parse(locations);
  const id = queryParams.submission_id;
  const formData = useAPI("/api/form-fields/" + id);
  //   const formData = useAPI("/api/form/920757c1-5ca8-4bd3-b8fa-4b24f413deb8");

  const submission = useAPI("/api/submission/" + id);
  const fieldData = formData?.data;
  console.log(formData);
  const survey = new Model(fieldData);
  survey.focusFirstQuestionAutomatic = true;
  survey.data = submission?.data?.submission_data;

  if (submission?.data?.submission_data?.pageNo) {
    survey.currentPageNo = submission.data.submission_data.pageNo;
  }

  function saveSurveyResults(url, json) {
    const request = new XMLHttpRequest();
    request.open("POST", url);
    request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    request.addEventListener("load", () => {
      // Handle "load"
    });
    request.addEventListener("error", () => {
      // Handle "error"
    });
    request.send(JSON.stringify(json));
  }

  function updateResults(url, json) {
    const request = new XMLHttpRequest();
    request.open("PATCH", url);
    request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    request.addEventListener("load", () => {
      // Handle "load"
    });
    request.addEventListener("error", () => {
      // Handle "error"
    });
    request.send(JSON.stringify(json));
  }

  const surveyComplete = useCallback((sender) => {
    saveSurveyResults("/api/submission/" + id, sender.data);
  }, []);

  const surveyUpdate = useCallback((sender) => {
    console.log("update data: ", sender);
    updateResults("/api/sub_data/" + id, sender.valuesHash);
  });

  survey.onComplete.add(surveyComplete);
  survey.onValueChanged.add(surveyUpdate);
  return (
    <>
      <p>off</p>
    </>
  );
  // return <>{!formData?.loading && <Survey model={survey} />}</>;
}
