import React, { useState, Fragment } from "react";
import ClassNames from "classnames";
import { Label, Error, Icon, Helper } from "components/lib";
import Style from "./input.module.scss";

export function TextInput(props) {
  const error = props.errorMessage || "Please enter a value";
  const hint = props.hint || "";
  const [lengthText, setLengthText] = useState("");
  const [showHint, setShowHint] = useState(false);

  function validate(e) {
    let value = e ? e.target.value : "";
    let valid = undefined;

    // input is required and value is blank
    if (props.required && value === "") valid = false;

    // input isn't required and value is blank
    if (!props.required && value !== "") valid = true;

    if (props.required && value !== "") valid = true;

    // update the parent form
    props.onChange?.(props.name, value, valid);
  }

  // style
  const css = ClassNames([
    Style.textbox,
    props.className,
    props.valid === true && Style.success,
    props.valid === false && Style.error,
  ]);

  const handleFocus = (e) => {
    if (props.hint) {
      setShowHint(true);
    }
    if (props.maxlength && props.value !== undefined) {
      const charCount = props.value === "" ? 0 : props.value.length;
      setLengthText(`${charCount}/${props.maxlength}`);
    }
  };

  if (props.show !== undefined && props.show === false) {
    return <></>;
  }

  return (
    <div className={Style.input}>
      {props.label && (
        <Fragment>
          <Label
            text={props.label}
            required={props.required}
            for={props.name}
          />
          {showHint && props.maxlength ? (
            <p>Character Count: {lengthText}</p>
          ) : (
            ""
          )}
        </Fragment>
      )}
      {showHint && <Helper text={props.hint} />}
      {props.type === "textarea" ? (
        <textarea
          id={props.id}
          name={props.name}
          className={css}
          value={props.value}
          placeholder={props.placeholder}
          onChange={(e) => {
            props.onChange?.(props.name, e.target.value, undefined);
            if (props.maxlength) {
              const charCount = e.target.value.length;
              setLengthText(`${charCount}/${props.maxlength}`);
            }
          }}
          onBlur={(e) => {
            validate(e);
            setShowHint(false);
          }}
          onFocus={handleFocus}
          maxLength={props.maxlength}
        ></textarea>
      ) : (
        <input
          type="text"
          id={props.id}
          name={props.name}
          className={css}
          value={props.value || ""}
          placeholder={props.placeholder}
          onChange={(e) =>
            props.onChange?.(props.name, e.target.value, undefined)
          }
          onBlur={(e) => {
            validate(e);
            setShowHint(false);
          }}
          onFocus={handleFocus}
          maxLength={props.maxlength}
        />
      )}

      {props.valid === false && <Error message={error} />}
      {props.valid === true && (
        <Icon
          image="check"
          color="#8CC57D"
          className={Style.successIcon}
          size={16}
        />
      )}
    </div>
  );
}
